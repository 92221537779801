import React, { useCallback, useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { IconSmile } from '../../Icon/Icons';
import ListEmojis from './Emojis.json';
import './TextAreaEmoji.scss';

export const BoxEmojis = ({ onClick = (emoji) => {} }) => {
    const [emojis, setEmojis] = useState([]);
    const [categories, setCategories] = useState([]);
    const [tab, setTab] = useState('Smileys & Emotion');
    const [emojisByCategory, setEmojisByCategory] = useState([]);

    useEffect(() => {
        const emojisByCategory = [];
        const allCategories = [];

        ListEmojis.forEach((row) => {
            if (
                emojisByCategory.filter((obj) => obj.category === row.category)
                    .length === 0
            ) {
                allCategories.push(row.category);
                emojisByCategory.push({
                    category: row.category,
                    data: ListEmojis.filter(
                        (obj) => obj.category === row.category
                    ),
                });
            }
        });
        setEmojis(emojisByCategory);
        setCategories(allCategories);
    }, []);

    useEffect(() => {
        const emojisFilter = emojis.filter((obj) => obj.category === tab);
        setEmojisByCategory(emojisFilter[0] ? emojisFilter[0].data : []);
    }, [tab, emojis]);

    const handleOnClickEmoji = useCallback(
        (emoji) => {
            onClick(emoji);
        },
        [onClick]
    );

    return (
        <div className="box-emoji" id="containerModalEmoji">
            <div className="tabs">
                {categories.map((row, key) => (
                    <button
                        type="button"
                        className={`${row} ${
                            row === tab ? 'active' : 'inactive'
                        }`}
                        onClick={() => setTab(row)}
                        key={key}
                    />
                ))}
            </div>
            <Scrollbars
                style={{
                    height: '200px',
                }}
                className="scroll"
            >
                <div className="list">
                    {emojisByCategory?.map((row, key) => (
                        <button
                            type="button"
                            onClick={() => handleOnClickEmoji(row.emoji)}
                            key={key}
                        >
                            <span>{row.emoji}</span>
                        </button>
                    ))}
                </div>
            </Scrollbars>
        </div>
    );
};

export const TextareaEmoji = ({
    value,
    onChange = (e) => {},
    head = [],
    max = false,
    ...rest
}) => {
    const [boxEmoji, setBoxEmoji] = useState(false);
    const InputElement = useRef();

    useEffect(() => {
        const eventClickOffset = document.addEventListener(
            'click',
            function (event) {
                if (
                    !event.target.closest('#containerModalEmoji') &&
                    !event.target.closest('#containerHeadBoxText')
                )
                    setBoxEmoji(false);
            },
            false
        );

        return () => document.removeEventListener('click', eventClickOffset);
    }, []);

    function addEmoji(val) {
        const step = InputElement.current;
        step.focus();
        const selection = window.getSelection();
        for (var i = 0; i < step; i += 1) {
            selection.modify('extend', 'backward', 'character');
        }
        document.execCommand('insertText', false, val);
    }

    return (
        <div className="textarea-emoji">
            {boxEmoji && <BoxEmojis onClick={(emoji) => addEmoji(emoji)} />}
            <div className="head" id="containerHeadBoxText">
                <button
                    type="button"
                    onClick={() => setBoxEmoji(true)}
                    className="emoji"
                >
                    <IconSmile />
                </button>

                <Scrollbars
                    style={{
                        height: '45px',
                        width: 'calc(100% - 54px)',
                    }}
                    autoHide={true}
                    className="items"
                >
                    <div className="list-head">
                        {head.map((row, key) => (
                            <button
                                type="button"
                                key={`btn-head-${key}`}
                                onClick={(emoji) => addEmoji(`[${row}]`)}
                            >
                                {row}
                            </button>
                        ))}
                    </div>
                </Scrollbars>
            </div>
            <textarea
                ref={InputElement}
                onChange={onChange}
                value={value}
                placeholder={rest.placeholder}
                maxLength={max}
                {...rest}
            ></textarea>
            {max && (
                <div
                    className={`maxlenght ${
                        max - value.length < 0 ? 'error' : 'normal'
                    }}`}
                >
                    {max - value.length} caracteres restantes.
                </div>
            )}
        </div>
    );
};
