import React, { useEffect } from 'react';
import moment from 'moment';
import { useGlobal } from '../../contexts/GlobalContext';
import Skeleton from 'react-loading-skeleton';
import messageStore from '../../Store/MessageStore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IconBell, IconDate, IconLoading, IconPower } from '../Icon/Icons';

export default function HeaderLayoutPrivate({ width }) {
    const history = useHistory();
    const { user, loadingNotification, loadingUser, notifications } =
        useGlobal();
    const meses = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ];

    useEffect(() => {
        if (loadingUser === false && !user.accountId) {
            messageStore.addError(
                'Desculpe, não foi possivel carregar os dados do seu usuário.'
            );
            history.push('/login');
        }
    }, [loadingUser, user, history]);

    return (
        <header id="header-profile">
            <div
                className={`position`}
                style={{
                    width: width,
                }}
            >
                <div className="profile">
                    <div
                        className="img"
                        style={{
                            backgroundImage: `url(${user.foto})`,
                        }}
                    >
                        {loadingUser && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <Skeleton
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="text">
                        {loadingUser ? (
                            <>
                                <p
                                    style={{
                                        width: 130,
                                        height: 23,
                                    }}
                                >
                                    <Skeleton />
                                </p>
                                <p
                                    style={{
                                        width: 100,
                                        height: 20,
                                    }}
                                >
                                    <Skeleton />
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="name">{user.nome}</p>
                                <p className="account">{user.account?.nome}</p>
                            </>
                        )}
                    </div>
                </div>
                <div className="menu-header">
                    <button className="button-default">
                        <IconDate />
                        <span>
                            {moment().format('DD')}{' '}
                            {meses[moment().format('M') - 1]}
                        </span>
                    </button>
                    <div>
                        <button
                            onClick={() => history.push('/notificacoes')}
                            className="button-default"
                        >
                            <IconBell />
                            <span className="number">
                                {loadingNotification ? (
                                    <IconLoading />
                                ) : (
                                    notifications.total
                                )}
                            </span>
                        </button>
                        <button
                            onClick={() => history.push('/login')}
                            className="button-default"
                        >
                            <IconPower />
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
}
