import React from 'react';
import './Utils.scss';

export const EmptyBox = ({ ...rest }) => {
    return (
        <div className="empty-box" {...rest}>
            <img src="/empty-box.gif" alt="Nada encontrado" />
            <p className="title">Oops...</p>
            <p className="description">Nada encontrado aqui.</p>
        </div>
    );
};
