import React, { useCallback, useEffect, useState } from 'react';
import messageStore from '../../Store/MessageStore';
import H1Page from '../../Components/Layout/H1Page';
import TableDefault from '../../Components/TableDefault/TableDefault';
import ModalBlackList from './ModalBlackList';
import {
    getBlockLists,
    HeadersBlockList,
    removeBlockLists,
} from '../../Api/BlockListService';
import './Blacklist.scss';
import { ButtonDefault } from '../../Components/Form/ButtonDefault/ButtonDefault';

export default function Blacklist(props) {
    const [load, setLoad] = useState(true);
    const [data, setData] = useState({
        docs: [],
    });
    const [page, setPage] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);

    const getData = useCallback(async () => {
        try {
            setLoad(true);
            const response = await getBlockLists(page);
            if (response.data.error === true)
                throw new Error(response.data.message);
            setData({ ...response.data });
            setLoad(false);
        } catch (err) {
            messageStore.addError(err.message);
            setLoad(false);
        }
    }, [page]);

    useEffect(() => {
        getData();
    }, [getData]);

    async function handleDelete(id) {
        await removeBlockLists(id);
        messageStore.addSuccess('Número removido na blacklist.');
        getData();
    }

    function handleClickButtonTable(id, event) {
        messageStore.addConfirm('Tem cereteza que deseja remover?', () =>
            handleDelete(id)
        );
    }

    return (
        <div id="blacklists">
            <H1Page nome={'Blocklist'} />

            {modalOpen && (
                <ModalBlackList
                    onClose={() => setModalOpen(false)}
                    getData={() => {
                        getData();
                        setModalOpen(false);
                    }}
                />
            )}
            <TableDefault
                headers={HeadersBlockList}
                data={data.docs}
                page={page}
                onPaginate={setPage}
                label="Todos bloqueios"
                total={data.total}
                totalPage={data.totalPage}
                loading={load}
                onClickButton={handleClickButtonTable}
                header={
                    <ButtonDefault onClick={() => setModalOpen(true)}>
                        Adicionar bloqueio
                    </ButtonDefault>
                }
            />
        </div>
    );
}
