import React, { useState, useEffect } from 'react';
import messageStore from '../../Store/MessageStore';
import { ButtonDefault } from '../Form/ButtonDefault/ButtonDefault';
import { InputDefault } from '../Form/InputDefault/InputDefault';
import { IconArrowLeftDown } from '../Icon/Icons';
import './ContactList.scss';

export default function EditColumns(props) {
    const [columns, setColumns] = useState([]);
    const [buttonDiscard, setButtonDiscard] = useState(false);

    useEffect(() => {
        setColumns([...props.columns]);
    }, [props.columns]);

    function handleNewColumn() {
        try {
            setButtonDiscard(true);
            if (columns.length >= 20)
                throw new Error('O máximo de colunas permitidas são 20.');
            columns.push('Coluna ' + (columns.length + 1));
            setColumns([...columns]);
        } catch (e) {
            messageStore.addError(e);
        }
    }

    function handleChangeColumn(name, value) {
        columns[name] = value;
        setColumns([...columns]);
    }

    async function handleConfirm(e) {
        try {
            e.preventDefault();
            props.handleEditColumn(columns);
            messageStore.addSuccess('Colunas salva com sucesso.');

            if (props.redirect) {
                props.setTab(props.redirect);
                props.setRedirect(false);
            }
        } catch (err) {
            console.log(err);
            messageStore.addError('Algo deu errado...');
        }
    }

    function handleDiscard() {
        setButtonDiscard(false);
        setColumns([...props.columns]);
    }

    return (
        <div className="edit-column">
            <form className="box-import" onSubmit={handleConfirm}>
                {columns.map((coluna, key) => (
                    <div key={`column-${key}`} className="column">
                        <div className="column-list">
                            <IconArrowLeftDown />
                            <span>Coluna</span>
                        </div>
                        <InputDefault
                            key={`input-edit-contact-${key}`}
                            placeholder={coluna}
                            onChange={(e) =>
                                handleChangeColumn(key, e.target.value)
                            }
                            name={key}
                            value={coluna}
                        />
                    </div>
                ))}

                <ButtonDefault
                    type="button"
                    onClick={handleNewColumn}
                    theme="warning"
                >
                    Adicionar nova coluna
                </ButtonDefault>
                <div className="buttons-foot">
                    {buttonDiscard && (
                        <ButtonDefault
                            type="button"
                            onClick={() => handleDiscard()}
                        >
                            Cancelar
                        </ButtonDefault>
                    )}
                    <ButtonDefault theme={'primary'}>Confirmar</ButtonDefault>
                </div>
            </form>
            <div className="tutorial-box">
                <p className="title-tutorial">Central de dicas</p>

                <div className="item">
                    <span>
                        A funcionalidade Gerenciar Colunas é uma ferramenta
                        essencial para quem trabalha com listas de contatos,
                        pois ela permite gerenciar as colunas presentes na sua
                        lista de contatos. Isso significa que você pode
                        adicionar e reorganizar as colunas da lista de contatos
                        de acordo com as suas necessidades.
                    </span>
                    <span>
                        Uma das principais vantagens de utilizar a
                        funcionalidade Gerenciar Colunas é a possibilidade de
                        adequar a sua lista de contatos às necessidades do seu
                        negócio. Por exemplo, se você precisa incluir uma coluna
                        com informações específicas dos seus clientes, basta
                        adicionar essa nova coluna na lista de contatos.
                    </span>
                </div>
            </div>
        </div>
    );
}
