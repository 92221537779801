import moment from 'moment';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ButtonDefault } from '../Form/ButtonDefault/ButtonDefault';
import './Dashboard.scss';

export const Activities = ({ data }) => {
    const history = useHistory();
    return (
        <Scrollbars className="activities-list">
            {data.docs.map((row, key) => (
                <div className="box-log" key={key}>
                    <div className="left">
                        <div
                            className="img"
                            style={{
                                backgroundImage: `url(${row.usuario?.foto})`,
                            }}
                        />
                    </div>
                    <div className="right">
                        <span className="date">
                            {moment(row.createdAt).locale('pt-br').fromNow()}
                        </span>
                        <p>{row.mensagem}</p>
                        <span className="user">{row.usuario?.nome}</span>
                    </div>
                </div>
            ))}

            {data.pages > 0 && (
                <ButtonDefault
                    onClick={() => history.push('/notificacoes')}
                    theme="primary"
                >
                    Ver todos
                </ButtonDefault>
            )}
        </Scrollbars>
    );
};
