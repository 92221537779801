import React from 'react';
import { IconLoadingPointer } from '../Icon/Icons';
import './Utils.scss';

export const LoadingBox = ({ ...rest }) => {
    return (
        <div className="loading-box" {...rest}>
            <IconLoadingPointer />
        </div>
    );
};
