import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    IconAnalytics,
    IconBlocklist,
    IconCampgain,
    IconConfig,
    IconDashboard,
    IconLandingPage,
    IconListContacts,
    IconPower,
    IconSend,
} from '../Icon/Icons';

export const RenderButtonMenu = ({ children, link, onToggle, ...rest }) => {
    return link ? (
        <Link to={link} {...rest}>
            {children}
        </Link>
    ) : (
        <div onClick={() => onToggle()} {...rest}>
            {children}
        </div>
    );
};

export const RenderItemMenu = ({ menu, pathname, octobar, setOctobar }) => {
    const [opened, setOpened] = useState(false);
    const [actived, setActived] = useState(false);

    useEffect(() => {
        let menuActive = false;

        if (menu.link === pathname) {
            menuActive = true;
        }

        if (menu.link.length > 1 && pathname.split(menu.link).length > 1) {
            menuActive = true;
        }

        if (menu.children.filter((obj) => obj.link === pathname).length > 0) {
            menuActive = true;
        }

        setActived(menuActive);
        setOpened(menuActive);
    }, [pathname, menu]);

    useEffect(() => {
        if (octobar) setOpened(false);
    }, [octobar]);

    return (
        <li
            className={`${actived ? 'actived' : 'normal'} ${
                opened ? 'opened' : 'closed'
            }`}
        >
            <RenderButtonMenu
                onToggle={() => {
                    setOpened(!opened);
                    setOctobar(octobar ? false : octobar);
                }}
                className={`item`}
                link={menu.link}
            >
                <RenderIcon active={actived} page={menu.name} />
                <span className="title">{menu.name}</span>
                {menu.children.length > 0 && (
                    <i className="icon down">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                        >
                            <rect
                                x="0"
                                y="0"
                                width="32"
                                height="32"
                                fill="none"
                                stroke="none"
                            />
                            <path
                                fill="currentColor"
                                d="M16 22L6 12l1.4-1.4l8.6 8.6l8.6-8.6L26 12z"
                            />
                        </svg>
                    </i>
                )}
            </RenderButtonMenu>
            {menu.children.length > 0 && (
                <ul className={`submenu  ${opened ? 'opened' : 'closed'}`}>
                    {menu.children.map((sub, subKey) => (
                        <li
                            className={`${
                                pathname === sub.link ? 'actived' : 'normal'
                            } `}
                            key={subKey}
                        >
                            <Link className="item" to={sub.link}>
                                <span className="title">{sub.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

export const RenderIcon = ({ page, active }) => {
    const [icon, setIcon] = useState();

    useEffect(() => {
        let iconDefault = (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
            >
                <rect
                    x="0"
                    y="0"
                    width="32"
                    height="32"
                    fill="none"
                    stroke="none"
                />
                <path
                    fill="currentColor"
                    d="M7.7 4.7a14.7 14.7 0 0 0-3 3.1L6.3 9a13.26 13.26 0 0 1 2.6-2.7zm-3.1 7.6l-1.9-.6A12.51 12.51 0 0 0 2 16h2a11.48 11.48 0 0 1 .6-3.7zm-1.9 8.1a14.4 14.4 0 0 0 2 3.9l1.6-1.2a12.89 12.89 0 0 1-1.7-3.3zm5.1 6.9a14.4 14.4 0 0 0 3.9 2l.6-1.9A12.89 12.89 0 0 1 9 25.7zm3.9-24.6l.6 1.9A11.48 11.48 0 0 1 16 4V2a12.51 12.51 0 0 0-4.3.7zm12.5 24.6a15.18 15.18 0 0 0 3.1-3.1L25.7 23a11.53 11.53 0 0 1-2.7 2.7zm3.2-7.6l1.9.6A15.47 15.47 0 0 0 30 16h-2a11.48 11.48 0 0 1-.6 3.7zm1.8-8.1a14.4 14.4 0 0 0-2-3.9l-1.6 1.2a12.89 12.89 0 0 1 1.7 3.3zm-5.1-7a14.4 14.4 0 0 0-3.9-2l-.6 1.9a12.89 12.89 0 0 1 3.3 1.7zm-3.8 24.7l-.6-1.9a11.48 11.48 0 0 1-3.7.6v2a21.42 21.42 0 0 0 4.3-.7z"
                />
            </svg>
        );

        if (page === 'Dashboard') iconDefault = <IconDashboard />;
        if (page === 'Nova campanha') iconDefault = <IconSend />;
        if (page === 'Minhas campanhas') iconDefault = <IconCampgain />;
        if (page === 'Gestão de contatos') iconDefault = <IconListContacts />;
        if (page === 'Blocklist') iconDefault = <IconBlocklist />;
        if (page === 'Landing pages') iconDefault = <IconLandingPage />;
        if (page === 'Analytics') iconDefault = <IconAnalytics />;
        if (page === 'Sair') iconDefault = <IconPower />;
        if (page === 'Configurações') iconDefault = <IconConfig />;

        setIcon(iconDefault);
    }, [page]);

    return <i className={`icon ${active ? 'active' : 'normal'}`}>{icon}</i>;
};
