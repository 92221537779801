import React, { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
    getListaContato,
    setListaContato,
    setNameListaContato,
} from '../../Api/GestaoContatosService';
import messageStore from '../../Store/MessageStore';

import { ButtonDefault } from '../Form/ButtonDefault/ButtonDefault';
import { InputDefault } from '../Form/InputDefault/InputDefault';
import { SelectDefault } from '../Form/SelectDefault/SelectDefault';
import { IconCloseLine, IconExpand } from '../Icon/Icons';

import './ContactList.scss';
import EditListNoModal from './EditListNoModal';

export default function EditList({
    id,
    send,
    nameOnly = false,
    callbackChangeColumns = () => {},
    callbackChangeName = () => {},
    callbackNewList = () => {},
    onClose = () => {},
}) {
    const [list, setList] = useState({});
    const [columns, setColumns] = useState([]);
    const [nameList, setNameList] = useState('');
    const [typeList, setTypeList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [expand, setExpand] = useState(
        window.localStorage.getItem('@expand-list-contact') ? true : false
    );

    const changeColumns = useCallback(
        (coluna, id) => {
            callbackChangeColumns(coluna, id);
        },
        [callbackChangeColumns]
    );

    useEffect(() => {
        changeColumns(columns, id);
    }, [columns, id, changeColumns]);

    const getData = useCallback(async () => {
        setLoading(true);
        const response = await getListaContato(id);
        setList({ ...response.data });
        setNameList(response.data.lista);
        setColumns([...response.data.colunas]);
        changeColumns(response.data.colunas, id);
        setLoading(false);
    }, [id, changeColumns]);

    useEffect(() => {
        if (id) {
            getData();
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (expand) {
            window.localStorage.setItem('@expand-list-contact', 'true');
        } else {
            window.localStorage.removeItem('@expand-list-contact');
        }
    }, [expand]);

    async function handleSaveName(e) {
        e.preventDefault();
        try {
            setLoadingButton(true);
            await setNameListaContato(list._id, nameList, columns);
            messageStore.addSuccess('Registro salvo com sucesso.');
            callbackChangeName(list._id, nameList);
            onClose();
        } catch (err) {
            setLoadingButton(false);
            messageStore.addError('Não foi possivel alterar o nome.');
        }
    }

    async function handleNewList(e) {
        try {
            e.preventDefault();
            setLoadingButton(true);
            if (!send && !typeList)
                throw new Error('Selecione o tipo da lista.');
            if (!nameList)
                throw new Error('Dê um nome para lista antes de prosseguir.');
            let typeListInternal = send ? send : typeList;
            let columns = [
                send === 'Email' || typeList === 'Email' ? 'email' : 'celular',
                'nome',
            ];
            const response = await setListaContato({
                lista: nameList,
                tipo: typeListInternal,
                colunas: columns,
            });
            if (response.data.error === true) {
                throw new Error(response.data.message);
            }
            messageStore.addSuccess('Registro salvo com sucesso.');
            callbackNewList(response.data.result);
            setNameList('');
            onClose();
        } catch (err) {
            setLoadingButton(false);
            messageStore.addError(err.message);
        }
    }

    return (
        <div id="list-contact-clean">
            <button className="close-overlay" onClick={onClose} />
            <div
                id="modalclickcontact"
                className={`box-modal ${expand ? 'expand' : 'normal'}`}
            >
                {nameOnly === true && (
                    <form onSubmit={list._id ? handleSaveName : handleNewList}>
                        <p className="title">
                            {list._id ? 'Editar lista' : 'Criar lista'}
                        </p>
                        <div className="inputs">
                            {!send && (
                                <SelectDefault
                                    value={typeList}
                                    onChange={(e) =>
                                        setTypeList(e.target.value)
                                    }
                                >
                                    <option value="SMS">SMS</option>
                                    <option value="RCS">RCS</option>
                                    <option value="Email">Email</option>
                                </SelectDefault>
                            )}

                            <InputDefault
                                loading={loading}
                                placeholder="Nome da lista"
                                value={nameList}
                                onChange={(e) => setNameList(e.target.value)}
                                style={{
                                    flex: 1,
                                }}
                            />
                        </div>

                        <div className="foot">
                            <ButtonDefault
                                type="button"
                                onClick={() => onClose()}
                            >
                                Cancelar
                            </ButtonDefault>

                            <ButtonDefault
                                loading={loadingButton}
                                theme="primary"
                            >
                                {list._id ? 'Salvar nome' : 'Salvar'}
                            </ButtonDefault>
                        </div>
                    </form>
                )}

                {nameOnly !== true && id && (
                    <div className="list-settings">
                        <div className="head">
                            <div className="buttons">
                                <button
                                    className="close"
                                    onClick={() => onClose()}
                                >
                                    <IconCloseLine />
                                </button>
                                <button
                                    className="expand"
                                    onClick={() => setExpand(!expand)}
                                >
                                    <IconExpand />
                                </button>
                            </div>
                            <p>{list.lista ?? <Skeleton />}</p>
                        </div>
                        <div className="overflow-list">
                            {loading === false && (
                                <EditListNoModal
                                    id={id}
                                    list={list}
                                    columnsDefault={columns}
                                    send={send}
                                    callbackChangeColumns={
                                        callbackChangeColumns
                                    }
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
