import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { IconHours } from '../../Icon/Icons';
import './InputsHours.scss';

export const InputHours = ({
    dateSelected,
    label = 'Horario',
    onChange,
    hourDefault,
    minuteDefault,
}) => {
    const [hours, setHours] = useState([]);
    const [minutes, setMinutes] = useState([]);

    const [DTO, setDTO] = useState({
        hour: hourDefault,
        minute: minuteDefault,
    });

    useEffect(() => {
        setDTO({
            hour: hourDefault,
            minute: minuteDefault,
        });
    }, [hourDefault, minuteDefault]);

    const handleOnChange = useCallback((name, value) => {
        DTO[name] = value;
        setDTO({ ...DTO });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        onChange(`${DTO.hour}:${DTO.minute}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [DTO]);

    useEffect(() => {
        const newhours = [];
        for (let i = 0; i < 24; i++) {
            const hour = i.toString().padStart(2, '0');
            newhours.push(hour);
        }
        setHours(newhours);

        // Array com todos os minutos de 00 até 59
        const newminutes = [];
        for (let i = 0; i < 60; i++) {
            const minute = i.toString().padStart(2, '0');
            newminutes.push(minute);
        }
        setMinutes(newminutes);
    }, []);

    useEffect(() => {
        if (dateSelected <= moment().format('YYYY-MM-DD')) {
            setDTO({
                hour: moment().format('HH'),
                minute: moment().format('mm'),
            });
        }
    }, [dateSelected]);

    return (
        <div className="input-hours">
            <p>{label}</p>
            <div className="element-input">
                <IconHours />
                <div className="selects">
                    <select
                        value={DTO.hour}
                        onChange={(e) => handleOnChange('hour', e.target.value)}
                    >
                        {hours.map((hour) => (
                            <option
                                disabled={
                                    dateSelected ===
                                        moment().format('YYYY-MM-DD') &&
                                    parseFloat(moment().format('HH')) >
                                        parseFloat(hour)
                                        ? true
                                        : false
                                }
                            >
                                {hour}
                            </option>
                        ))}
                    </select>
                    <span>:</span>
                    <select
                        value={DTO.minute}
                        onChange={(e) =>
                            handleOnChange('minute', e.target.value)
                        }
                    >
                        {minutes.map((minute) => (
                            <option
                                disabled={
                                    dateSelected ===
                                        moment().format('YYYY-MM-DD') &&
                                    parseFloat(moment().format('mm')) >
                                        parseFloat(minute) &&
                                    parseFloat(moment().format('HH')) >=
                                        parseFloat(DTO.hour)
                                        ? true
                                        : false
                                }
                            >
                                {minute}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};
