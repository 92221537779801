import React, { useState, useRef } from 'react';
import ReactInputMask from 'react-input-mask';
import { IconEye, IconEyeClose, IconLoading } from '../../Icon/Icons';
import './InputDefault.scss';

export const InputDefault = ({
    label,
    mask,
    type,
    loading = false,
    defaultValue = '',
    value = '',
    onChange = (e) => {},
    ...rest
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const refInputMask = useRef(null);

    return (
        <div
            className="input-default-theme"
            style={{
                marginTop: label ? '20px' : '0px',
            }}
        >
            {label && <p className="label">{label}</p>}
            <ReactInputMask
                {...rest}
                mask={mask}
                type={showPassword ? 'text' : type}
                value={value}
                onChange={onChange}
                ref={refInputMask}
            />
            {type === 'password' && (
                <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {!showPassword ? <IconEye /> : <IconEyeClose />}
                </button>
            )}
            {loading && (
                <button type="button">
                    <IconLoading />
                </button>
            )}
        </div>
    );
};
