import React from 'react';
import { IconCloseLine } from '../Icon/Icons';
import './Modal.scss';

export default function Modal({ title, children, foot, id, onClose }) {
    return (
        <div id={id} className="modal">
            <button className="overlay" onClick={() => onClose()} />
            <div className="modal-box-md">
                <div className="head-md">
                    <div className="buttons">
                        <button onClick={() => onClose()} className="close">
                            <IconCloseLine />
                        </button>
                    </div>
                    <p>{title}</p>
                </div>
                <div className="content">{children}</div>
                {foot && <div className="foot">{foot}</div>}
            </div>
        </div>
    );
}
