const axios = require('axios');

var baseUrl;

if (process.env.NODE_ENV === 'development') {
    baseUrl = 'https://oldocto.octotecnologia.com/';
} else {
    baseUrl = 'https://oldocto.octotecnologia.com/';
}

const TOKEN = window.localStorage.getItem('token');

const api = axios.create({
    baseURL: baseUrl,
    headers: {
        Authorization: 'Bearer ' + TOKEN,
    },
});

export { api, TOKEN, baseUrl };
