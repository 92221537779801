import React from 'react';

import './LaptopPreview.scss';
import Scrollbars from 'react-custom-scrollbars';

export const LaptopPreview = ({ children, title, ...rest }) => {
    return (
        <div className="laptop-preview" {...rest}>
            <div className="display">
                <div className="browser">
                    <Scrollbars
                        style={{
                            height: 'calc(100%)',
                        }}
                        className="scroll"
                    >
                        {children}
                    </Scrollbars>
                </div>
            </div>
            <div className="keyboard" />
        </div>
    );
};
