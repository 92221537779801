import React, { useState, useEffect } from 'react';
import { api } from '../../Api/app';

import './ContactList.scss';
import EditContact from './EditContact';
import ImportContacts from './ImportContacts';
import ListContact from './ListContact';
import EditColumns from './EditColumns';
import CleanCSV from './CleanCSV';

export default function EditListNoModal({
    id,
    dataInicial = '',
    dataFinal = '',
    statusHigienizacao = '',
    callbackChangeColumns = () => {},
    send,
    list,
    search = '',
    columnsDefault,
}) {
    const [page, setPage] = useState(1);
    const [columns, setColumns] = useState(columnsDefault ?? []);
    const [tab, setTab] = useState('list');
    const [contact, setContact] = useState({});
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        callbackChangeColumns(columns, id, send);
    }, [columns, tab, callbackChangeColumns, id, send]);

    async function handleEditColumn(col) {
        setColumns([...col]);
        const response = await api.put('contactLists/' + list._id, {
            colunas: col,
        });
        return response;
    }

    return (
        <div id="list-contact-clean-settings">
            <div className="tabs">
                <button
                    type="button"
                    onClick={() => setTab('list')}
                    className={tab === 'list' ? 'active' : 'normal'}
                >
                    Contatos
                </button>

                <button
                    type="button"
                    onClick={() => setTab('add')}
                    className={tab === 'add' ? 'active' : 'normal'}
                >
                    Adicionar contato
                </button>

                <button
                    type="button"
                    onClick={() => setTab('import')}
                    className={tab === 'import' ? 'active' : 'normal'}
                >
                    Importar contatos
                </button>

                <button
                    type="button"
                    onClick={() => setTab('columns')}
                    className={tab === 'columns' ? 'active' : 'normal'}
                >
                    Editar colunas
                </button>

                <button
                    type="button"
                    onClick={() => setTab('clean')}
                    className={tab === 'clean' ? 'active' : 'normal'}
                >
                    Higienizar CSV
                </button>
            </div>

            <div className="content">
                {tab === 'list' && (
                    <ListContact
                        open={tab === 'list' ? true : false}
                        page={page}
                        setPage={setPage}
                        id={id}
                        columns={columns}
                        tab={tab}
                        setTab={setTab}
                        setContact={setContact}
                        send={send}
                        search={search}
                        dataInicial={dataInicial}
                        dataFinal={dataFinal}
                        statusHigienizacao={statusHigienizacao}
                    />
                )}

                {tab === 'add' && (
                    <EditContact
                        open={tab === 'add' ? true : false}
                        list={list}
                        nameList={list.lista}
                        tab={tab}
                        columns={columns}
                        handleNewContact={handleEditColumn}
                        handleEditColumn={handleEditColumn}
                        setRedirect={setRedirect}
                        setTab={setTab}
                        send={send}
                    />
                )}

                {tab === 'edit' && (
                    <EditContact
                        open={tab === 'edit' ? true : false}
                        setTab={setTab}
                        tab={tab}
                        list={list}
                        nameList={list.lista}
                        columns={columns}
                        contact={contact}
                        handleEditColumn={handleEditColumn}
                        setRedirect={setRedirect}
                        send={send}
                    />
                )}

                {tab === 'columns' && (
                    <EditColumns
                        open={tab === 'columns' ? true : false}
                        setTab={setTab}
                        list={list}
                        nameList={list.lista}
                        columns={columns}
                        contact={contact}
                        handleEditColumn={handleEditColumn}
                        redirect={redirect}
                        setRedirect={setRedirect}
                        send={send}
                    />
                )}

                {tab === 'import' && (
                    <ImportContacts
                        tab={tab}
                        setTab={setTab}
                        list={list}
                        nameList={list.lista}
                        columns={columns}
                        contact={contact}
                        handleEditColumn={handleEditColumn}
                        redirect={redirect}
                        setRedirect={setRedirect}
                        send={send}
                    />
                )}

                {tab === 'clean' && (
                    <CleanCSV
                        open={tab === 'clean' ? true : false}
                        setTab={setTab}
                        list={list}
                        nameList={list.lista}
                        columns={columns}
                        contact={contact}
                        handleEditColumn={handleEditColumn}
                        redirect={redirect}
                        setRedirect={setRedirect}
                        send={send}
                        id={id}
                    />
                )}
            </div>
        </div>
    );
}
