import React from 'react';
import { IconLoadingPointer } from '../../Icon/Icons';
import './ButtonDefault.scss';

export const ButtonDefault = ({
    children,
    loading = false,
    theme,
    ...rest
}) => {
    return (
        <button
            className={`button-default-theme ${theme ?? 'transparent'}`}
            {...rest}
        >
            {loading ? <IconLoadingPointer /> : children}
        </button>
    );
};
