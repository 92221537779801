import React, { useState } from 'react';
import Paginator from '../Paginator/Paginator';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

import './TableDefault.scss';
import { ButtonDefault } from '../Form/ButtonDefault/ButtonDefault';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CheckboxDefault } from '../Form/CheckboxDefault/CheckboxDefault';
import { IconConfig } from '../Icon/Icons';
import Scrollbars from 'react-custom-scrollbars';
import Modal from '../Modal/Modal';

export const RenderItemOverflow = ({
    loading,
    totalItems,
    maxHeight,
    children,
}) => {
    return !maxHeight ? (
        <div
            className="overflow"
            style={{
                maxHeight,
            }}
        >
            {children}
        </div>
    ) : (
        <div
            className="overflow"
            style={{
                maxHeight,
                height: loading
                    ? 592
                    : (totalItems <= 0 ? 1 : totalItems) * 55 + 42,
            }}
        >
            <Scrollbars
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                {children}
            </Scrollbars>
        </div>
    );
};

export const RenderComponentTableTd = ({ item, Component }) => {
    return <Component item={item} />;
};

export default function TableDefault({
    headers = [],
    data = [],
    label,
    loading = false,
    page,
    totalPage,
    total,
    onPaginate = () => {},
    onClickButton = (id, event) => {},
    onClickCheckbox = (ids, event) => {},
    header,
    filter,
    download,
    maxHeight = false,
    ...rest
}) {
    const history = useHistory();

    const [selectAll, setSelectAll] = useState(false);
    const [ids, setIds] = useState([]);
    const [modalMinify, setModalMinify] = useState(false);

    function handleSelectAll() {
        if (selectAll) {
            setSelectAll(false);
            setIds([]);
        } else {
            setSelectAll(true);
            data.forEach((row) => {
                ids.push(
                    row[
                        headers.filter((obj) => obj.type === 'checkbox')[0]
                            .indice
                    ]
                );
            });
            setIds([...ids]);
        }
    }

    function handleSetIds(id) {
        if (ids.filter((obj) => obj === id).length > 0) {
            setIds([...ids.filter((obj) => obj !== id)]);
        } else {
            ids.push(id);
            setIds([...ids]);
        }
    }

    function handleOnClickCheckbox(ids, row) {
        setSelectAll(false);
        onClickCheckbox(ids, row);
    }

    return (
        <>
            {modalMinify && (
                <Modal
                    title="Mensagem completa"
                    onClose={() => setModalMinify(false)}
                >
                    <p
                        className="string-full"
                        dangerouslySetInnerHTML={{ __html: modalMinify }}
                    />
                </Modal>
            )}

            <div className="table-default-clean" {...rest}>
                {(label || header) && (
                    <div className="head">
                        {label && <p>{label}</p>}
                        {header && (
                            <div className="header-children">{header}</div>
                        )}
                        {(filter || download) && (
                            <div className="header-filter">
                                {download}
                                {filter}
                            </div>
                        )}
                    </div>
                )}
                <RenderItemOverflow
                    loading={loading}
                    totalItems={data.length}
                    maxHeight={maxHeight}
                >
                    <table>
                        <thead>
                            <tr>
                                {headers.map((row, rowKey) => (
                                    <th
                                        style={{
                                            width:
                                                row.type === 'button' ||
                                                row.type === 'checkbox'
                                                    ? '10px'
                                                    : 'auto',
                                        }}
                                        key={`thead-${rowKey}`}
                                    >
                                        {row.type === 'checkbox' && (
                                            <CheckboxDefault
                                                selected={selectAll}
                                                onClick={handleSelectAll}
                                            />
                                        )}
                                        {row.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {loading === false && data.length === 0 && (
                                <tr>
                                    <td colSpan={headers.length}>
                                        <span
                                            style={{
                                                padding: '7.5px 0px',
                                                display: 'flex',
                                            }}
                                        >
                                            Nenhum registro encontrado.
                                        </span>
                                    </td>
                                </tr>
                            )}
                            {loading &&
                                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                    (key) => (
                                        <tr key={'loading-' + key}>
                                            {headers.map((head, headKey) => (
                                                <td
                                                    key={`loading-td-${headKey}`}
                                                >
                                                    <span>
                                                        <Skeleton />
                                                    </span>
                                                </td>
                                            ))}
                                        </tr>
                                    )
                                )}
                            {!loading &&
                                data.map((row, key) => (
                                    <tr key={key}>
                                        {headers.map((head, headKey) => (
                                            <td key={headKey}>
                                                {head.type === 'checkbox' && (
                                                    <CheckboxDefault
                                                        onChange={() =>
                                                            handleSetIds(
                                                                row._id
                                                            )
                                                        }
                                                        selected={
                                                            ids.filter(
                                                                (obj) =>
                                                                    obj ===
                                                                    row._id
                                                            ).length > 0
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                )}

                                                {head.type === 'component' && (
                                                    <RenderComponentTableTd
                                                        item={row}
                                                        Component={
                                                            head.RenderComponent
                                                        }
                                                    />
                                                )}
                                                {head.type === 'string' && (
                                                    <span className="text">
                                                        {row[head.indice] ??
                                                            row[head.tryIndice]}
                                                    </span>
                                                )}

                                                {head.type ===
                                                    'stringMinify' && (
                                                    <p
                                                        className="text-minify"
                                                        onClick={() =>
                                                            setModalMinify(
                                                                row[head.indice]
                                                            )
                                                        }
                                                    >
                                                        {row[head.indice]}
                                                    </p>
                                                )}

                                                {head.type ===
                                                    'stringObject' && (
                                                    <span className="text">
                                                        {row[
                                                            head.indice.split(
                                                                '.'
                                                            )[0]
                                                        ]
                                                            ? row[
                                                                  head.indice.split(
                                                                      '.'
                                                                  )[0]
                                                              ][
                                                                  head.indice.split(
                                                                      '.'
                                                                  )[1]
                                                              ]
                                                            : head.catch}
                                                    </span>
                                                )}
                                                {head.type === 'date' && (
                                                    <span
                                                        title={moment(
                                                            row[head.indice]
                                                        ).format(
                                                            'DD/MM/DD hh:mm'
                                                        )}
                                                        className="date"
                                                    >
                                                        {moment(
                                                            row[head.indice]
                                                        )
                                                            .locale('pt-br')
                                                            .fromNow()}
                                                    </span>
                                                )}

                                                {head.type === 'link' && (
                                                    <ButtonDefault
                                                        onClick={() =>
                                                            history.push(
                                                                `/${
                                                                    head.pathname
                                                                }/${
                                                                    row[
                                                                        head
                                                                            .indice
                                                                    ]
                                                                }`
                                                            )
                                                        }
                                                        theme={head.theme}
                                                    >
                                                        {head.children}
                                                    </ButtonDefault>
                                                )}

                                                {head.type === 'button' && (
                                                    <ButtonDefault
                                                        onClick={() =>
                                                            onClickButton(
                                                                row[
                                                                    head.indice
                                                                ],
                                                                head.event
                                                            )
                                                        }
                                                        theme={`${head.theme} icon`}
                                                    >
                                                        {head.children}
                                                    </ButtonDefault>
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </RenderItemOverflow>

                <div className="footer-table">
                    {headers.filter((obj) => obj.type === 'checkbox').length ? (
                        <ul
                            className={`config-checkbox ${
                                ids.length > 0 ? 'active' : 'hidden'
                            }`}
                        >
                            <li>
                                <IconConfig />
                            </li>
                            <ul>
                                {headers
                                    .filter((obj) => obj.type === 'checkbox')[0]
                                    .checkboxOptions.map((row, key) => (
                                        <li
                                            onClick={() =>
                                                handleOnClickCheckbox(ids, row)
                                            }
                                            key={key}
                                        >
                                            {row}
                                        </li>
                                    ))}
                            </ul>
                        </ul>
                    ) : (
                        <div></div>
                    )}

                    {data.length > 0 && (
                        <Paginator
                            loading={loading}
                            range={3}
                            totalPage={totalPage}
                            setPage={onPaginate}
                            page={page}
                            total={total}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
