import React, { useState, useCallback } from 'react';
import { api } from '../../Api/app';
import messageStore from '../../Store/MessageStore';
import { ButtonDefault } from '../Form/ButtonDefault/ButtonDefault';
import { SelectDefault } from '../Form/SelectDefault/SelectDefault';

import DragDropFile from '../Form/DragDropFile/DragDropFile';

import './ContactList.scss';
import { IconArrowLeftDown, IconUploadMac } from '../Icon/Icons';

export default function ImportContacts(props) {
    const [edited, setEdited] = useState(false);
    const [step, setStep] = useState('upload');
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [filename, setFilename] = useState('');

    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);

    const [columns, setColumns] = useState([]);
    const [columnsFilename, setColumnsFilename] = useState([]);
    const [columnsUser, setColumnsUser] = useState([]);

    const handleOnChange = useCallback(
        (key, value) => {
            columnsUser[key] = value;
            console.log(columnsUser);
            setColumnsUser([...columnsUser]);
            const newColumns = [];
            props.columns.forEach((row, key) => {
                newColumns.push([
                    row,
                    columnsUser[key] ? columnsUser[key] : null,
                ]);
            });

            setColumns([...newColumns]);
        },
        [columnsUser, props.columns]
    );

    async function handleUpload(file) {
        try {
            setLoadingUpload(true);
            var formData = new FormData();
            formData.append('file', file, file.name);
            let response = await api.post('contact-imports/upload', formData);
            setFilename(response.data.filename);
            setColumnsFilename([...response.data.columns]);
            handleSplit(response.data.filename);
            setLoadingUpload(false);
            setEdited(true);
            setStep('match');
        } catch (e) {
            console.log(e);
            messageStore.addError('Houve um erro ao ler o arquivo.');
            setLoadingUpload(false);
        }
    }

    async function handleSplit(filename) {
        try {
            let response = await api.post('contact-imports/split', {
                filename: filename,
            });

            setTotal(response.data.total);
        } catch (e) {
            return 'Houve um erro ao configurar o arquivo.';
        }
    }

    async function handleProcess(pageRefresh = 1) {
        try {
            if (columns.length === 0)
                throw new Error(
                    `É obrigatório o campo ${
                        props.send === 'Email' ? 'email' : 'celular'
                    }.`
                );

            if (!columns[0][1])
                throw new Error(
                    `É obrigatório o campo ${
                        props.send === 'Email' ? 'email' : 'celular'
                    }.`
                );

            if (pageRefresh === 1) props.handleEditColumn(columnsUser);

            const data = {
                listaId: props.list._id,
                filename: filename,
                page: pageRefresh,
                total: total,
                hasHeader: true,
                columns: columns,
            };

            setStep('progress');

            let response = await api.post('contact-imports/process', data);

            if (
                response.data.page > 1 &&
                response.data.total >= response.data.page
            ) {
                handleProcess(response.data.page);
                setPage(response.data.page);
            } else {
                setPage(0);
                setColumns([]);
                setColumnsFilename([]);
                setColumnsUser([]);
                props.setTab('list');
                messageStore.addSuccess('Importação completa.');
            }
        } catch (e) {
            messageStore.addError(e.message);
        }
    }

    function handleRedirect(tab) {
        if (edited) {
            messageStore.addConfirm(
                'Você tem certeza que deseja ir gerenciar suas colunas? Você vai perder as informações preenchidas.',
                () => {
                    props.setTab('columns');
                    props.setRedirect(tab);
                }
            );
        } else {
            props.setTab('columns');
            props.setRedirect(tab);
        }
    }

    return (
        <div className="import-contact">
            <div className="box-import">
                {step === 'upload' && (
                    <div className="step">
                        <div className="head-step">
                            <b className="number">1</b>
                            <p className="description-import">
                                Selecione a planilha, deve ter o formato de .csv
                                ou .txt
                            </p>
                        </div>
                        <div className="content-step">
                            <DragDropFile
                                onChange={handleUpload}
                                loading={loadingUpload}
                            />
                        </div>
                    </div>
                )}
                {step === 'match' && (
                    <div className="step">
                        <div className="head-step">
                            <b className="number">2</b>
                            <p className="description-import">
                                Faça uma referência entre a lista de contatos e
                                a planilha, ligue os nomes do cabeçalho.
                            </p>
                        </div>
                        <div className="content-step">
                            <form onSubmit={(e) => e.preventDefault()}>
                                {props.columns.map((row, key) => (
                                    <div
                                        key={`import-column-${key}`}
                                        className="column"
                                    >
                                        <div className="column-list">
                                            <IconArrowLeftDown />
                                            <span>{row}</span>
                                        </div>

                                        <SelectDefault
                                            value={columnsUser[key]}
                                            onChange={(e) =>
                                                handleOnChange(
                                                    key,
                                                    e.target.value
                                                )
                                            }
                                        >
                                            {columnsFilename.map(
                                                (columnKey, i) => (
                                                    <option
                                                        key={i}
                                                        disabled={
                                                            columnsUser.filter(
                                                                (obj) =>
                                                                    obj ===
                                                                    columnKey
                                                            ).length > 0
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        {columnKey}
                                                    </option>
                                                )
                                            )}
                                        </SelectDefault>
                                    </div>
                                ))}

                                <div className="buttons-foot">
                                    <ButtonDefault
                                        onClick={() => setStep('upload')}
                                    >
                                        Voltar
                                    </ButtonDefault>
                                    <ButtonDefault
                                        theme="primary"
                                        onClick={() => handleProcess(1)}
                                    >
                                        Confirmar
                                    </ButtonDefault>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {step === 'progress' && (
                    <div className="step">
                        <div className="head-step">
                            <b className="number">3</b>
                            <p className="description-import">
                                Aguarde enquanto a importação finalize
                            </p>
                        </div>
                        <div className="content-step">
                            <div className="container-progress">
                                <div className="icon">
                                    <IconUploadMac />
                                </div>
                                <div className="process-load">
                                    <div
                                        style={{
                                            width: (page / total) * 100 + '%',
                                        }}
                                    >
                                        <span>
                                            {`${((page / total) * 100).toFixed(
                                                0
                                            )}`}
                                            %
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="tutorial-box">
                <p className="title-tutorial">Central de dicas</p>
                <div className="item">
                    <span>
                        O sistema de importação de contatos é uma funcionalidade
                        que permite aos usuários enviar suas planilhas de
                        contatos de uma forma simples e intuitiva. Com o nosso
                        sistema, você não precisa se preocupar em alterar o
                        cabeçalho da planilha antes de fazer o upload, pois o
                        sistema é capaz de reconhecer os campos automaticamente.
                    </span>
                    <span>
                        No entanto, para que a importação seja realizada com
                        sucesso, é necessário que faça uma espécie de "Match"
                        entre os campos da sua planilha e as colunas do nosso
                        sistema. Isso significa que o sistema irá apresentar uma
                        tela onde você deve informar qual é o campo da planilha
                        dele que corresponde a cada uma das colunas do nosso
                        sistema.
                    </span>
                    <span>
                        Por exemplo, se o campo "nome" da sua planilha está na
                        primeira coluna, enquanto o nosso sistema espera que o
                        nome esteja na segunda coluna, basta selecionar o campo
                        "nome" da sua planilha e associá-lo à coluna correta do
                        nosso sistema. Dessa forma, o sistema será capaz de
                        importar os contatos corretamente, sem que haja confusão
                        entre os campos.
                    </span>
                    <span>
                        Caso queira, pode realizar o download de uma planilha
                        modelo.
                        <a href="/t2u-exemplo-csv.csv" target="_blank">
                            Download planilha modelo
                        </a>
                    </span>
                    <span>
                        Seu contato tem mais informações como CPF, Endereço ou
                        outros campos e quer adicionar?
                    </span>
                    <ButtonDefault
                        theme="success"
                        onClick={() => handleRedirect(props.tab)}
                    >
                        Adicione novas colunas
                    </ButtonDefault>
                </div>
            </div>
        </div>
    );
}
