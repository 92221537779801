import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { TOKEN } from '../../Api/app';
import { IconHamburger, IconLogo, IconLogoPolvo } from '../Icon/Icons';
import HeaderLayoutPrivate from './Header';
import { Scrollbars } from 'react-custom-scrollbars';
import { useGlobal } from '../../contexts/GlobalContext';
import './LayoutPrivate.scss';

import ListPages from './pages.json';
import { RenderItemMenu } from './UtilsLayoutPrivate';
import { LoadingBox } from '../Utils/LoadingBox';

export default function LayoutPrivate(props) {
    const { setRefScroll, theme } = useGlobal();

    const elementRefScroll = useRef();
    const [pages, setPages] = useState(ListPages.pages);
    const [submenuMini, setSubmenuMini] = useState(
        window.localStorage.getItem('@octobar') ? true : false
    );

    const pagesSettings = ListPages.settings;

    useEffect(() => {
        if (!TOKEN) {
            window.location.href = '/login';
            return false;
        }

        setPages([...ListPages.pages]);
        elementRefScroll.current.scrollTop();
    }, [props]);

    const [menuFixed, setMenuFixed] = useState(false);
    const [widthHeader, setWidthRender] = useState('100%');
    const refRender = useRef();

    const handleResize = useCallback(() => {
        if (refRender.current) {
            setWidthRender(refRender.current.offsetWidth);
        }
    }, [refRender]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refRender]);

    useEffect(() => {
        setTimeout(() => {
            handleResize();
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submenuMini]);

    useEffect(() => {
        if (elementRefScroll.current) setRefScroll(elementRefScroll.current);
    }, [elementRefScroll, setRefScroll]);

    useEffect(() => {
        if (submenuMini) {
            window.localStorage.setItem('@octobar', 'true');
        } else {
            window.localStorage.removeItem('@octobar');
        }
    }, [submenuMini]);

    return !window.localStorage.getItem('token') ? (
        <LoadingBox />
    ) : (
        <div id={`layout-private`} className={`var-${theme}`}>
            <div className={`container ${submenuMini ? 'mini' : 'normal'} `}>
                <div id="sidebar">
                    <div className="logo">
                        <Link to="/">
                            {!submenuMini ? <IconLogo /> : <IconLogoPolvo />}
                        </Link>
                        <button
                            className="hamburger"
                            onClick={() => setSubmenuMini(!submenuMini)}
                        >
                            <IconHamburger />
                        </button>
                    </div>

                    <Scrollbars
                        hideTracksWhenNotNeeded={true}
                        autoHide={true}
                        style={{
                            width: '100%',
                            height: 'calc(100% - 124px)',
                        }}
                    >
                        <div className="padding-scroll">
                            <ul className="menu-sidebar pages">
                                {pages.map((menu, key) => (
                                    <RenderItemMenu
                                        pathname={props.location.pathname}
                                        key={key}
                                        menu={menu}
                                        octobar={submenuMini}
                                        setOctobar={setSubmenuMini}
                                    />
                                ))}
                            </ul>

                            <ul
                                className="menu-sidebar settings"
                                style={{
                                    marginTop: 100,
                                }}
                            >
                                {pagesSettings.map((menu, key) => (
                                    <RenderItemMenu
                                        pathname={props.location.pathname}
                                        key={key}
                                        menu={menu}
                                        octobar={submenuMini}
                                        setOctobar={setSubmenuMini}
                                    />
                                ))}
                            </ul>
                        </div>
                    </Scrollbars>
                </div>

                <div id="content-page">
                    <Scrollbars
                        ref={elementRefScroll}
                        onScroll={(e) =>
                            e.currentTarget.scrollTop > 50
                                ? setMenuFixed(true)
                                : setMenuFixed(false)
                        }
                        hideTracksWhenNotNeeded={false}
                        autoHide={false}
                        id="scroll-page"
                        className={menuFixed ? 'menu-fixed' : 'menu-normal'}
                        style={{ width: '100%', height: '100%' }}
                    >
                        <div className="content-padding">
                            <HeaderLayoutPrivate width={widthHeader} />
                            <section ref={refRender} id="render-page">
                                {props.children}
                            </section>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </div>
    );
}
