import React from 'react';
import moment from 'moment';
import { IconBatery, IconWifi } from '../Icon/Icons';

import './PhonePreview.scss';
import Scrollbars from 'react-custom-scrollbars';

export const PhonePreview = ({ children, title, ...rest }) => {
    return (
        <div className="phone-preview" {...rest}>
            <div className="head-phone">
                <div>
                    <span>{moment().format('hh:mm')}</span>
                </div>
                <div>
                    <span>
                        <IconWifi />
                    </span>
                    <span>
                        <IconBatery />
                    </span>
                </div>
            </div>
            <div className="content-phone">
                <div className="children-item">
                    <div className="head-item">
                        <span>{title}</span>
                    </div>
                    <Scrollbars
                        style={{
                            height: 'calc(100% - 80px)',
                        }}
                        className="scroll"
                    >
                        {children}
                    </Scrollbars>
                </div>
            </div>
        </div>
    );
};
