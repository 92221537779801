import React, { useState } from 'react';
import H1Page from '../../Components/Layout/H1Page';

import './GestaoContatos.scss';
import EditListNoModal from '../../Components/ContactList/EditListNoModal';

export default function Contatos(props) {
    const id = props.match.params.id ? props.match.params.id : '';
    const [dataInicial, setDataInicial] = useState('');

    const [dataFinal, setDataFinal] = useState('');

    const item = props.location.state.item;

    const [statusHigienizacao, setStatusHigienizacao] = useState('');

    const [search, setSearch] = useState(null);

    return (
        <div id="contatos" className="page">
            <div className="h1-button">
                <H1Page nome={'Contatos'} />
            </div>

            <form onSubmit={(e) => e.preventDefault()} className="filter-page">
                <label>
                    <input
                        onBlur={(e) => setSearch(e.target.value)}
                        type="text"
                        name="search"
                        placeholder="Buscar"
                    />
                </label>
                <label>
                    <input
                        onChange={(e) => setDataInicial(e.target.value)}
                        type="date"
                        name="dataInicial"
                    />
                </label>
                <label>
                    <input
                        onChange={(e) => setDataFinal(e.target.value)}
                        type="date"
                        name="dataFinal"
                    />
                </label>
                <label>
                    <select
                        value={statusHigienizacao}
                        onChange={(e) => setStatusHigienizacao(e.target.value)}
                    >
                        <option value="">Status de higienização</option>
                        <option value="Pendente">
                            Pendente (Não processado)
                        </option>
                        <option value="Inválido">Inválido</option>
                        <option value="Válido">
                            Válido (Telefone fixo e celular)
                        </option>
                    </select>
                </label>
                <button className="button-zig primary">Buscar</button>
            </form>

            <div className="infos-report">
                <h4>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                    >
                        <g fill="none">
                            <circle
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="2"
                            />
                            <path
                                d="M12 7h.01"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                            <path
                                d="M10 11h2v5"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M10 16h4"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                    </svg>
                    Status de higienização
                </h4>
                <p>
                    Pendente: Quando ainda não processamos o número de telefone,
                    ainda está em uma fila de espera.
                </p>
                <p>
                    Válido: Quando processamos o número de telefone e tivemos
                    algum retorno que ele existe.
                </p>
                <p>
                    Inválido: Quando processamos o número de telefone e não
                    tivemos retornos que existe.
                </p>
            </div>

            <EditListNoModal
                id={id}
                open={true}
                callbackChangeColumns={() => {}}
                callbackChangeName={() => {}}
                send={item.tipo}
                search={search}
                dataInicial={dataInicial}
                dataFinal={dataFinal}
                statusHigienizacao={statusHigienizacao}
            />
        </div>
    );
}
