import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { api } from '../Api/app';

const GlobalContext = createContext();

export default function GlobalProvider({ children }) {
    const [theme, setTheme] = useState(
        window.localStorage.getItem('@theme') ?? 'light'
    );
    const [refScroll, setRefScroll] = useState({});
    const [loadingUser, setLoadingUser] = useState(true);
    const [user, setUser] = useState({
        accountId: '',
        account: {
            nome: '',
            brokers: [],
        },
        listaPermissoes: [],
    });
    const [loadingNotification, setLoadingNotification] = useState(true);
    const [notifications, setNotifications] = useState({
        total: 0,
        pages: 0,
        page: 0,
        limit: 0,
        docs: [
            {
                mensagem: 'Lorem ipsum',
            },
            {
                mensagem: 'Lorem ipsum dollor',
            },
            {
                mensagem:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum euismod, lorem quis bibendum tempus, odio risus',
            },
        ],
    });

    const getProfile = useCallback(async () => {
        try {
            const response = await api.get('profile');
            setUser({ ...response.data });
            setLoadingUser(false);
        } catch (err) {
            setLoadingUser(false);
            console.log('error profile');
        }
    }, []);

    const getNotifications = useCallback(async () => {
        try {
            const response = await api.get('logs');
            setNotifications({ ...response.data });
            setLoadingNotification(false);
        } catch (err) {
            setLoadingNotification(false);
            console.log('error profile');
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem('@theme', theme);
    }, [theme]);

    useEffect(() => {
        if (window.localStorage.getItem('token')) {
            getProfile();
            getNotifications();
        }
    }, [getProfile, getNotifications]);

    return (
        <GlobalContext.Provider
            value={{
                user,
                setUser,
                loadingUser,
                notifications,
                loadingNotification,
                refScroll,
                setRefScroll,
                theme,
                setTheme,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}

export function useGlobal() {
    const context = useContext(GlobalContext);
    const {
        user,
        setUser,
        loadingUser,
        notifications,
        loadingNotification,
        refScroll,
        setRefScroll,
        theme,
        setTheme,
    } = context;
    return {
        user,
        setUser,
        loadingUser,
        notifications,
        loadingNotification,
        refScroll,
        setRefScroll,
        theme,
        setTheme,
    };
}
