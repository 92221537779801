import { api } from './app';

export const updateProfile = async ({
    accountId,
    nome,
    email,
    cpf,
    nascimento,
    password,
    passwordConfirm,
}) => {
    const response = await api.put('profile/update', {
        accountId,
        nome,
        email,
        cpf,
        nascimento,
        password,
        passwordConfirm,
    });

    return response;
};
