import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../Api/app';
import H1Page from '../../Components/Layout/H1Page';
import Paginator from '../../Components/Paginator/Paginator';
import messageStore from '../../Store/MessageStore';
import Skeleton from 'react-loading-skeleton';
//scss
import './administracao.scss';

export default function Contas(props) {
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);

    const [page, setPage] = useState(1);

    const getData = useCallback(async () => {
        try {
            setLoad(true);
            const response = await api.get('accounts?page=' + page);

            setData(response.data);
            setLoad(false);
        } catch (e) {
            console.log(e);
            messageStore.addError('Erro ao listar.');
        }
    }, [page]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <div id="administracao" className="page">
            <H1Page nome="Contas" />

            <Link to="/adm-contas/new">
                <button type="button" className="button-zig column primary">
                    <span>Nova conta</span>
                </button>
            </Link>

            <table className="table-default">
                <thead>
                    <tr>
                        <th width="10"></th>
                        <th width="10">Conta</th>
                        <th>Usuarios</th>
                        <th>Whatsapp</th>
                        <th>Whatsapp Oficial</th>
                        <th>SMS</th>
                        <th>E-mail</th>
                    </tr>
                </thead>

                <tbody>
                    {load === true &&
                        [0, 1, 2, 3, 4, 5, 6].map((row, key) => (
                            <tr key={key}>
                                <td colSpan="7">
                                    <Skeleton />
                                </td>
                            </tr>
                        ))}
                    {load === false &&
                        data.docs.map((row, key) => (
                            <tr key={key}>
                                <td style={{ verticalAlign: 'top' }}>
                                    <Link to={'/adm-contas/' + row._id}>
                                        <button
                                            type="button"
                                            className="button-zig secondary"
                                        >
                                            <span>Editar conta</span>
                                        </button>
                                    </Link>
                                </td>
                                <td
                                    style={{
                                        whiteSpace: 'nowrap',
                                        verticalAlign: 'top',
                                        paddingTop: '18px',
                                    }}
                                >
                                    {row.nome}
                                </td>
                                <td>
                                    {row.users.map((user, userKey) => (
                                        <tr key={userKey}>
                                            <td width="110">
                                                <Link
                                                    to={
                                                        '/adm-contas/usuarios/' +
                                                        user._id
                                                    }
                                                >
                                                    <button
                                                        type="button"
                                                        className="button-zig secondary"
                                                    >
                                                        <span>
                                                            Editar usuario
                                                        </span>
                                                    </button>
                                                </Link>
                                            </td>
                                            <td align="left">
                                                <div className="perfil-user">
                                                    <div
                                                        className="photo-list"
                                                        style={{
                                                            backgroundImage:
                                                                'url(' +
                                                                user.foto +
                                                                ')',
                                                        }}
                                                    ></div>
                                                    <span>{user.nome}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    <Link
                                        to={
                                            '/adm-contas/usuarios/new/' +
                                            row._id
                                        }
                                    >
                                        <button
                                            type="button"
                                            className="button-zig column primary"
                                            style={{ marginTop: 10 }}
                                        >
                                            <span>Inserir novo usuário</span>
                                        </button>
                                    </Link>
                                </td>

                                <td>
                                    {row.brokers.filter(
                                        (obj) =>
                                            obj.tipo === 'Whatsapp' &&
                                            obj.oficial === false
                                    ).length ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 1024 1024"
                                        >
                                            <path
                                                d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    ) : (
                                        ''
                                    )}
                                </td>

                                <td>
                                    {row.brokers.filter(
                                        (obj) =>
                                            obj.tipo === 'Whatsapp' &&
                                            obj.oficial === true
                                    ).length ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 1024 1024"
                                        >
                                            <path
                                                d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    ) : (
                                        ''
                                    )}
                                </td>

                                <td>
                                    {row.brokers.filter(
                                        (obj) => obj.tipo === 'SMS'
                                    ).length ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 1024 1024"
                                        >
                                            <path
                                                d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    ) : (
                                        ''
                                    )}
                                </td>

                                <td>
                                    {row.brokers.filter(
                                        (obj) => obj.tipo === 'Email'
                                    ).length ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 1024 1024"
                                        >
                                            <path
                                                d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    ) : (
                                        ''
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <div className="tfoot">
                <Paginator
                    range={10}
                    totalPage={data.pages}
                    setPage={setPage}
                    page={page}
                />
            </div>
        </div>
    );
}
