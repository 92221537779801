import { api } from './app';

export const HeadersCampanha = [
    {
        indice: 'id',
        label: 'ID',
        type: 'string',
    },
    {
        indice: 'nome',
        label: 'Campanha',
        type: 'string',
    },
    {
        indice: 'tipoDeEnvio',
        label: 'Canal',
        type: 'string',
    },
    {
        indice: 'createdAt',
        label: 'Data de criação',
        type: 'date',
    },
    {
        indice: 'dataEnvio',
        label: 'Data de envio',
        type: 'date',
    },
    {
        indice: 'id',
        label: '',
        type: 'link',
        pathname: 'campanhas',
        theme: 'primary',
        children: 'Detalhes',
    },
];

export const getCampanhas = async (page, { source = {} }) => {
    const getCampanhas = await api.get('/campaigns?page=' + page, {
        cancelToken: source.token,
    });
    return getCampanhas;
};

export const getCampanha = async (
    id,
    dataInicial,
    dataFinal,
    { source = {} }
) => {
    const getCampanhas = await api.get(
        `/campaigns/${id}?dataInicial=${dataInicial}&dataFinal=${dataFinal}`,
        {
            cancelToken: source.token,
        }
    );
    return getCampanhas;
};

export const newCampanha = async (DTO) => {
    const response = await api.post('campaigns', DTO);
    return response;
};

export const sendQueueCampanha = async (id) => {
    const response = await api.post('/queues/' + id);
    return response;
};

export const sendCron = async ({ id, canal }) => {
    const response = await api.get('cron/' + canal.toLowerCase(), {
        headers: {
            Authorization:
                'Bearer eyJhbGciOiJIUzI1NiJ9.NjFmOThkNzdkYjUxMWMxMTkwMGY1MWNl.SU2KHPWDk4ajhqUuviwqm4xqPS9GiHAPN8M04xOQRgE',
        },
    });
    return response;
};
