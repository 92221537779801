import React, { useState, useEffect } from 'react';
import { ReactSortable } from 'react-sortablejs';
import LabelColor from '../../Edition/LabelColor';
import FieldsetWidget from '../../Edition/FieldsetWidget';
import FormEditWidget from '../../Edition/FormEditWidget';
import WidgetRender from '../../WidgetRender/WidgetRender';

import './Columns.scss';
import messageStore from '../../../../Store/MessageStore';

const ColumnElement = (props) => {
    const [items, setItems] = useState(...[props.row]);

    const options = props.item.options;

    const styleOptions = {
        padding: options.padding,
        backgroundColor: options.backgroundColor,
        minHeight:
            options.minHeight !== 'auto'
                ? options.minHeight + options.minHeightType
                : 'auto',
    };

    useEffect(() => {
        setItems(...[props.row]);
    }, [props]);

    function handleSetItems(state, e) {
        if (
            state.filter((obj) => obj.template.className === 'column column-1')
                .length ||
            state.filter((obj) => obj.template.className === 'column column-2')
                .length ||
            state.filter((obj) => obj.template.className === 'column column-3')
                .length
        ) {
            messageStore.addError(
                'Não é possivel inserir uma coluna dentro de outra coluna.'
            );
        } else if (e && props.edit === true) {
            setItems(state);
            props.handleSetList(state, props.keyElement);
        }
    }

    return (
        <td
            className="item-column"
            style={{
                backgroundColor: styleOptions.backgroundColor,
                padding: props.item.options.padding,
            }}
        >
            {props.edit ? (
                <ReactSortable
                    group="shared"
                    animation={0}
                    list={items}
                    setList={(state, e) => handleSetItems(state, e)}
                    onEnd={(e) => {}}
                    style={{ minHeight: styleOptions.minHeight }}
                >
                    {items &&
                        items.map((item, itemkey) => (
                            <WidgetRender
                                id={item.id}
                                item={item.template}
                                indice={props.keyElement}
                                key={itemkey}
                                editId={props.editId}
                                template={props.template}
                                editWidget={props.editWidget}
                                removeWidget={props.handleRemoveList}
                                edit={props.edit}
                                setLoading={props.setLoading}
                            />
                        ))}
                </ReactSortable>
            ) : (
                <>
                    {items &&
                        items.map((item, itemkey) => (
                            <WidgetRender
                                id={item.id}
                                item={item.template}
                                indice={props.keyElement}
                                key={itemkey}
                                editId={props.editId}
                                template={props.template}
                                editWidget={props.editWidget}
                                removeWidget={props.handleRemoveList}
                                edit={props.edit}
                                setLoading={props.setLoading}
                            />
                        ))}
                </>
            )}
        </td>
    );
};

const Column = (props) => {
    const [content, setContent] = useState([]);

    useEffect(() => {
        setContent(...[props.item.content]);
    }, [props]);

    function handleSetList(state, indice) {
        var newContent = [];

        content.forEach((row, key) => {
            newContent[key] = row;

            if (key === indice) {
                newContent[key] = state;
            }
        });
        props.updateWidgetColumn(newContent, props.id);
    }

    function handleRemoveList(id) {
        var newContent = [];

        content.forEach((row, key) => {
            newContent[key] = row.filter((obj) => obj.id !== id);
        });

        props.updateWidgetColumn(newContent, props.id);
        setContent(...newContent);
    }

    return (
        <table
            style={{ width: '100%' }}
            cellSpacing={0}
            cellPadding={0}
            className="columns"
        >
            <tr>
                {content.map((row, key) => (
                    <ColumnElement
                        key={key}
                        edit={props.edit}
                        handleRemoveList={handleRemoveList}
                        handleSetList={handleSetList}
                        row={row}
                        keyElement={key}
                        {...props}
                    />
                ))}
            </tr>
        </table>
    );
};

const ColumnEdit = ({ id, item, onSave, onClose }) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        setOptions({});
        setOptions(item.options);
        onSave(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    function handleOnChange(e) {
        //setLoad(true)
        options[e.target.name] = e.target.value;
        onSave(options);
        setOptions({});
    }

    return (
        <FormEditWidget onSave={onSave} item={item}>
            <FieldsetWidget legend="Altura minima">
                <input
                    type="text"
                    onChange={(e) => handleOnChange(e)}
                    name="minHeight"
                    defaultValue={options.minHeight}
                />
                <select
                    onChange={(e) => handleOnChange(e)}
                    name="minHeightType"
                    defaultValue={options.minHeightType}
                >
                    <option value="px">pixel</option>
                </select>
            </FieldsetWidget>

            <FieldsetWidget legend="Espaçamento">
                <select
                    onChange={(e) => handleOnChange(e)}
                    name="padding"
                    value={options.padding}
                >
                    <option value="0px">0</option>
                    <option value="1px">1</option>
                    <option value="2px">2</option>
                    <option value="3px">3</option>
                    <option value="4px">4</option>
                    <option value="5px">5</option>
                    <option value="6px">6</option>
                    <option value="7px">7</option>
                    <option value="8px">8</option>
                    <option value="9px">9</option>
                    <option value="10px">10</option>
                </select>

                <div>
                    <LabelColor
                        value="Cor do Fundo"
                        name="backgroundColor"
                        onChange={handleOnChange}
                        color={options.backgroundColor}
                    />
                </div>
            </FieldsetWidget>
        </FormEditWidget>
    );
};

export { Column, ColumnEdit };
