import { api } from './app';
import mockup from './Dashboard.mockup.json';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

export const MockupEnviosPorHora = mockup;

export const ListBrokers = [
    {
        name: 'sms',
        value: 0,
        color: COLORS[0],
    },
    {
        name: 'email',
        value: 0,
        color: COLORS[1],
    },
    {
        name: 'rcs',
        value: 0,
        color: COLORS[2],
    },
];

export const HeadersEnvios = [
    {
        indice: 'campaign.nome',
        label: 'Campanha',
        type: 'stringObject',
        catch: 'Mensagem de teste',
    },
    {
        indice: 'tipoDeEnvio',
        label: 'Origem',
        type: 'string',
    },
    {
        indice: 'telefone',
        tryIndice: 'email',
        label: 'Destinatário',
        type: 'string',
    },
    {
        indice: 'mensagem',
        label: 'Mensagem',
        type: 'stringMinify',
    },
    {
        indice: 'statusPadrao',
        label: 'Status',
        type: 'string',
    },
    {
        indice: 'createdAt',
        label: 'Data de criação',
        type: 'date',
    },
];

export const HeadersAnalyticsLandingPageClick = [
    {
        indice: 'campaign.nome',
        label: 'Campanha',
        type: 'stringObject',
        catch: '',
    },
    {
        indice: 'campaign.tipoDeEnvio',
        label: 'Canal',
        type: 'stringObject',
    },
    {
        indice: 'contact.field1',
        label: 'Destinatário',
        type: 'stringObject',
    },

    {
        indice: 'tipo',
        label: 'Tipo do clique',
        type: 'string',
    },
    {
        indice: 'texto',
        label: 'Nome do clique',
        type: 'string',
    },
    {
        indice: 'link',
        label: 'Origem do clique',
        type: 'stringMinify',
    },
    {
        indice: 'createdAt',
        label: 'Data',
        type: 'date',
    },
];

export const HeadersAnalyticsLandingPageView = [
    {
        indice: 'campaign.nome',
        label: 'Campanha',
        type: 'stringObject',
        catch: '',
    },
    {
        indice: 'campaign.tipoDeEnvio',
        label: 'Canal',
        type: 'stringObject',
    },
    {
        indice: 'contact.field1',
        label: 'Destinatário',
        type: 'stringObject',
    },
    {
        indice: 'so',
        label: 'Sistema operacional',
        type: 'string',
    },
    {
        indice: 'browser',
        label: 'Browser',
        type: 'string',
    },
    {
        indice: 'createdAt',
        label: 'Data',
        type: 'date',
    },
];

export const HeadersAnalyticsEmailClick = [
    {
        indice: 'campaign.nome',
        label: 'Campanha',
        type: 'stringObject',
        catch: '',
    },
    {
        indice: 'campaign.tipoDeEnvio',
        label: 'Canal',
        type: 'stringObject',
    },
    {
        indice: 'contact.field1',
        label: 'Destinatário',
        type: 'stringObject',
    },
    {
        indice: 'link',
        label: 'Origem do clique',
        type: 'stringMinify',
    },
    {
        indice: 'createdAt',
        label: 'Data',
        type: 'date',
    },
];

export const MockupBroker = () => {
    const listBrokersNew = [];
    ListBrokers.forEach((row) => {
        let random = Math.random();
        row.value = Math.floor(random * 91) + 10;
        listBrokersNew.push(row);
    });
    return listBrokersNew;
};

export const MockupSendByHour = () => {
    const dataNew = [];

    mockup.forEach((row) => {
        ListBrokers.forEach((brok) => {
            let random = Math.random();
            row[brok.name] = Math.floor(random * 91) + 10;
        });
        dataNew.push(row);
    });

    return dataNew;
};

export const getEnviosPorHora = async ({ dataInicial }, { source }) => {
    const sendHour = await api.get(
        'dashboards/envios-por-hora?data=' +
            dataInicial +
            {
                cancelToken: source.token,
            }
    );

    const renewBrokers = [];
    ListBrokers.forEach((row) => {
        renewBrokers.push({
            ...row,
            value: 0,
        });
    });

    //ListBrokers.splist();

    let total = 0;
    sendHour.data.results.forEach((row) => {
        renewBrokers.forEach((broker, index) => {
            renewBrokers[index].value += row[broker.name];
            total += row[broker.name];
        });
    });

    console.log('total', total);
    renewBrokers.forEach((finalBroker) => {
        finalBroker.percent = parseInt(
            ((finalBroker.value / total) * 100).toFixed(0)
        );
    });

    return {
        hours: sendHour.data.results,
        brokers: renewBrokers,
    };
};

export const getEnviosPorPeriodo = async (
    { dataInicial, dataFinal },
    { source }
) => {
    const sendHour = await api.get(
        'dashboards/envios-por-periodo?dataInicial=' +
            dataInicial +
            '&dataFinal=' +
            dataFinal,
        {
            cancelToken: source.token,
        }
    );

    const renewBrokers = [];
    ListBrokers.forEach((row) => {
        renewBrokers.push({
            ...row,
            value: 0,
        });
    });

    //ListBrokers.splist();

    let total = 0;
    sendHour.data.results.forEach((row) => {
        renewBrokers.forEach((broker, index) => {
            renewBrokers[index].value += row[broker.name];
            total += row[broker.name];
        });
    });

    console.log('total', total);
    renewBrokers.forEach((finalBroker) => {
        finalBroker.percent = parseInt(
            ((finalBroker.value / total) * 100).toFixed(0)
        );
    });

    return {
        result: sendHour.data.results,
        brokers: renewBrokers,
    };
};

export const getEnvios = async (
    {
        id = '',
        tipoDeEnvio = '',
        status = '',
        dataInicial = '',
        dataFinal = '',
        page = 1,
    },
    { source }
) => {
    const url =
        'queues?campaignId=' +
        id +
        '&tipoDeEnvio=' +
        tipoDeEnvio +
        '&status=' +
        status +
        '&dataInicial=' +
        dataInicial +
        '&dataFinal=' +
        dataFinal +
        '&page=' +
        page;
    const response = await api.get(url, {
        cancelToken: source.token,
    });
    return response;
};

export const getEnviosDownload = async (
    {
        id = '',
        tipoDeEnvio = '',
        status = '',
        dataInicial = '',
        dataFinal = '',
        page = 1,
    },
    { source }
) => {
    const url =
        'queues/csv?campaignId=' +
        id +
        '&tipoDeEnvio=' +
        tipoDeEnvio +
        '&status=' +
        status +
        '&dataInicial=' +
        dataInicial +
        '&dataFinal=' +
        dataFinal +
        '&page=' +
        page;
    const response = await api.get(url, {
        cancelToken: source.token,
    });
    return response;
};
