import React, { useState, useEffect } from 'react';
import { api } from '../../Api/app';
import messageStore from '../../Store/MessageStore';
import { InputDefault } from '../Form/InputDefault/InputDefault';
import { ButtonDefault } from '../Form/ButtonDefault/ButtonDefault';

import './ContactList.scss';

export default function EditContact(props) {
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [values, setValues] = useState({
        contactListId: props.list._id,
    });

    useEffect(() => {
        setColumns([...props.columns]);
        if (props.contact) {
            setValues({ ...props.contact });
        }
    }, [props.columns, props.contact]);

    function validatePhone(phone) {
        phone = phone
            .replace(' ', '')
            .replace(' ', '')
            .replace(' ', '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', '');

        var regex = new RegExp(
            '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'
        );
        return regex.test(phone);
    }

    const [errors, setErrors] = useState([]);

    async function handleNewContact(e) {
        try {
            e.preventDefault();
            setLoading(true);
            const validateDarklist = await api.get(
                `blacklists?page=1&search=${values['field1']}`
            );

            if (validateDarklist.data.total > 0) {
                if (validateDarklist.data.docs.length > 0) {
                    throw new Error(
                        `Não foi possivel adicionar o contato, pois ele está na Blocklist pelo motivo ${validateDarklist.data.docs[0].motivo}`
                    );
                }
            }

            const newErrors = [];

            columns.forEach((row, key) => {
                if (
                    !values[`field${key + 1}`] &&
                    (row.toLowerCase() === 'celular' ||
                        row.toLowerCase() === 'email')
                ) {
                    newErrors.push(row);
                }
            });
            setErrors([...newErrors]);

            if (newErrors.length) {
                throw new Error(`Campo ${newErrors[0]} é obrigatório1.`);
            }

            if (columns[0] === 'Celular' && !values.field1)
                throw new Error(`Campo ${columns[0]} é obrigatório2.`);

            if (columns[0] === 'Email' && !values.field1)
                throw new Error('Campo email é obrigatório3.');

            if (
                columns[0] === 'Celular' &&
                validatePhone(values.field1) === false
            )
                throw new Error('Campo celular incorreto.');
            if (
                columns[0] === 'Email' &&
                values.field1.split('@').length <= 1 &&
                values.field1.split('.').length <= 1
            )
                throw new Error('Campo e-mail incorreto.');

            var listEdit = await api.put('contactLists/' + props.list._id);
            let contactEdit;

            if (listEdit.data.error === true)
                throw new Error(
                    'Houve um erro ao salvar, tente novamente mais tarde.'
                );

            if (props.contact) {
                contactEdit = await api.put(
                    'contacts/' + props.contact._id,
                    values
                );

                if (contactEdit.data.error === true)
                    throw new Error(
                        'Houve um erro ao salvar, tente novamente mais tarde.'
                    );

                props.handleEditColumn(columns);
            } else {
                contactEdit = await api.post('contacts/', values);

                if (contactEdit.data.error === true)
                    throw new Error(
                        'Houve um erro ao salvar, tente novamente mais tarde.'
                    );

                values._id = contactEdit.data.result._id;

                props.handleEditColumn(columns);
            }

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });

            props.setTab('list');

            messageStore.addSuccess('Contato salvo com sucesso.');

            setLoading(false);
        } catch (err) {
            console.log(err);
            messageStore.addError(err.message);
            setLoading(false);
        }
    }

    function handleOnChange(name, value, coluna) {
        setErrors([...errors.filter((obj) => obj !== coluna)]);

        if (name === 'field1')
            value = value.replace('+', '').replace('(', '').replace(')', '');

        values[name] = value;
        setValues({ ...values });
    }

    function handleRedirect(tab) {
        let alertRedirect = false;

        columns.forEach((row, indice) => {
            if (values['field' + (indice + 1)]) alertRedirect = true;
        });

        if (alertRedirect) {
            messageStore.addConfirm(
                'Você tem certeza que deseja ir gerenciar suas colunas? Você vai perder as informações preenchidas.',
                () => {
                    props.setTab('columns');
                    props.setRedirect(tab);
                }
            );
        } else {
            props.setTab('columns');
            props.setRedirect(tab);
        }
    }

    return (
        <div className="edit-contact">
            <form onSubmit={handleNewContact}>
                {columns.map((coluna, indice) => (
                    <div className="value" key={`column-${indice}`}>
                        <div className="inputs-edit">
                            <InputDefault
                                key={`input-edit-contact-${indice}`}
                                label={coluna}
                                value={values['field' + (indice + 1)]}
                                onChange={(e) =>
                                    handleOnChange(
                                        'field' + (indice + 1),
                                        e.target.value,
                                        coluna
                                    )
                                }
                            />
                        </div>
                        {errors.filter((obj) => obj === coluna).length > 0 && (
                            <div className="error">
                                <span>Campo obrigatório</span>
                            </div>
                        )}
                    </div>
                ))}
                <ButtonDefault theme={'primary'} loading={loading}>
                    Confirmar
                </ButtonDefault>
            </form>
            <div className="tutorial-box">
                <p className="title-tutorial">Central de dicas</p>

                <div className="item">
                    <span>
                        O campo "celular", deve ser preenchido com DDD e Número,
                        exemplo: 11912345678
                    </span>
                    <span>
                        O campo "email", deve ser preenchido corretamente,
                        exemplo: nome@exemplo.com
                    </span>
                </div>

                <div className="item">
                    <span>
                        Seu contato tem mais informações como CPF, Endereço ou
                        outros campos e quer adicionar?
                    </span>
                    <ButtonDefault
                        theme="success"
                        onClick={() => handleRedirect(props.tab)}
                    >
                        Adicione novas colunas
                    </ButtonDefault>
                </div>
            </div>
        </div>
    );
}
