import React from 'react';
import { ResponsiveContainer, Tooltip, XAxis, Area, AreaChart } from 'recharts';
import './Dashboard.scss';

export const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="tooltip-chart">
                <p className="title">{label}</p>
                <ul className="legend-tooltip">
                    {payload.map((pay) => (
                        <li key={pay.dataKey}>
                            <i
                                style={{
                                    backgroundColor: pay.stroke,
                                }}
                            />
                            <span>
                                {pay.dataKey} <b>({pay.value})</b>
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
    return null;
};

export const CustomAxis = ({ x, y, stroke, payload }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={10} y={0} dy={16} textAnchor="end" fill="#666">
                {payload.value}
            </text>
        </g>
    );
};

export default function TinyLineChart({ data = [], brokers = [] }) {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                data={data}
                margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            >
                <Tooltip content={<CustomTooltip />} />
                {brokers.map((row, key) => (
                    <Area
                        type="monotone"
                        dataKey={row.name}
                        stroke={row.color}
                        fill={row.color}
                        strokeWidth={2}
                        fillOpacity={0.1}
                        key={key}
                    />
                ))}
                <XAxis
                    axisLine={false}
                    tickLine={false}
                    dataKey="name"
                    tick={<CustomAxis />}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
}
