import React, { useState, useRef } from 'react';
import { IconUploadMac, IconLoading } from '../../Icon/Icons';
import { ButtonDefault } from '../ButtonDefault/ButtonDefault';
import './DragDropFile.scss';

// drag drop file component
export default function DragDropFile({
    textDrop = 'Arraste e solte seu arquivo aqui ou ',
    textButton = 'Escolha o arquivo',
    onChange = (file) => {},
    loading = false,
    accept,
}) {
    const [file, setFile] = useState({});
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFile(e.dataTransfer.files[0]);
            onChange(e.dataTransfer.files[0]);
        }
    };

    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
            onChange(e.target.files[0]);
        }
    };

    const onButtonClick = () => {
        inputRef.current.click();
    };

    return (
        <div className="drag-drop-file">
            <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input
                    ref={inputRef}
                    type="file"
                    className="input-file-upload"
                    onChange={handleChange}
                    accept={accept}
                />
                <label
                    className={`label-file-upload ${
                        dragActive && 'drag-active'
                    }`}
                    htmlFor="input-file-upload"
                >
                    <div>
                        <div className={`icon ${loading ? 'load' : 'normal'}`}>
                            {loading ? <IconLoading /> : <IconUploadMac />}
                        </div>
                        <p>{loading ? file.name : textDrop}</p>
                        {!loading && (
                            <ButtonDefault
                                theme="primary"
                                onClick={onButtonClick}
                            >
                                {textButton}
                            </ButtonDefault>
                        )}
                    </div>
                </label>
                {dragActive && !loading && (
                    <div
                        className="drag-file-element"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                    ></div>
                )}
            </form>
        </div>
    );
}
