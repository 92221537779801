import React from 'react';
import { IconRemove } from '../Components/Icon/Icons';
import { api } from './app';

export const HeadersBlockList = [
    {
        indice: 'blacklist',
        label: 'Desinatário',
        type: 'string',
    },
    {
        indice: 'motivo',
        label: 'motivo',
        type: 'string',
    },
    {
        indice: 'createdAt',
        label: 'Data de criação',
        type: 'date',
    },
    {
        indice: '_id',
        type: 'button',
        event: 'remove',
        theme: 'danger',
        children: <IconRemove />,
    },
];

export const getBlockLists = async (page) => {
    const response = await api.get('blacklists?page=' + page);
    return response;
};

export const addBlockList = async ({ blacklist, motivo }) => {
    const response = await api.post('blacklists', {
        blacklist,
        motivo,
    });
    return response;
};

export const removeBlockLists = async (id) => {
    const response = await api.delete('blacklists/' + id);
    return response;
};
