import Axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function BlacklistPublic() {
    const params = useParams();

    useEffect(() => {
        Axios.post('https://backend.t2u.com.br/blacklists/external', {
            slug: params.slug,
            blacklist: params.phone,
        });
    }, [params]);

    return (
        <div id="blacklist-public">
            <h1>Número bloqueado</h1>
            <p>
                Olá, seu número entrou na nossa blacklist, a partir de agora
                você não receberá mais envios.
            </p>
        </div>
    );
}
