import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

export const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="tooltip-chart">
                <ul className="legend-tooltip">
                    {payload.map((pay) => (
                        <>
                            <li>
                                <i
                                    style={{
                                        backgroundColor: pay.payload?.fill,
                                    }}
                                />
                                <span>{pay.name}</span>
                            </li>
                            <li key={pay.name}>
                                <span>
                                    Total <b>{pay.value}</b>
                                </span>
                            </li>
                            {pay.payload?.percent > 0 && (
                                <li key={pay.name}>
                                    <span>
                                        Porcentagem{' '}
                                        <b>
                                            {pay.payload?.percent.toFixed(0)}%
                                        </b>
                                    </span>
                                </li>
                            )}
                        </>
                    ))}
                </ul>
            </div>
        );
    }
    return null;
};

export default function PieChartWithPadding({ data, ...rest }) {
    const [dataInternal, setDataInternal] = useState([]);

    useEffect(() => {
        setDataInternal([...data.filter((obj) => obj.value > 0)]);
    }, [data]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Tooltip content={<CustomTooltip />} />
                <Pie
                    data={dataInternal}
                    cx={'50%'}
                    cy={'50%'}
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={dataInternal.length > 1 ? 5 : 0}
                    dataKey="value"
                    {...rest}
                >
                    {dataInternal.map(
                        (entry, index) =>
                            entry.value > 0 && (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                />
                            )
                    )}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}
