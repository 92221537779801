import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from '../../Api/app';
import FormDefault from '../../Components/Form/FormDefault';
import InputDefault from '../../Components/Form/InputDefault';
import InputRow from '../../Components/Form/InputRow';
import H1Page from '../../Components/Layout/H1Page';
import messageStore from '../../Store/MessageStore';
//scss
import './administracao.scss';

export default function ContasForm(props) {
    const [data, setData] = useState({
        endereco: {},
        brokers: [],
        pessoaTipo: 'PJ',
    });

    const [brokers, setBrokers] = useState([]);
    const history = useHistory();
    const id = props.match.params.id;

    const getData = useCallback(async () => {
        try {
            const responseBrokers = await api.get('brokers');

            setBrokers([...responseBrokers.data.docs]);

            if (id !== 'new') {
                const response = await api.get('accounts/' + id);
                setData({ ...response.data });
            }
        } catch (e) {
            console.log(e);
            messageStore.addError('Erro ao listar.');
        }
    }, [id]);

    useEffect(() => {
        getData();
    }, [getData]);

    function handleOnChange(name, value) {
        data[name] = value;
        setData({ ...data });
    }

    function handleOnChangeAddress(name, value) {
        data.endereco[name] = value;
        setData({ ...data });
    }

    function handleOnChangeBrokers(value) {
        if (data.brokers.filter((obj) => obj === value).length) {
            data.brokers = data.brokers.filter((obj) => obj !== value);
        } else {
            data.brokers.push(value);
        }

        setData({ ...data });
    }

    async function handleUpload(e) {
        if (e.target.files.length) {
            var formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);

            const response = await api.put('profile/upload', formData);

            data.imagem = response.data.link;

            setData({ ...data });
        }
    }

    async function handleOnSave(e) {
        try {
            e.preventDefault();

            data.brokers = ['6046e4bde7c1a6269ca2ba3d'];

            if (id !== 'new') {
                const responsePut = await api.put('accounts/' + id, data);
                if (responsePut.data.error === true)
                    throw responsePut.data.message;
                if (!responsePut.data.params)
                    throw new Error('Todos os dados são obrigatórios');
            }

            if (id === 'new') {
                const responsePost = await api.post('accounts/', data);
                if (responsePost.data.error === true)
                    throw responsePost.data.message;
                if (!responsePost.data.result)
                    throw new Error('Todos os dados são obrigatórios');
            }

            messageStore.addSuccess('Conta atualizada com sucesso.');

            history.goBack();
        } catch (e) {
            messageStore.addError(e);
        }
    }

    return (
        <div id="administracao" className="page">
            <H1Page nome="Gerenciar contas" />

            <div className="row">
                <FormDefault onSubmit={handleOnSave}>
                    <h3 style={{ display: 'none' }}>Brokers SMS</h3>

                    <div className="brokers" style={{ display: 'none' }}>
                        {brokers
                            .filter((obj) => obj.tipo === 'SMS')
                            .map((row, key) => (
                                <div
                                    onClick={() =>
                                        handleOnChangeBrokers(row._id)
                                    }
                                    key={key}
                                    className={
                                        data.brokers.filter(
                                            (obj) => obj === row._id
                                        ).length
                                            ? 'active'
                                            : false
                                    }
                                >
                                    <div>
                                        <input
                                            checked={
                                                data.brokers.filter(
                                                    (obj) => obj === row._id
                                                ).length
                                                    ? true
                                                    : false
                                            }
                                            type="checkbox"
                                        />
                                        <span>
                                            {row.identificacao} -{' '}
                                            <b>{row.tipo}</b>
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {row.isActive ? 'Ativo' : 'Inativo'}
                                        </span>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <h3 style={{ display: 'none' }}>Brokers Whatsapp</h3>

                    <div className="brokers" style={{ display: 'none' }}>
                        {brokers
                            .filter((obj) => obj.tipo === 'Whatsapp')
                            .map((row, key) => (
                                <div
                                    onClick={() =>
                                        handleOnChangeBrokers(row._id)
                                    }
                                    key={key}
                                    className={
                                        data.brokers.filter(
                                            (obj) => obj === row._id
                                        ).length
                                            ? 'active'
                                            : false
                                    }
                                >
                                    <div>
                                        <input
                                            checked={
                                                data.brokers.filter(
                                                    (obj) => obj === row._id
                                                ).length
                                                    ? true
                                                    : false
                                            }
                                            type="checkbox"
                                        />
                                        <span>
                                            {row.identificacao} -{' '}
                                            <b>{row.tipo}</b>
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {row.isActive ? 'Ativo' : 'Inativo'}
                                        </span>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <h3 style={{ display: 'none' }}>Brokers Email</h3>

                    <div className="brokers" style={{ display: 'none' }}>
                        {brokers
                            .filter((obj) => obj.tipo === 'Email')
                            .map((row, key) => (
                                <div
                                    onClick={() =>
                                        handleOnChangeBrokers(row._id)
                                    }
                                    key={key}
                                    className={
                                        data.brokers.filter(
                                            (obj) => obj === row._id
                                        ).length
                                            ? 'active'
                                            : false
                                    }
                                >
                                    <div>
                                        <input
                                            checked={
                                                data.brokers.filter(
                                                    (obj) => obj === row._id
                                                ).length
                                                    ? true
                                                    : false
                                            }
                                            type="checkbox"
                                        />
                                        <span>
                                            {row.identificacao} -{' '}
                                            <b>{row.tipo}</b>
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {row.isActive ? 'Ativo' : 'Inativo'}
                                        </span>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <h3>Imagem whatsapp</h3>

                    <div className="change-photo">
                        <div
                            className="image"
                            style={{
                                backgroundImage: 'url(' + data.imagem + ')',
                            }}
                        ></div>
                        <input
                            id="upload-button"
                            onChange={handleUpload}
                            type="file"
                            name="file"
                        />
                    </div>

                    <h3>Contato</h3>

                    <InputRow>
                        <InputDefault
                            title="Slug"
                            name="slug"
                            value={data.slug}
                            onChange={handleOnChange}
                        />
                        <InputDefault
                            title="Nome"
                            name="nome"
                            value={data.nome}
                            onChange={handleOnChange}
                        />
                        <InputDefault
                            title="E-mail"
                            name="email"
                            value={data.email}
                            onChange={handleOnChange}
                        />
                        <InputDefault
                            title="Telefone"
                            name="telefone"
                            mask={'(99) 99999-9999'}
                            value={data.telefone}
                            onChange={handleOnChange}
                        />
                    </InputRow>

                    <h3>Empresa</h3>

                    <InputRow>
                        <InputDefault
                            title="CNPJ"
                            name="documento"
                            value={data.documento}
                            onChange={handleOnChange}
                            mask={'99.999.999/9999-99'}
                        />
                        <InputDefault
                            title="Razão social"
                            name="razaoSocial"
                            value={data.razaoSocial}
                            onChange={handleOnChange}
                        />
                    </InputRow>

                    <h3>Endereço</h3>

                    <InputRow>
                        <InputDefault
                            title="CEP"
                            name="cep"
                            value={data.endereco.cep}
                            onChange={handleOnChangeAddress}
                        />

                        <InputDefault
                            title="Estado"
                            name="estado"
                            value={data.endereco.estado}
                            onChange={handleOnChangeAddress}
                        />

                        <InputDefault
                            title="Cidade"
                            name="cidade"
                            value={data.endereco.cidade}
                            onChange={handleOnChangeAddress}
                        />
                    </InputRow>

                    <InputRow>
                        <InputDefault
                            title="Endereço"
                            name="logradouro"
                            value={data.endereco.logradouro}
                            onChange={handleOnChangeAddress}
                        />

                        <InputDefault
                            title="Número"
                            name="numero"
                            value={data.endereco.numero}
                            onChange={handleOnChangeAddress}
                        />

                        <InputDefault
                            title="Complemento"
                            name="complemento"
                            value={data.endereco.complemento}
                            onChange={handleOnChangeAddress}
                        />
                    </InputRow>

                    <button type="submit">Salvar</button>
                </FormDefault>
            </div>
        </div>
    );
}
