import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../Api/app';
import H1Page from '../../Components/Layout/H1Page';
import Paginator from '../../Components/Paginator/Paginator';
import messageStore from '../../Store/MessageStore';
//scss
import './administracao.scss';

export default function Brokers(props) {
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);

    const [page, setPage] = useState(1);

    useEffect(() => {
        getData();
    }, [props]);

    async function getData() {
        try {
            const response = await api.get('brokers');

            setData(response.data);
            setLoad(false);
        } catch (e) {
            console.log(e);
            messageStore.addError('Erro ao listar.');
        }
    }

    return (
        <div id="administracao" className="page">
            <H1Page nome="Brokers" />

            <Link to="/adm-brokers/new">
                <button type="button" className="button-zig column primary">
                    <button icon="new" />
                    <span>Novo broker</span>
                </button>
            </Link>

            <table className="table-default">
                <thead>
                    <tr>
                        <th width="10"></th>
                        <th>Status</th>
                        <th>Broker</th>
                        <th>Identificação</th>
                        <th>Contas</th>
                        <th>Tipo</th>
                        <th>Oficial</th>
                    </tr>
                </thead>

                <tbody>
                    {load === false &&
                        data.docs.map((row, key) => (
                            <tr
                                key={key}
                                style={{
                                    backgroundColor: !row.isActive
                                        ? 'rgba(255,0,0,.1)'
                                        : '',
                                }}
                            >
                                <td>
                                    <Link to={'/adm-brokers/' + row._id}>
                                        <button
                                            type="button"
                                            className="button-zig secondary"
                                        >
                                            <span>Editar broker</span>
                                        </button>
                                    </Link>
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {row.isActive ? 'Ativo' : 'Inativo'}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {row.broker}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {row.identificacao}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {row.accounts.map((account, accountKey) => (
                                        <span key={accountKey}>
                                            {account.nome},{' '}
                                        </span>
                                    ))}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {row.tipo}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {row.oficial ? 'Sim' : 'Não'}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <div className="tfoot">
                <Paginator
                    range={10}
                    totalPage={data.pages}
                    setPage={setPage}
                    page={page}
                />
            </div>
        </div>
    );
}
