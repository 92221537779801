import React from 'react';
import './SelectDefault.scss';

export const SelectDefault = ({
    label,
    value = '',
    onChange = (e) => {},
    children,
    ...rest
}) => {
    return (
        <div className="select-default-theme">
            {label && <p className="label">{label}</p>}
            <select {...rest} value={value} onChange={onChange}>
                <option value="">Selecione uma opção</option>
                {children}
            </select>
        </div>
    );
};
