import React, { useState, useEffect, useCallback } from 'react';
import messageStore from '../../Store/MessageStore';
import TableDefault from '../TableDefault/TableDefault';
import ButtonDarkList from '../Darklist/ButtonDarkList';

import './ContactList.scss';
import {
    getContacts,
    removeContact,
    removeContacts,
} from '../../Api/GestaoContatosService';
import { IconEdit, IconRemove } from '../Icon/Icons';

export default function ListContact({
    id,
    columns,
    page,
    search,
    dataInicial,
    dataFinal,
    setTab,
    setPage,
    setContact,
}) {
    const [load, setLoad] = useState(false);
    const [contacts, setContacts] = useState({});
    const [headersTable, setHeadersTable] = useState([]);

    const RenderItemBlockList = ({ item }) => {
        return (
            <ButtonDarkList
                telefone={item['field1']}
                darklist={item.darklist}
                getData={getData}
            />
        );
    };

    useEffect(() => {
        let newHeaders = [
            {
                indice: 'id',
                type: 'checkbox',
                checkboxOptions: ['Remover'],
            },
            {
                indice: 'id',
                type: 'button',
                event: 'edit',
                theme: 'warning',
                children: <IconEdit />,
            },
            {
                indice: 'id',
                type: 'button',
                event: 'remove',
                theme: 'danger',
                children: <IconRemove />,
            },
            {
                label: 'Blocklist',
                type: 'component',
                RenderComponent: RenderItemBlockList,
            },
        ];
        columns.forEach((row, key) => {
            newHeaders.push({
                indice: `field${key + 1}`,
                label: row
                    .replace('celular', 'Celular')
                    .replace('email', 'E-mail')
                    .replace('nome', 'Nome'),
                type: 'string',
            });
        });
        setHeadersTable([...newHeaders]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    const getData = useCallback(async () => {
        setLoad(true);
        const response = await getContacts({
            id,
            page,
            search,
            dataInicial,
            dataFinal,
        });
        setContacts({ ...response.data });
        setLoad(false);
    }, [dataFinal, dataInicial, id, page, search, setLoad]);

    useEffect(() => {
        getData();
    }, [page, getData]);

    async function handleDelContact(id) {
        setLoad(true);
        await removeContact(id);
        getData();
        const docs = contacts.docs.filter((obj) => obj._id !== id);
        contacts.docs = docs;
        setContacts({ ...contacts });
    }

    function handleEdit(contact) {
        setTab('edit');
        setContact(contact);
    }

    async function handleRemoveIds(ids) {
        try {
            setLoad(true);
            await removeContacts(ids);
            getData();
            messageStore.addSuccess(
                'Os contatos selecionados foram removidos.'
            );
        } catch (err) {
            messageStore.addError(
                'Houve um erro ao tentar remover sua seleção de contatos, tente novamente mais tarde.'
            );
        }
    }

    function handleClickButtonTable(id, event) {
        if (event === 'edit') {
            handleEdit(contacts.docs.filter((obj) => obj.id === id)[0]);
        } else if (event === 'remove') {
            messageStore.addConfirm(
                'Deseja realmente remover esse contato?',
                () => handleDelContact(id)
            );
        }
    }

    function handleCLickCheckbox(ids, event) {
        if (event === 'Remover') {
            messageStore.addConfirm(
                'Deseja realmente remover esses contatos?',
                () => handleRemoveIds(ids)
            );
        }
    }

    return (
        <TableDefault
            headers={headersTable}
            data={contacts.docs}
            loading={load}
            page={page}
            onClickButton={handleClickButtonTable}
            onClickCheckbox={handleCLickCheckbox}
            onPaginate={setPage}
            totalPage={contacts.pages}
            total={contacts.total}
            maxHeight={'100%'}
        />
    );
}
