import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import {
    HeadersAnalyticsLandingPageView,
    HeadersAnalyticsLandingPageClick,
} from '../../Api/AnalyticsService';
import { api } from '../../Api/app';
import { getCampanhas } from '../../Api/CampanhasService';
import { ButtonDefault } from '../../Components/Form/ButtonDefault/ButtonDefault';
import { InputDefault } from '../../Components/Form/InputDefault/InputDefault';
import { SelectDefault } from '../../Components/Form/SelectDefault/SelectDefault';
import { IconSearch } from '../../Components/Icon/Icons';
import H1Page from '../../Components/Layout/H1Page';

import TableDefault from '../../Components/TableDefault/TableDefault';

import './Analytics.scss';

export default function RelatoriosTemplate(props) {
    const [campaigns, setCampaigns] = useState([]);

    const [campaignIdView, setCampaignIdView] = useState(
        props.match.params.id ? props.match.params.id : ''
    );
    const [loadingView, setLoadingView] = useState(true);
    const [pageView, setPageView] = useState(1);
    const [dataInicialView, setDataInicialView] = useState('');
    const [dataFinalView, setDataFinalView] = useState('');
    const [dataView, setDataView] = useState({
        docs: [],
    });

    const [campaignIdClick, setCampaignIdClick] = useState(
        props.match.params.id ? props.match.params.id : ''
    );
    const [loadingClick, setLoadingClick] = useState(true);
    const [pageClick, setPageClick] = useState(1);
    const [dataInicialClick, setDataInicialClick] = useState('');
    const [dataFinalClick, setDataFinalClick] = useState('');
    const [dataClick, setDataClick] = useState({
        docs: [],
    });

    const getData = useCallback(
        async (tipoRelatorio, dataInicial, dataFinal, campaignId = '') => {
            const response = await api.get(
                'callback/' +
                    tipoRelatorio +
                    '?campaignId=' +
                    campaignId +
                    '&dataInicial=' +
                    dataInicial +
                    '&dataFinal=' +
                    dataFinal
            );

            return response;
        },
        []
    );

    const getView = useCallback(async () => {
        try {
            setLoadingView(true);
            const response = await getData(
                'views',
                dataInicialView,
                dataFinalView,
                campaignIdView
            );
            setDataView({ ...response.data });
            setLoadingView(false);
        } catch (err) {
            setLoadingView(false);
        }
    }, [dataFinalView, dataInicialView, campaignIdView, getData]);

    const getCLick = useCallback(async () => {
        try {
            setLoadingClick(true);
            const response = await getData(
                'clicks',
                dataInicialClick,
                dataFinalClick,
                campaignIdClick
            );
            setDataClick({ ...response.data });
            setLoadingClick(false);
        } catch (err) {
            setLoadingClick(false);
        }
    }, [dataInicialClick, dataFinalClick, campaignIdClick, getData]);

    const getCampaigns = useCallback(async () => {
        const source = Axios.CancelToken.source();
        const response = await getCampanhas(1, source);
        setCampaigns([...response.data.docs]);
    }, []);

    useEffect(() => {
        getCampaigns();
    }, [getCampaigns]);

    useEffect(() => {
        getCLick();
        getView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="analytics">
            <H1Page nome={'Relatório landing page'} />

            <div className="tables">
                <TableDefault
                    header={
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                getView();
                            }}
                        >
                            <SelectDefault
                                label="Campanha"
                                value={campaignIdView}
                                onChange={(e) =>
                                    setCampaignIdView(e.target.value)
                                }
                            >
                                {campaigns.map((row) => (
                                    <option value={row.id} key={row.id}>
                                        {row.nome}
                                    </option>
                                ))}
                            </SelectDefault>
                            <InputDefault
                                type="date"
                                label="Periodo inicial"
                                value={dataInicialView}
                                onChange={(e) =>
                                    setDataInicialView(e.target.value)
                                }
                            />
                            <InputDefault
                                type="date"
                                label="Periodo final"
                                value={dataFinalView}
                                onChange={(e) =>
                                    setDataFinalView(e.target.value)
                                }
                            />
                            <ButtonDefault
                                style={{
                                    width: '100px',
                                }}
                                theme="primary"
                            >
                                <IconSearch />
                            </ButtonDefault>
                        </form>
                    }
                    label="Aberturas"
                    headers={HeadersAnalyticsLandingPageView}
                    data={dataView.docs}
                    totalPage={dataView.pages}
                    total={dataView.total}
                    loading={loadingView}
                    onPaginate={setPageView}
                    page={pageView}
                />

                <TableDefault
                    header={
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                getCLick();
                            }}
                        >
                            <SelectDefault
                                label="Campanha"
                                value={campaignIdClick}
                                onChange={(e) =>
                                    setCampaignIdClick(e.target.value)
                                }
                            >
                                {campaigns.map((row) => (
                                    <option value={row.id} key={row.id}>
                                        {row.nome}
                                    </option>
                                ))}
                            </SelectDefault>
                            <InputDefault
                                type="date"
                                label="Periodo inicial"
                                value={dataInicialClick}
                                onChange={(e) =>
                                    setDataInicialClick(e.target.value)
                                }
                            />
                            <InputDefault
                                type="date"
                                label="Periodo final"
                                value={dataFinalClick}
                                onChange={(e) =>
                                    setDataFinalClick(e.target.value)
                                }
                            />
                            <ButtonDefault
                                style={{
                                    width: '100px',
                                }}
                                theme="primary"
                            >
                                <IconSearch />
                            </ButtonDefault>
                        </form>
                    }
                    label="Cliques"
                    headers={HeadersAnalyticsLandingPageClick}
                    data={dataClick.docs}
                    totalPage={dataClick.pages}
                    total={dataClick.total}
                    loading={loadingClick}
                    onPaginate={setPageClick}
                    page={pageClick}
                />
            </div>
        </div>
    );
}
