import React, { useCallback, useEffect, useState } from 'react';
import { api } from '../../../Api/app';
import WidgetRender from '../../../Components/TemplateCreation/WidgetRender/WidgetRender';

import '../LandingPage.scss';

export default function VIewLandingPage(props) {
    const [loading, setLoading] = useState(false);

    const [template, setTemplate] = useState([]);

    const id = props.match.params.id;

    const shorturl = props.match.params.shorturl;

    const getData = useCallback(async () => {
        var response = await api.get(
            id ? 'templates/' + id : 'queues/template/' + shorturl
        );

        if (response.data.estrutura !== '[{}]') {
            setTemplate([...JSON.parse(response.data.estrutura)]);
        }
    }, [id, shorturl]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <div id="template-detalhe" className="page">
            <div className="container" id="click-offset">
                {loading === false &&
                    template.map((item, key) => (
                        <WidgetRender
                            updateWidgetColumn={() => {}}
                            id={item.id}
                            item={item.template}
                            indice={key}
                            editId={() => {}}
                            setLoading={setLoading}
                            template={template}
                            editWidget={() => {}}
                            removeWidget={() => {}}
                            key={key}
                            edit={false}
                            shorturl={shorturl}
                        />
                    ))}
            </div>
        </div>
    );
}
