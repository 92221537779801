import React from 'react';
import './CheckboxDefault.scss';

export const CheckboxDefault = ({
    onChange = () => {},
    selected,
    label,
    ...rest
}) => {
    return (
        <div onClick={onChange} className="checkbox-default-theme" {...rest}>
            <div className={`checkbox ${selected ? 'active' : 'normal'}`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        fill="none"
                        stroke="none"
                    />
                    <path
                        fill="currentColor"
                        d="m10 13.6l5.9-5.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-6.6 6.6q-.3.3-.7.3t-.7-.3l-2.6-2.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l1.9 1.9Z"
                    />
                </svg>
            </div>
            {label && <p className="label">{label}</p>}
        </div>
    );
};
