import React, { useState } from 'react';
import { api } from '../../../Api/app';
import messageStore from '../../../Store/MessageStore';
import DragDropFile from '../../Form/DragDropFile/DragDropFile';

import './RCSMessage.scss';

export default function UploadDropRCS({
    textDrop = 'Arraste e solte seu arquivo aqui ou ',
    textButton = 'Escolha o arquivo',
    onChange,
    accept,
    className = 'message-image',
}) {
    const [loading, setLoading] = useState(false);

    const tokenJWT =
        'eyJhbGciOiJIUzI1NiJ9.Z3VpbGhlcm1lQHRyb3BhLmRpZ2l0YWx8fDIwMjMtMDMtMDNUMTc6MTA6MjMuMDY4Wg.tlpJSA4cP_NcsOCdcWRooXBdAQly-BY2mVVzfHBG-zg';
    const urlBucket = 'https://bucket.backendtropa.com.br';
    const bucket = 'octo';

    async function handleUpload(file) {
        try {
            setLoading(true);
            var formData = new FormData();
            formData.append('file', file, file.name);
            let response = await api.post(`${urlBucket}/upload`, formData, {
                headers: {
                    Authorization: tokenJWT,
                    Destination: bucket,
                },
            });
            setLoading(false);
            onChange(response.data.arquivo);
        } catch (e) {
            setLoading(false);
            console.log(e);
            messageStore.addError('Houve um erro ao ler o arquivo.');
        }
    }

    return (
        <div className={className}>
            <DragDropFile
                textDrop={textDrop}
                textButton={textButton}
                accept={accept}
                onChange={handleUpload}
                loading={loading}
            />
        </div>
    );
}
