import { useEffect, useState } from 'react';
import React from 'react';

import './Paginator.scss';
import { ButtonDefault } from '../Form/ButtonDefault/ButtonDefault';
import { IconLoadingPointer } from '../Icon/Icons';

const Paginator = ({
    loading,
    setPage,
    page,
    totalPage = 0,
    total = 0,
    range,
}) => {
    const [data, setData] = useState([]);
    const [totalDocs, setTotalDocs] = useState(0);

    function formatarNumero(numero) {
        const partes = numero.toString().split('.');
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return partes.join(',');
    }

    useEffect(() => {
        setTotalDocs(formatarNumero(total));
    }, [total]);

    useEffect(() => {
        const newData = [];

        for (var i = page - range; i < page + range; i++) {
            try {
                if (i <= 0) throw i;
                if (i > totalPage) throw i;

                newData.push(i);
            } catch (e) {}
        }

        setData([...newData]);
    }, [page, totalPage, range]);

    return (
        <div className="paginator-clean">
            <ul>
                {totalDocs > 0 && (
                    <li>
                        <ButtonDefault
                            theme={'info'}
                            style={{
                                cursor: 'text',
                            }}
                            type="button"
                            onClick={() => page !== 1 && setPage(1)}
                        >
                            {loading ? (
                                <IconLoadingPointer />
                            ) : (
                                `${totalDocs} registros`
                            )}
                        </ButtonDefault>
                    </li>
                )}

                {totalPage > 0 && (
                    <li>
                        <ButtonDefault
                            theme={page === 1 ? 'primary' : ''}
                            type="button"
                            onClick={() => page !== 1 && setPage(1)}
                        >
                            Primeira
                        </ButtonDefault>
                    </li>
                )}
                {data.map((row, key) => (
                    <li key={key}>
                        <ButtonDefault
                            theme={page === row ? 'primary' : ''}
                            type="button"
                            onClick={() => row !== page && setPage(row)}
                        >
                            {row}
                        </ButtonDefault>
                    </li>
                ))}
                {totalPage > 0 && (
                    <li>
                        <ButtonDefault
                            theme={page === totalPage ? 'primary' : ''}
                            type="button"
                            onClick={() =>
                                page !== totalPage && setPage(totalPage)
                            }
                        >
                            Ultíma
                        </ButtonDefault>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Paginator;
