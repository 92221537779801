import React, { useCallback, useEffect, useState } from 'react';
import TableDefault from '../../Components/TableDefault/TableDefault';
import messageStore from '../../Store/MessageStore';
import H1Page from '../../Components/Layout/H1Page';
import { useHistory } from 'react-router-dom';

import './LandingPage.scss';
import {
    getLandingPages,
    HeadersLandignPage,
    newLandingPage,
    removeLandingPage,
} from '../../Api/LandingPagesSerive';
import { ButtonDefault } from '../../Components/Form/ButtonDefault/ButtonDefault';
import PreviewTemplate from '../../Components/TemplateCreation/PreviewTemplate/PreviewTemplate';

export default function LandingPages(props) {
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [previewId, setPreviewId] = useState(false);

    const history = useHistory();

    const getData = useCallback(async () => {
        setLoad(true);
        var response = await getLandingPages(page);
        setData(...[response.data]);
        setLoad(false);
    }, [page]);

    useEffect(() => {
        getData();
    }, [getData]);

    async function handleNew() {
        setLoad(true);
        var response = await newLandingPage();
        if (response.data.error === true) {
            messageStore.addError(response.data.message);
        } else {
            history.push('/landing-pages/studio/' + response.data.result.uid);
        }

        setLoad(false);
    }

    async function handleDelete(id) {
        setLoad(true);
        await removeLandingPage(id);
        getData();
        setLoad(false);
    }

    function handleOnClickButtonTable(id, event) {
        if (event === 'remove') {
            messageStore.addConfirm('Tem certeza que deseja remover?', () =>
                handleDelete(id)
            );
        } else if (event === 'edit') {
            history.push('/landing-pages/studio/' + id);
        } else if (event === 'view') {
            setPreviewId(id);
        }
    }

    return (
        <div id="landing-pages">
            <H1Page nome={'Minhas landing pages'} />

            {previewId && (
                <PreviewTemplate
                    onClose={() => setPreviewId(false)}
                    id={previewId}
                />
            )}
            <TableDefault
                headers={HeadersLandignPage}
                data={data.docs}
                label="Landing pages"
                page={page}
                total={data.total}
                totalPage={data.pages}
                loading={load}
                onPaginate={setPage}
                onClickButton={handleOnClickButtonTable}
                header={
                    <ButtonDefault onClick={handleNew}>
                        Criar nova landing page
                    </ButtonDefault>
                }
            />
        </div>
    );
}
