import React, { useCallback, useEffect, useState } from 'react';
import { baseUrl } from '../../Api/app';
import { getListasContatos } from '../../Api/GestaoContatosService';
import { getLandingPages } from '../../Api/LandingPagesSerive';
import messageStore from '../../Store/MessageStore';
import EditList from '../ContactList/EditList';
import { ButtonDefault } from '../Form/ButtonDefault/ButtonDefault';
import { CheckboxDefault } from '../Form/CheckboxDefault/CheckboxDefault';
import { InputDefault } from '../Form/InputDefault/InputDefault';
import { SelectDefault } from '../Form/SelectDefault/SelectDefault';
import { TextareaEmoji } from '../Form/TextAreaEmoji/TextAreaEmoji';
import { IconClose, IconEdit, IconRemove, IconUsers } from '../Icon/Icons';
import { PhonePreview } from '../PhonePreview/PhonePreview';
import PreviewTemplate from '../TemplateCreation/PreviewTemplate/PreviewTemplate';
import moment from 'moment';
import { LaptopPreview } from '../LaptopPreview/LaptopPreview';
import Axios from 'axios';
import Scrollbars from 'react-custom-scrollbars';
import { InputHours } from '../Form/InputHours/InputsHours';
import {
    maxLenghtMessage,
    maxLenghtMessageTemplate,
} from '../../Pages/NovaCampanha/SMS';
import UploadDropRCS from './rcs/UploadDropRCS';
import Modal from '../Modal/Modal';

const ItemRenderDivForm = ({ type, children, className, ...rest }) => {
    return type === 'form' ? (
        <form {...rest} className={className}>
            {children}
        </form>
    ) : (
        <div className={className}>{children}</div>
    );
};

export const ContainerStep = ({
    children,
    step,
    stepActive,
    image,
    onNext,
    onPrev,
    type = 'form',
    loading = false,
    foot = true,
}) => {
    return (
        <ItemRenderDivForm
            type={type}
            className="form-step"
            onSubmit={(e) => {
                e.preventDefault();
                onNext();
            }}
        >
            <div
                className={`container-step ${
                    stepActive > step ? 'checked' : 'normal'
                } ${stepActive >= step ? 'active' : 'disabled'}`}
            >
                <div className="image-step">
                    <img
                        className="image"
                        src={image}
                        alt="Imagem referente ao passo"
                    />
                </div>
                <div className="number-step">{stepActive <= step && step}</div>
                <div className="content-light">
                    {children}
                    {parseFloat(stepActive) === parseFloat(step) &&
                        foot === true && (
                            <div className="foot-step-light">
                                {step > 1 && loading === false && (
                                    <ButtonDefault
                                        type="button"
                                        onClick={() => onPrev()}
                                    >
                                        Voltar
                                    </ButtonDefault>
                                )}
                                <ButtonDefault
                                    type="button"
                                    theme="primary"
                                    onClick={() =>
                                        !loading ? onNext() : () => {}
                                    }
                                    loading={loading}
                                >
                                    Confirmar
                                </ButtonDefault>
                            </div>
                        )}
                </div>
            </div>
        </ItemRenderDivForm>
    );
};

export const ContainerStepName = ({
    position,
    step,
    handleSubmit,
    handleOnChange,
    DTO,
}) => {
    return (
        <ContainerStep
            onNext={handleSubmit}
            step={position}
            stepActive={step}
            image="/campanha/step-1.png"
        >
            <div className="label-step">
                <p className="title-label">Dê um nome para sua campanha</p>
                <InputDefault
                    autoFocus={step === 1 ? true : false}
                    value={DTO.nome}
                    placeholder="Digite aqui"
                    onChange={(e) => handleOnChange('nome', e.target.value)}
                />
            </div>
        </ContainerStep>
    );
};

export const ContainerStepListContacts = ({
    position,
    step,
    handleSubmit,
    handleOnChange,
    canal,
    DTO,
    onPrev,
    setShortCodes = (shorts = []) => {},
}) => {
    const [loadingLists, setLoadingLists] = useState(true);
    const [lists, setLists] = useState([]);
    const [listsSelected, setListsSelected] = useState([]);

    const [modalNewList, setModalNewList] = useState(false);
    const [modalEditList, setModalEditList] = useState({
        open: false,
    });

    const convertSlugFromAPI = {
        sms: 'SMS',
        rcs: 'RCS',
        email: 'Email',
    };

    const getData = useCallback(async () => {
        try {
            setLoadingLists(true);
            const responseListas = await getListasContatos({
                page: 1,
                limit: 200,
                tipo: convertSlugFromAPI[canal.toLowerCase()],
            });
            setLists([...responseListas.data.docs]);
            setLoadingLists(false);
        } catch (err) {}
    }, [canal, convertSlugFromAPI]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleToggleList(id) {
        if (DTO.contactListId.filter((obj) => obj === id).length > 0) {
            DTO.contactListId = DTO.contactListId.filter((obj) => obj !== id);
        } else {
            DTO.contactListId.push(id);
        }
        const newListsSelecteds = [];
        DTO.contactListId.forEach((row) => {
            newListsSelecteds.push(lists.filter((obj) => obj.id === row)[0]);
        });

        const newShortCades = [];
        newListsSelecteds.forEach((row) => {
            row.colunas.forEach((column) => {
                if (
                    newShortCades.filter((item) => item === column).length === 0
                )
                    newShortCades.push(column);
            });
        });
        setShortCodes(newShortCades);

        handleOnChange('contactListId', DTO.contactListId);
        setListsSelected([...newListsSelecteds]);
    }

    if (position - 1 >= step) return false;

    return (
        <>
            {modalNewList && (
                <EditList
                    nameOnly={true}
                    callbackChangeColumns={(e) => setShortCodes(e)}
                    callbackNewList={() => getData()}
                    onClose={() => setModalNewList(false)}
                    send={convertSlugFromAPI[canal]}
                />
            )}

            {modalEditList.open && (
                <EditList
                    send={canal}
                    onClose={() =>
                        setModalEditList({
                            open: false,
                        })
                    }
                    id={modalEditList.id}
                    callbackChangeColumns={(e) => setShortCodes(e)}
                    callbackChangeName={() => {}}
                />
            )}
            <ContainerStep
                stepActive={step}
                step={position}
                onPrev={onPrev}
                onNext={handleSubmit}
                image="/campanha/step-2.png"
                type="div"
            >
                <div className="label-step">
                    <p className="title-label">
                        Selecione uma ou mais listas de contato
                    </p>

                    <div className="select-options">
                        <SelectDefault
                            autoFocus={step === position ? true : false}
                            loading={loadingLists.toString()}
                            onChange={(e) => handleToggleList(e.target.value)}
                        >
                            {lists.map((row, key) => (
                                <option
                                    key={`option-list-${key}`}
                                    value={row._id}
                                    disabled={
                                        DTO.contactListId.filter(
                                            (obj) => obj === row.id
                                        ).length
                                            ? true
                                            : false
                                    }
                                >
                                    {row.lista}
                                </option>
                            ))}
                        </SelectDefault>
                        <div
                            style={{
                                width: 200,
                            }}
                        >
                            <ButtonDefault
                                type="button"
                                theme="warning"
                                onClick={() => setModalNewList(true)}
                            >
                                Criar nova lista
                            </ButtonDefault>
                        </div>
                    </div>

                    {listsSelected.length > 0 && (
                        <div className="lists-selected">
                            {listsSelected.map((list, listKey) => (
                                <div
                                    className="list-info"
                                    key={`l-i-${listKey}`}
                                >
                                    <div className="icon-list">
                                        <IconUsers />
                                    </div>
                                    <div className="name">{list.lista}</div>
                                    <button
                                        type="button"
                                        className="edit"
                                        onClick={() =>
                                            setModalEditList({
                                                open: true,
                                                id: list.id,
                                            })
                                        }
                                    >
                                        <IconEdit />
                                    </button>
                                    <button
                                        type="button"
                                        className="remove"
                                        onClick={() =>
                                            handleToggleList(list.id)
                                        }
                                    >
                                        <IconRemove />
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </ContainerStep>
        </>
    );
};

export const ContainerStepLandingPage = ({
    position,
    step,
    handleSubmit,
    handleOnChange,
    DTO,
    onPrev,
    sendLP,
    setSendLP,
}) => {
    const [modalPreviewLP, setModalPreviewLP] = useState(null);
    const [loadingLandingPages, setLoadingLandingPages] = useState(true);
    const [landingPages, setLandingPages] = useState([]);

    const getData = useCallback(async () => {
        try {
            setLoadingLandingPages(true);
            const responseLandingPages = await getLandingPages(1, 200);
            setLandingPages([...responseLandingPages.data.docs]);
            setLoadingLandingPages(false);
        } catch (err) {}
    }, []);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleOnChange('templateId', null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendLP]);

    if (position - 1 >= step) return false;

    return (
        <>
            {modalPreviewLP && (
                <PreviewTemplate
                    onClose={() => setModalPreviewLP(false)}
                    id={modalPreviewLP}
                />
            )}

            <ContainerStep
                stepActive={step}
                step={position}
                onPrev={onPrev}
                onNext={handleSubmit}
                image="/campanha/step-3.png"
            >
                <div className="label-step">
                    <p className="title-label">
                        Deseja enviar uma landing page junto ao corpo da
                        mensagem?
                    </p>
                    <div className="label-clean">
                        <CheckboxDefault
                            selected={!sendLP}
                            onChange={() => setSendLP(false)}
                            label="Não quero enviar landing page"
                        />
                        <CheckboxDefault
                            selected={sendLP}
                            onChange={() => setSendLP(true)}
                            label="Quero enviar uma landing page"
                        />
                    </div>
                    {sendLP && (
                        <div className="select-options mt">
                            <SelectDefault
                                loading={loadingLandingPages}
                                value={DTO.templateId}
                                onChange={(e) =>
                                    handleOnChange('templateId', e.target.value)
                                }
                            >
                                {landingPages.map((row, key) => (
                                    <option
                                        key={`option-list-${key}`}
                                        value={row.id}
                                    >
                                        {row.nome}
                                    </option>
                                ))}
                            </SelectDefault>
                            {DTO.templateId && (
                                <div
                                    style={{
                                        width: 200,
                                    }}
                                >
                                    <ButtonDefault
                                        type="button"
                                        onClick={() =>
                                            setModalPreviewLP(
                                                landingPages.filter(
                                                    (obj) =>
                                                        obj.id ===
                                                        DTO.templateId
                                                )[0].uid
                                            )
                                        }
                                        theme="warning"
                                    >
                                        Visualizar
                                    </ButtonDefault>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </ContainerStep>
        </>
    );
};

export const ContainerMessageSMS = ({
    position,
    step,
    canal,
    handleSubmit,
    handleOnChange,
    DTO,
    onPrev,
    shortCodes,
    templateId,
}) => {
    const [message, setMessage] = useState('');

    const handleChangeInput = useCallback(
        (name, value) => {
            handleOnChange(name, value);

            setMessage(value.replace(/\n/g, ''));
        },
        [handleOnChange]
    );

    const maxLength = DTO.templateId
        ? maxLenghtMessageTemplate
        : maxLenghtMessage;

    if (position - 1 >= step) return false;
    return (
        <ContainerStep
            stepActive={step}
            step={position}
            onPrev={onPrev}
            onNext={handleSubmit}
            image="/campanha/step-4.png"
            type="div"
        >
            <div className="label-step">
                <p className="title-label">
                    Personalize a mensagem da campanha
                </p>
                <div className="message-campgain">
                    <div className="message-area sms">
                        <TextareaEmoji
                            value={message}
                            onChange={(e) =>
                                handleChangeInput('mensagem', e.target.value)
                            }
                            head={shortCodes}
                            placeholder={'Digite aqui sua mensagem.'}
                            max={maxLength}
                        />
                    </div>
                    <div className="preview">
                        <PhonePreview
                            style={{
                                maxHeight: '315px',
                            }}
                            title="SMS"
                        >
                            {message && (
                                <div className="messages">
                                    <div className="message">{message}</div>
                                </div>
                            )}
                        </PhonePreview>
                    </div>
                </div>
                <SendPreview
                    templateId={templateId}
                    canal={canal}
                    message={message}
                />
            </div>
        </ContainerStep>
    );
};

export const ContainerMessageRCS = ({
    position,
    step,
    canal,
    handleSubmit,
    handleOnChange,
    DTO,
    onPrev,
    shortCodes,
}) => {
    const [message, setMessage] = useState(DTO?.rcs?.message ?? '');
    const [video, setVideo] = useState('');
    const [image, setImage] = useState('');
    const [card, setCard] = useState({
        title: '',
        description: '',
        fileUrl: '',
        suggestions: [],
    });

    const handleChangeCard = (name, value) => {
        card[name] = value;
        setCard({ ...card });
    };

    const tabs = [
        {
            key: 'text',
            value: 'Texto',
        },
        {
            key: 'image',
            value: 'Imagem',
        },
        {
            key: 'video',
            value: 'Video',
        },
        {
            key: 'rich-card',
            value: 'Card',
        },
    ];
    const [tab, setTab] = useState(DTO?.rcs?.type ?? tabs[0].type);

    const updateRCSData = useCallback(() => {
        let RCSData = {};

        if (tab === 'text') {
            RCSData = {
                message,
            };
        } else if (tab === 'image') {
            RCSData = {
                image,
            };
        } else if (tab === 'video') {
            RCSData = {
                video,
            };
        } else if (tab === 'rich-card') {
            RCSData = card;
        }

        handleOnChange('rcs', {
            type: tab,
            data: RCSData,
        });
    }, [tab, message, image, video, card]);

    useEffect(() => {
        updateRCSData();
    }, [updateRCSData]);

    const [modalNewButton, setModalNewButton] = useState(false);

    const [DTOButton, setDTOButton] = useState({
        type: '',
        title: '',
        value: '',
    });

    const handleOnChangeButton = (name, value) => {
        DTOButton[name] = value;
        setDTOButton({ ...DTOButton });
    };

    const handleSubmitSugestion = () => {
        try {
            if (!DTOButton.type)
                throw new Error('O tipo do botão é obrigatório');
            if (!DTOButton.title)
                throw new Error('O titulo do botão é obrigatório');
            if (!DTOButton.value)
                throw new Error('O destino do botão é obrigatório');
            DTO.rcs.data.suggestions.push(DTOButton);
            handleOnChange('rcs', DTO.rcs);

            setDTOButton({
                title: '',
                type: '',
                value: '',
            });
            setModalNewButton(false);
        } catch (err) {
            messageStore.addError(err.message);
        }
    };

    const handleRemoveButton = (item) => {
        DTO.rcs.data.suggestions = DTO.rcs.data.suggestions.filter(
            (obj) =>
                obj.title !== item.title &&
                obj.value !== item.value &&
                obj.type !== item.type
        );

        handleOnChange('rcs', DTO.rcs);
    };

    if (position - 1 >= step) return false;

    return (
        <>
            {modalNewButton && (
                <Modal
                    title="Adicionar novo botão ao card"
                    onClose={() => setModalNewButton(false)}
                    foot={
                        <>
                            <ButtonDefault
                                onClick={() => setModalNewButton(false)}
                            >
                                Cancelar
                            </ButtonDefault>

                            <ButtonDefault
                                theme="primary"
                                type="button"
                                onClick={() => handleSubmitSugestion()}
                            >
                                Adicionar
                            </ButtonDefault>
                        </>
                    }
                >
                    <SelectDefault
                        value={DTOButton.type}
                        onChange={(e) =>
                            handleOnChangeButton('type', e.target.value)
                        }
                    >
                        <option value={'openUrl'}>Url browser</option>
                        <option value={'call'}>Ligação</option>
                        <option value={'reply'}>Resposta</option>
                    </SelectDefault>
                    <InputDefault
                        label="Titulo do botão"
                        value={DTOButton.title}
                        onChange={(e) =>
                            handleOnChangeButton('title', e.target.value)
                        }
                    />
                    <InputDefault
                        label="Destino"
                        value={DTOButton.value}
                        onChange={(e) =>
                            handleOnChangeButton('value', e.target.value)
                        }
                    />
                </Modal>
            )}
            <ContainerStep
                stepActive={step}
                step={position}
                onPrev={onPrev}
                onNext={handleSubmit}
                image="/campanha/step-4.png"
                type="div"
            >
                <div className="label-step">
                    <p className="title-label">
                        Personalize a mensagem da campanha
                    </p>

                    <div className="message-campgain">
                        <div className="message-area">
                            <div className="tabs-message">
                                {tabs.map((row) => (
                                    <div
                                        className={`tab ${
                                            row.key === tab
                                                ? 'active'
                                                : 'normal'
                                        }`}
                                        onClick={() => setTab(row.key)}
                                    >
                                        <i>
                                            <img src={`/rcs/${row.key}.png`} />
                                        </i>
                                        <span>{row.value}</span>
                                    </div>
                                ))}
                            </div>

                            {tab === 'text' && (
                                <TextareaEmoji
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    head={shortCodes}
                                    placeholder="Digite aqui"
                                />
                            )}

                            {tab === 'image' && (
                                <UploadDropRCS
                                    accept="image/*"
                                    onChange={(value) => setImage(value)}
                                />
                            )}

                            {tab === 'video' && (
                                <UploadDropRCS
                                    accept="video/*"
                                    onChange={(value) => setVideo(value)}
                                />
                            )}
                            {tab === 'rich-card' && (
                                <div className="form-card">
                                    <div className="row">
                                        <InputDefault
                                            label="Titulo*"
                                            value={card.title}
                                            onChange={(e) =>
                                                handleChangeCard(
                                                    'title',
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <InputDefault
                                            label="Descrição"
                                            value={card.description}
                                            onChange={(e) =>
                                                handleChangeCard(
                                                    'description',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <UploadDropRCS
                                        className="image-card"
                                        accept="image/*"
                                        textDrop="Arraste e solte sua imagem aqui ou"
                                        textButton="Escolher imagem"
                                        onChange={(value) =>
                                            handleChangeCard('fileUrl', value)
                                        }
                                    />
                                    <div className="sugestions">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                setModalNewButton(true)
                                            }
                                            className="new"
                                        >
                                            <span>Adicionar botão</span>
                                        </button>
                                        {DTO.rcs.data?.suggestions &&
                                            DTO.rcs.data?.suggestions.map(
                                                (item) => (
                                                    <button
                                                        onClick={() =>
                                                            handleRemoveButton(
                                                                item
                                                            )
                                                        }
                                                        className="item"
                                                    >
                                                        <span>
                                                            {item.title}
                                                        </span>
                                                        <IconClose />
                                                    </button>
                                                )
                                            )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="preview">
                            <PhonePreview
                                style={{
                                    maxHeight: '400px',
                                }}
                                title="RCS"
                            >
                                {DTO.rcs.type && (
                                    <div className="messages">
                                        {DTO.rcs.type === 'text' && message && (
                                            <div className="message">
                                                {message ?? 'teste'}
                                            </div>
                                        )}
                                        {DTO.rcs.type === 'image' && image && (
                                            <div className="message">
                                                <img src={image} />
                                            </div>
                                        )}
                                        {DTO.rcs.type === 'video' && video && (
                                            <div className="message">
                                                <video
                                                    controls
                                                    src={video}
                                                ></video>
                                            </div>
                                        )}

                                        {DTO.rcs.type === 'rich-card' &&
                                            card.title && (
                                                <div className="card-message">
                                                    {card.fileUrl && (
                                                        <img
                                                            src={card.fileUrl}
                                                        />
                                                    )}
                                                    <p>{card.title}</p>
                                                    <span>
                                                        {card.description}
                                                    </span>
                                                    <div className="buttons-sugestions">
                                                        {card.suggestions.map(
                                                            (row, key) => (
                                                                <a key={key}>
                                                                    {row.title}
                                                                </a>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                )}
                            </PhonePreview>
                        </div>
                    </div>
                    <SendPreview
                        rcs={DTO.rcs}
                        canal={canal}
                        message={message}
                    />
                </div>
            </ContainerStep>
        </>
    );
};

export const ContainerMessageEmail = ({
    position,
    step,
    canal,
    handleSubmit,
    handleOnChange,
    DTO,
    onPrev,
    shortCodes,
}) => {
    const [message, setMessage] = useState('');
    const [templates, setTemplates] = useState([]);

    const handleChangeInput = useCallback(
        (name, value) => {
            handleOnChange(name, value);
            setMessage(value);
        },
        [handleOnChange]
    );

    const getTemplates = useCallback(async () => {
        let templatesLista = [
            {
                nome: 'Academia',
                path: 'academia1',
            },
            {
                nome: 'Aniversário de cachorro',
                path: 'aniversario-cachorrinho',
            },
            {
                nome: 'Aniversário',
                path: 'aniversario-fundo-escuro',
            },
            {
                nome: 'Ano novo',
                path: 'ano-novo-2',
            },
            {
                nome: 'Black Friday 1',
                path: 'Blackfriday1',
            },
            {
                nome: 'Black Friday 2',
                path: 'Blackfriday2',
            },
            {
                nome: 'Dia dos pais',
                path: 'dia-pais-produtos',
            },
            {
                nome: 'Dia dos pais 01',
                path: 'dia-pais-coluna',
            },
            {
                nome: 'Dia dos pais',
                path: 'dia-pais-produtos',
            },
            {
                nome: 'Dia das crianças 1',
                path: 'diadascriancas1',
            },
            {
                nome: 'Dia das crianças 2',
                path: 'diasdascriancas2',
            },
            {
                nome: 'Evento',
                path: 'evento-4',
            },
            {
                nome: 'Férias 1',
                path: 'ferias1',
            },
            {
                nome: 'Férias 2',
                path: 'ferias2',
            },
            {
                nome: 'Natal 1',
                path: 'natal1',
            },
            {
                nome: 'Natal 2',
                path: 'natal9',
            },
            {
                nome: 'Notícias 1',
                path: 'noticias-1',
            },
            {
                nome: 'Notícias 2',
                path: 'noticias-2',
            },
            {
                nome: 'Notícias 3',
                path: 'noticias-5',
            },
            {
                nome: 'Produtos 1',
                path: 'produtos-3',
            },
            {
                nome: 'Produtos 2',
                path: 'produtos-4',
            },
        ];

        const loop = async (row) => {
            let response = await getHtmlTemplate(
                baseUrl + 'files/templates-emails/' + row.path
            );
            var data = {
                nome: row.nome,
                html: response,
            };
            return Promise.resolve(data);
        };

        let responses = await Promise.all(
            templatesLista.map((row) => loop(row))
        );
        setTemplates([...responses]);
    }, []);

    async function getHtmlTemplate(url) {
        const HTMLTemplate = await Axios(url);

        let response = HTMLTemplate.data;

        response = JSON.stringify(response);
        response = response.split('img/').join(url + '/img/');
        response = response.split('images/').join(url + '/images/');
        response = response.split('./index_files/').join(url + '/index_files/');
        response = response.split('iagente.com.br').join('seusite.com.br');
        response = response.split('iagente').join('Seu site');
        response = response.split('data-mce-src').join('olddata-mce-src');

        return response;
    }

    useEffect(() => {
        if (parseFloat(position) === parseFloat(step)) getTemplates();
    }, [position, step, getTemplates]);

    if (position - 1 >= step) return false;
    return (
        <ContainerStep
            stepActive={step}
            step={position}
            onPrev={onPrev}
            onNext={handleSubmit}
            image="/campanha/step-4.png"
            type="div"
        >
            <div className="label-step">
                <p className="title-label">
                    Personalize a mensagem da campanha
                </p>
                <div className="message-campgain">
                    <div className="message-area">
                        <div className="templates-preview">
                            <p>
                                Utilize alguns de nossos templates como exemplo.
                            </p>
                            <div className="list-templates">
                                <Scrollbars>
                                    <ul>
                                        {templates.map((row, key) => (
                                            <li
                                                key={`ii-t-${key}`}
                                                onClick={() => {
                                                    setMessage(
                                                        JSON.parse(row.html)
                                                    );
                                                    handleOnChange(
                                                        'mensagem',
                                                        JSON.parse(row.html)
                                                    );
                                                }}
                                            >
                                                {row.nome}
                                            </li>
                                        ))}
                                    </ul>
                                </Scrollbars>
                            </div>
                        </div>
                        <TextareaEmoji
                            value={message}
                            onChange={(e) =>
                                handleChangeInput('mensagem', e.target.value)
                            }
                            head={shortCodes}
                            placeholder="Digite ou cole aqui o template HTML do seu e-mail."
                        />
                    </div>
                    <div className="preview">
                        <LaptopPreview title="E-mail">
                            {message && (
                                <div
                                    className="message"
                                    dangerouslySetInnerHTML={{
                                        __html: message,
                                    }}
                                />
                            )}
                        </LaptopPreview>
                    </div>
                </div>
                <SendPreview canal={canal} message={message} />
            </div>
        </ContainerStep>
    );
};

export const SendPreview = ({ rcs, canal, message, templateId }) => {
    const [loading, setLoading] = useState(false);
    const [destination, setDestination] = useState('');
    const [destinations, setDestinations] = useState([]);

    function validatePhone(phone) {
        phone = phone
            .replace(' ', '')
            .replace(' ', '')
            .replace(' ', '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', '');

        var regex = new RegExp(
            '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'
        );
        return regex.test(phone);
    }

    const addDestination = (destination) => {
        try {
            if (canal === 'sms' && !validatePhone(destination))
                throw new Error('Número inválido');
            if (canal === 'rcs' && !validatePhone(destination))
                throw new Error('Número inválido');
            setDestination('');
            destinations.push(destination);
            setDestinations([...destinations]);
        } catch (err) {
            messageStore.addError(err.message);
        }
    };

    const removeDestination = (destination) => {
        try {
            setDestinations([
                ...destinations.filter((obj) => obj !== destination),
            ]);
        } catch (err) {
            messageStore.addError(err.message);
        }
    };

    async function sendDestinations(destinations, message) {
        const messageFinal = `${message}${
            templateId
                ? ' ' +
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  '/t/' +
                  templateId
                : ''
        }`;

        for (const destination of destinations) {
            let DTOForAPI = {};

            if (rcs.type !== 'rich-card') {
                DTOForAPI = rcs.data;
            } else {
                DTOForAPI.richCard = rcs.data;
            }

            DTOForAPI.to = destination
                .replace(' ', '')
                .replace('(', '')
                .replace(')', '');

            if (rcs.data.type === 'text') DTOForAPI.message = messageFinal;

            await Axios.post(
                `https://octo${canal}.backendtropa.com.br/pontal/${canal}/single/send/${rcs.type}`,
                DTOForAPI,
                {
                    headers: {
                        Authorization:
                            'Bearer ' + window.localStorage.getItem('token'),
                    },
                }
            );
        }
    }

    const sendAvulsos = useCallback(
        async (e) => {
            try {
                e.preventDefault();

                if (destinations.length === 0)
                    throw new Error('Nenhum destinatário encontrado.');

                if (rcs.type === 'text' && !message)
                    throw new Error(
                        'Preencha a mensagem antes de enviar os testes.'
                    );

                if (rcs.type === 'image' && !rcs.data.image)
                    throw new Error(
                        'Escolha a imagem antes de enviar os testes.'
                    );

                if (rcs.type === 'video' && !rcs.data.video)
                    throw new Error(
                        'Escolha o video antes de enviar os testes.'
                    );

                if (rcs.type === 'rich-card' && !rcs.data.title)
                    throw new Error('O titulo do card é obrigatório.');

                if (rcs.type === 'rich-card' && !rcs.data.title)
                    throw new Error('O titulo do card é obrigatório.');

                if (rcs.type === 'rich-card' && !rcs.data.fileUrl)
                    throw new Error('A imagem do card é obrigatório.');
                setLoading(true);

                sendDestinations(destinations, message)
                    .then(() => {
                        messageStore.addSuccess(
                            'Todos os envios foram realizados com sucesso. No entanto, é importante notar que pode levar alguns minutos até que você receba as mensagens em seu celular.'
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                        messageStore.addError(
                            'Ocorreu um erro durante as chamadas API:',
                            error
                        );
                    });

                messageStore.addSuccess('Enviado com sucesso.');
                setLoading(false);
            } catch (e) {
                setLoading(false);
                messageStore.addError(e.message);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [canal, destinations, message, rcs]
    );

    return (
        <div className="send-preview">
            <p>Envie a mensagem no seu celular ou de sua equipe.</p>
            <form
                className="form-destination"
                onSubmit={(e) => {
                    e.preventDefault();
                    addDestination(destination);
                }}
            >
                <InputDefault
                    value={destination}
                    maskChar=""
                    onChange={(e) => setDestination(e.target.value)}
                    placeholder={
                        canal === 'email'
                            ? 'nome@exemplo.com'
                            : '(99) 999999999'
                    }
                    mask={canal !== 'email' && '(99) 999999999'}
                />
                <ButtonDefault theme="warning">Adicionar</ButtonDefault>
            </form>

            {destinations.length > 0 && (
                <form onSubmit={sendAvulsos} className="list-destinations">
                    <ul>
                        {destinations.map((row, key) => (
                            <li key={`d-${key}`}>
                                <span>{row}</span>
                                <button
                                    type="button"
                                    onClick={() => removeDestination(row)}
                                    theme="danger"
                                >
                                    <IconRemove />
                                </button>
                            </li>
                        ))}
                    </ul>
                    <ButtonDefault loading={loading} theme="success">
                        Enviar testes
                    </ButtonDefault>
                </form>
            )}
        </div>
    );
};

export const ContainerStepSend = ({
    position,
    step,
    handleSubmit,
    handleOnChange,
    loading,
    onPrev,
}) => {
    const [dateValue, setDateValue] = useState('');
    const [hoursValue, setHoursValue] = useState('');

    useEffect(() => {
        setDateValue(moment().format('YYYY-MM-DD'));
        setHoursValue(moment().add('minutes', 5).format('hh:mm'));
    }, []);

    const changeValues = useCallback(() => {
        handleOnChange('dataEnvio', `${moment(`${dateValue} ${hoursValue}`)}`);
    }, [handleOnChange, dateValue, hoursValue]);

    useEffect(() => {
        changeValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateValue, hoursValue]);

    if (position - 1 >= step) return false;

    return (
        <ContainerStep
            stepActive={step}
            step={position}
            onPrev={onPrev}
            onNext={handleSubmit}
            image="/campanha/step-5.png"
            loading={loading}
        >
            <div className="label-step">
                <p className="title-label">
                    Selecione uma data e um horario para que os envios sejam
                    realizados.
                </p>
                <div className="calendar-campgain">
                    {loading}
                    <div className="inputs">
                        <div
                            style={{
                                width: 140,
                            }}
                        >
                            <InputDefault
                                label="Data"
                                value={dateValue}
                                onChange={(e) => setDateValue(e.target.value)}
                                type="date"
                                min={new Date().toISOString().split('T')[0]}
                            />
                        </div>
                        <div
                            style={{
                                width: 95,
                            }}
                        >
                            <InputHours
                                onChange={(value) => setHoursValue(value)}
                                dateSelected={dateValue}
                                hourDefault={moment().format('HH')}
                                minuteDefault={moment().format('mm')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ContainerStep>
    );
};

export const ContainerStepProgress = ({ position, step, progress }) => {
    if (position - 1 >= step) return false;

    return (
        <ContainerStep
            type="div"
            stepActive={step}
            step={position}
            image="/campanha/step-6.png"
            foot={false}
        >
            <div className="label-step">
                <p className="title-label">Processando campanha</p>

                <div className="progressbar">
                    {progress > 0 && (
                        <div
                            style={{
                                width: `${progress}%`,
                            }}
                        >
                            <span>{progress}%</span>
                        </div>
                    )}
                    {progress === 0 && <span className="loading"></span>}
                </div>
            </div>
        </ContainerStep>
    );
};
