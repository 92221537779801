import React, { useCallback, useEffect, useState } from 'react';

//scss
import './Campanhas.scss';
import H1Page from '../../Components/Layout/H1Page';
import { api } from '../../Api/app';

import messageStore from '../../Store/MessageStore';
import TableDefault from '../../Components/TableDefault/TableDefault';
import { getCampanhas, HeadersCampanha } from '../../Api/CampanhasService';
import Axios from 'axios';

export default function Campanhas(props) {
    const [load, setLoad] = useState(true);
    const [page, setPage] = useState(1);
    const [data, setData] = useState({
        docs: [],
    });

    const getData = useCallback(
        async (source) => {
            setLoad(true);
            const response = await getCampanhas(page, {
                source,
            });
            setData({ ...response.data });
            setLoad(false);
        },
        [page]
    );

    useEffect(() => {
        const source = Axios.CancelToken.source();
        getData(source);
        return () => {
            source.cancel('Cancelado pelo usuário');
        };
    }, [page, getData]);

    const handleCancel = useCallback(
        async (id) => {
            setLoad(true);
            const response = await api.get(
                'cron/cancelar-campanha?campaignId=' + id
            );
            messageStore.addSuccess(response.data.message);
            getData();
        },
        [getData]
    );

    useEffect(() => {
        if (1 === 2) handleCancel(1);
    }, [handleCancel]);

    return (
        <div id="campanhas" className="page">
            <H1Page nome="Minhas campanhas" />
            <TableDefault
                label="Todas campanhas"
                headers={HeadersCampanha}
                data={data.docs}
                totalPage={data.pages}
                page={page}
                loading={load}
                onPaginate={setPage}
            />
        </div>
    );
}
