import React, { useCallback, useEffect, useState } from 'react';
import { api } from '../../Api/app';
import messageStore from '../../Store/MessageStore';
import H1Page from '../../Components/Layout/H1Page';

import TableDefault from '../../Components/TableDefault/TableDefault';
import './Analytics.scss';
import {
    getEnvios,
    getEnviosDownload,
    HeadersEnvios,
} from '../../Api/AnalyticsService';
import { ButtonDefault } from '../../Components/Form/ButtonDefault/ButtonDefault';
import { InputDefault } from '../../Components/Form/InputDefault/InputDefault';
import { SelectDefault } from '../../Components/Form/SelectDefault/SelectDefault';
import { IconDownload, IconFilters } from '../../Components/Icon/Icons';
import Modal from '../../Components/Modal/Modal';
import {
    useHistory,
    useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import Axios from 'axios';

export default function AnalyticsEnvios(props) {
    const history = useHistory();
    const location = useLocation();
    var search = location.search.substring(1);
    const paramsURL = search
        ? JSON.parse(
              '{"' +
                  decodeURI(search)
                      .replace(/"/g, '\\"')
                      .replace(/&/g, '","')
                      .replace(/=/g, '":"') +
                  '"}'
          )
        : {};

    const [load, setLoad] = useState(false);
    const [loadDownload, setLoadDownload] = useState(false);
    const [campaigns, setCampaigns] = useState([]);
    const [data, setData] = useState({
        docs: [],
    });
    const [page, setPage] = useState(
        paramsURL.page ? parseFloat(paramsURL.page) : 1
    );
    const [id, setId] = useState(paramsURL.id ?? '');
    const [status, setStatus] = useState(paramsURL.status ?? '');
    const [dataInicial, setDataInicial] = useState(paramsURL.dataInicial ?? '');
    const [dataFinal, setDataFinal] = useState(paramsURL.dataFinal ?? '');
    const [tipoDeEnvio, setTipoDeEnvio] = useState(paramsURL.tipoDeEnvio ?? '');
    const [modalFilters, setModalFilters] = useState(false);

    useEffect(() => {
        api.get('campaigns').then((response) => {
            setCampaigns(response.data.docs);
        });
    }, []);

    const source = Axios.CancelToken.source();

    function newFile(blob, name = 'file.txt') {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );
        document.body.removeChild(link);
    }

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    async function handleDownload() {
        try {
            setLoadDownload(true);
            const response = await getEnviosDownload({
                dataFinal,
                dataInicial,
                id,
                page,
                status,
                tipoDeEnvio,
            });
            newFile(
                new Blob([s2ab(response.data)], { type: 'text/csv' }),
                'Relatório analitico.csv'
            );
            setLoadDownload(false);
        } catch (err) {
            setLoadDownload(false);
            console.log(err);
            messageStore.addError(err.toString());
        }
    }

    const getData = useCallback(
        async (urlParams = false) => {
            try {
                setLoad(true);
                const params = {
                    id,
                    tipoDeEnvio,
                    status,
                    dataInicial,
                    dataFinal,
                    page,
                };
                if (urlParams) {
                    const objString =
                        '?' + new URLSearchParams(params).toString();
                    history.push(objString);
                }

                const response = await getEnvios(params, {
                    source,
                });
                setData({ ...response.data });
                setLoad(false);
            } catch (e) {
                console.log(e);
                setLoad(false);
            }
        },
        [dataFinal, dataInicial, source, id, page, status, tipoDeEnvio, history]
    );

    function handleFilter(e) {
        e.preventDefault();
        getData(true);
        setModalFilters(false);
    }

    useEffect(() => {
        getData(false);
        return () => {
            source.cancel('Cancelado pelo usuário');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data.docs.length > 0) getData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <div id="analytics" className="page">
            <H1Page nome={'Relatório envios'} />

            {modalFilters && (
                <Modal
                    title="Filtrar registros"
                    onClose={() => setModalFilters(false)}
                >
                    <form onSubmit={handleFilter} className="filter">
                        <SelectDefault
                            label="Canal"
                            value={tipoDeEnvio}
                            onChange={(e) => setTipoDeEnvio(e.target.value)}
                        >
                            <option>SMS</option>
                            <option>RCS</option>
                            <option>Email</option>
                        </SelectDefault>

                        <div className="row">
                            <SelectDefault
                                label="Campanha"
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                            >
                                {campaigns.map((row, key) => (
                                    <option value={row._id} key={key}>
                                        {row.nome}
                                    </option>
                                ))}
                            </SelectDefault>

                            <SelectDefault
                                label="Status"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <option>Em Fila</option>
                                <option>Agendado</option>
                                <option>Enviado</option>
                                <option>Recebido</option>
                                <option>Erro</option>
                                <option>Cancelado</option>
                            </SelectDefault>
                        </div>

                        <div className="row">
                            <InputDefault
                                label="Periodo inicial"
                                value={dataInicial}
                                onChange={(e) => setDataInicial(e.target.value)}
                                type="date"
                                name="dataInicial"
                            />

                            <InputDefault
                                label="Periodo final"
                                value={dataFinal}
                                onChange={(e) => setDataFinal(e.target.value)}
                                type="date"
                                name="dataFinal"
                            />
                        </div>
                        <ButtonDefault theme="primary">Confirmar</ButtonDefault>
                    </form>
                </Modal>
            )}

            <TableDefault
                filter={
                    <ButtonDefault
                        theme="warning"
                        onClick={() => setModalFilters(true)}
                    >
                        <IconFilters /> Filtrar
                    </ButtonDefault>
                }
                download={
                    <ButtonDefault
                        loading={loadDownload}
                        type="button"
                        onClick={handleDownload}
                    >
                        <IconDownload /> Download
                    </ButtonDefault>
                }
                headers={HeadersEnvios}
                data={data.docs}
                page={page}
                onPaginate={setPage}
                totalPage={data.pages}
                total={data.total}
                label={'Envios'}
                loading={load}
            />
        </div>
    );
}
