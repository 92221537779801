import React, { useCallback, useEffect, useState } from 'react';
import { api } from '../../Api/app';
import H1Page from '../../Components/Layout/H1Page';
import TableDefault from '../../Components/TableDefault/TableDefault';
import EditList from '../../Components/ContactList/EditList';
import {
    getListasContatos,
    HeadersGestaoContatos,
} from '../../Api/GestaoContatosService';
import messageStore from '../../Store/MessageStore';
import { ButtonDefault } from '../../Components/Form/ButtonDefault/ButtonDefault';

export default function GestaoContatos(props) {
    const [load, setLoad] = useState(true);
    const [data, setData] = useState({
        docs: [],
    });
    const [page, setPage] = useState(1);

    const getData = useCallback(async () => {
        setLoad(true);
        const response = await getListasContatos({ page, limit: 10 });
        setData({ ...response.data });
        setLoad(false);
    }, [page]);

    useEffect(() => {
        getData();
    }, [page, getData]);

    async function handleDelete(id) {
        await api.delete('contactLists/' + id);
        getData();
    }

    function handleOnClickButton(id, event) {
        if (event === 'remove') {
            messageStore.addConfirm('Tem certeza que deseja remover?', () =>
                handleDelete(id)
            );
        } else if (event === 'edit') {
            setEditList({
                nameOnly: true,
                open: true,
                id: id,
                send: data.docs.filter((obj) => obj._id === id)[0].tipo,
            });
        } else if (event === 'contacts') {
            setEditList({
                nameOnly: false,
                open: true,
                id: id,
                send: data.docs.filter((obj) => obj._id === id)[0].tipo,
            });
        }
    }

    const [newList, setNewList] = useState(false);
    const [editList, setEditList] = useState({
        opened: false,
    });

    return (
        <div id="gestao-contatos">
            <H1Page nome="Gestão de contatos" />
            <TableDefault
                headers={HeadersGestaoContatos}
                data={data.docs}
                label="Listas de contato"
                loading={load}
                onPaginate={setPage}
                page={page}
                totalPage={data.pages}
                total={data.total}
                onClickButton={handleOnClickButton}
                header={
                    <ButtonDefault
                        onClick={() => setNewList(true)}
                        type="button"
                    >
                        Criar nova lista
                    </ButtonDefault>
                }
            />

            {newList && (
                <EditList
                    nameOnly={true}
                    callbackChangeColumns={() => {}}
                    callbackNewList={() => getData()}
                    onClose={() => setNewList(false)}
                />
            )}

            {editList.open && (
                <EditList
                    nameOnly={editList.nameOnly}
                    send={editList.send}
                    onClose={() =>
                        setEditList({
                            open: false,
                        })
                    }
                    id={editList.id}
                    callbackChangeColumns={() => {}}
                    callbackChangeName={() => {}}
                />
            )}
        </div>
    );
}
