import React from 'react';
import { IconEdit, IconEye, IconRemove } from '../Components/Icon/Icons';
import { api } from './app';

export const HeadersLandignPage = [
    {
        indice: 'nome',
        label: 'Nome',
        type: 'string',
    },
    {
        indice: 'user.nome',
        label: 'Proprietario',
        type: 'stringObject',
    },
    {
        indice: 'createdAt',
        label: 'Data de criação',
        type: 'date',
    },
    {
        indice: 'uid',
        type: 'button',
        event: 'view',
        theme: 'primary',
        children: <IconEye />,
    },
    {
        indice: 'uid',
        type: 'button',
        event: 'edit',
        theme: 'warning',
        children: <IconEdit />,
    },
    {
        indice: 'uid',
        type: 'button',
        event: 'remove',
        theme: 'danger',
        children: <IconRemove />,
    },
];

export const getLandingPages = async (page, limit = 10) => {
    const response = await api.get(`/templates?page=${page}`);
    return response;
};

export const getLandingPage = async (id) => {
    const response = await api.get('/templates/' + id);
    return response;
};

export const newLandingPage = async () => {
    const response = await api.post('/templates', {
        nome: 'Nova Landing Page',
        estrutura: '[{}]',
    });
    return response;
};

export const removeLandingPage = async (id) => {
    const response = await api.delete('templates/' + id);
    return response;
};
