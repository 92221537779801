import React, { useState, useEffect, useCallback } from 'react';
import FieldsetWidget from '../../Edition/FieldsetWidget';
import FormEditWidget from '../../Edition/FormEditWidget';

import LabelFontFamily from '../../Edition/LabelFontFamily';
import LabelTextAlign from '../../Edition/LabelTextAlign';
import LabelFontSize from '../../Edition/LabelFontSize';
import LabelLineHeight from '../../Edition/LabelLineHeight';
import LabelPadding from '../../Edition/LabelPadding';
import LabelColor from '../../Edition/LabelColor';

import './Button.scss';
import messageStore from '../../../../Store/MessageStore';
import { api } from '../../../../Api/app';

const Button = (props) => {
    const options = props.item.options;

    const styleOptions = {
        fontFamily: options.fontFamily,
        textAlign: options.textAlign,
        fontSize: options.fontSize,
        lineHeight: options.lineHeight,

        backgroundColor: options.backgroundColor,
        color: options.color,

        paddingTop: options.paddingTop,
        paddingBottom: options.paddingBottom,
        paddingLeft: options.paddingLeft,
        paddingRight: options.paddingRight,
    };

    async function handleRedirect() {
        try {
            await api.post('callback/template/click', {
                shorturl: props.shorturl,
                link: options.link,
                text: options.text,
                type: 'Botão',
            });
            window.open(options.link, '_blank');
        } catch (err) {
            messageStore.addError('Não foi possivel acessar o link.');
        }
    }

    return (
        <div onClick={handleRedirect} className="button-widget">
            <button style={styleOptions}>{options.text}</button>
        </div>
    );
};

const ButtonEdit = ({ item, onSave }) => {
    const [load, setLoad] = useState(false);
    const [options, setOptions] = useState({});

    const reloadOptions = useCallback(() => {
        setOptions(item.options);
        setLoad(false);
        onSave(options);
    }, [item.options, onSave, options]);

    useEffect(() => {
        reloadOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    function handleOnChange(e) {
        options[e.target.name] = e.target.value;
        setOptions(options);
        onSave(options);
    }

    return (
        <>
            {load === false && (
                <FormEditWidget onSave={onSave} item={item}>
                    <div className="group">
                        <FieldsetWidget legend="Texto do botão">
                            <input
                                type="text"
                                onChange={(e) => handleOnChange(e)}
                                name="text"
                                defaultValue={options.text}
                                style={{ fontFamily: options.fontFamily }}
                            />
                        </FieldsetWidget>
                    </div>

                    <div className="group">
                        <FieldsetWidget legend="Link">
                            <input
                                type="text"
                                onChange={(e) => handleOnChange(e)}
                                name="link"
                                defaultValue={options.link}
                            />
                        </FieldsetWidget>
                    </div>

                    <div className="group">
                        <LabelFontFamily
                            full={true}
                            value={options.fontFamily}
                            onChange={handleOnChange}
                        />
                        <LabelTextAlign
                            full={true}
                            value={options.textAlign}
                            onChange={handleOnChange}
                        />
                    </div>

                    <div className="group">
                        <LabelFontSize
                            full={true}
                            value={options.fontSize}
                            onChange={handleOnChange}
                        />
                        <LabelLineHeight
                            full={true}
                            value={options.lineHeight}
                            onChange={handleOnChange}
                        />
                    </div>

                    <div className="group">
                        <FieldsetWidget>
                            <LabelColor
                                name="color"
                                color={options.color}
                                value="Cor do Texto"
                                onChange={handleOnChange}
                            />
                            <LabelColor
                                name="backgroundColor"
                                color={options.backgroundColor}
                                value="Cor do Fundo"
                                onChange={handleOnChange}
                            />
                        </FieldsetWidget>
                    </div>

                    <div className="group">
                        <LabelPadding
                            full={true}
                            value={options}
                            onChange={handleOnChange}
                        />
                    </div>
                </FormEditWidget>
            )}
        </>
    );
};

export { Button, ButtonEdit };
