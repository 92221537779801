import React, { useEffect, useState } from 'react';
import {
    useHistory,
    useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { IconArrow } from '../Icon/Icons';
import ListPages from './pages.json';

import { RenderIcon } from './UtilsLayoutPrivate';

export default function H1Page(props) {
    const location = useLocation();
    const history = useHistory();
    const [menu, setMenu] = useState({});

    useEffect(() => {
        ListPages.pages.forEach((menu) => {
            let menuActive = false;
            if (menu.link === location.pathname) {
                menuActive = true;
            }

            if (
                menu.link.length > 1 &&
                location.pathname.split(menu.link).length > 1
            ) {
                menuActive = true;
            }

            if (
                menu.children.filter((obj) => obj.link === location.pathname)
                    .length > 0
            ) {
                menuActive = true;
            }

            if (menuActive) setMenu(menu);
        });
    }, [location]);

    return (
        <div className="header-page">
            {props.goback && (
                <button onClick={() => history.goBack()}>
                    <IconArrow />
                </button>
            )}
            {menu.name && <RenderIcon page={menu.name} />}
            <h1>{props.nome}</h1>
        </div>
    );
}
