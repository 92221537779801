import React from 'react';
import { IconEdit, IconRemove } from '../Components/Icon/Icons';
import { api } from './app';

export const HeadersGestaoContatos = [
    {
        indice: 'lista',
        label: 'Lista',
        type: 'string',
    },
    {
        indice: 'tipo',
        label: 'Canal',
        type: 'string',
    },
    {
        indice: 'user.nome',
        label: 'Proprietario',
        type: 'stringObject',
    },
    {
        indice: 'createdAt',
        label: 'Data de criação',
        type: 'date',
    },
    /** 
     {
         indice: '_id',
         type: 'link',
         pathname: 'gestao-contatos',
         theme: 'primary',
         children: 'Contatos',
     },
     */
    {
        indice: 'id',
        type: 'button',
        event: 'contacts',
        theme: 'primary',
        children: 'Contatos',
    },
    {
        indice: 'id',
        type: 'button',
        event: 'edit',
        theme: 'warning',
        children: <IconEdit />,
    },
    {
        indice: 'id',
        type: 'button',
        event: 'remove',
        theme: 'danger',
        children: <IconRemove />,
    },
];

export const columnsDefault = [
    {
        label: 'Primeiro nome',
        value: 'primeiro nome',
    },
    {
        label: 'Sobrenome',
        value: 'sobrenome',
    },
    {
        label: 'Nome completo',
        value: 'Nome completo',
    },
    {
        label: 'CPF',
        value: 'CPF',
    },
    {
        label: 'CNPJ',
        value: 'CNPJ',
    },
    {
        label: 'Telefone residencial',
        value: 'Telefone residencial',
    },
    {
        label: 'Telefone comercial',
        value: 'Telefone comercial',
    },
    {
        label: 'Sexo',
        value: 'Sexo',
    },
    {
        label: 'CEP residencial',
        value: 'CEP residencial',
    },
    {
        label: 'Endereço residencial',
        value: 'Endereço residencial',
    },
    {
        label: 'Bairro residencial',
        value: 'Bairro residencial',
    },
    {
        label: 'Complemento residencial',
        value: 'Complemento residencial',
    },
    {
        label: 'UF residencial',
        value: 'UF residencial',
    },
    {
        label: 'Cidade residencial',
        value: 'Cidade residencial',
    },
    {
        label: 'CEP comercial',
        value: 'CEP comercial',
    },
    {
        label: 'Endereço comercial',
        value: 'Endereço comercial',
    },
    {
        label: 'Bairro comercial',
        value: 'Bairro comercial',
    },
    {
        label: 'Complemento comercial',
        value: 'Complemento comercial',
    },
    {
        label: 'UF comercial',
        value: 'UF comercial',
    },
    {
        label: 'Cidade comercial',
        value: 'Cidade comercial',
    },
];

export const getListasContatos = async ({
    page = 1,
    limit = 1000,
    tipo = '',
}) => {
    const response = await api.get(
        'contactLists?page=' + page + '&limit=' + limit + '&tipo=' + tipo
    );
    return response;
};

export const getListaContato = async (id) => {
    const response = await api.get('contactLists/' + id);
    return response;
};

export const setListaContato = async ({ lista, tipo, colunas }) => {
    const response = await api.post('contactLists', {
        lista,
        tipo,
        colunas,
    });
    return response;
};

export const setNameListaContato = async (id, lista, colunas) => {
    const response = await api.put('contactLists/' + id, {
        lista,
        colunas,
    });
    return response;
};

export const getContacts = async ({
    id,
    page,
    search,
    dataInicial,
    dataFinal,
}) => {
    const response = await api.get(
        `contacts?contactListId=${id}&page=${page}&search=${search}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&limit=10`
    );
    return response;
};

export const removeContact = async (id) => {
    const response = await api.delete('contacts/' + id);
    return response;
};

export const removeContacts = async (ids) => {
    const response = await api.post('contacts-delete', {
        ids,
    });
    return response;
};
