import React, { useEffect, useState } from 'react';
import { addBlockList } from '../../Api/BlockListService';
import { ButtonDefault } from '../../Components/Form/ButtonDefault/ButtonDefault';
import { InputDefault } from '../../Components/Form/InputDefault/InputDefault';
import Modal from '../../Components/Modal/Modal';
import messageStore from '../../Store/MessageStore';

export default function ModalBlackList({
    open,
    getData,
    onClose,
    telefone = '',
}) {
    const [loading, setLoading] = useState(false);

    async function handleAddItem(e) {
        try {
            e.preventDefault();
            if (!motivo) throw new Error('O motivo é obrigatório');
            setLoading(true);
            const DTO = {
                blacklist: phone,
                motivo: motivo,
            };
            const response = await addBlockList(DTO);

            if (response.data.error === true)
                throw new Error(response.data.message);

            DTO._id = response.data.result._id;
            setPhone('');
            setMotivo('');
            messageStore.addSuccess('Número adicionado na blacklist.');
            getData(DTO);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err, err.message);
            messageStore.addError(err.message);
        }
    }

    const [phone, setPhone] = useState(telefone);
    const [motivo, setMotivo] = useState('');

    useEffect(() => {
        setPhone(telefone);
    }, [telefone]);
    return (
        <Modal
            id="darklist-modal"
            onClose={() => onClose()}
            title="Adicionar blocklist"
            open={open}
        >
            <form className="blacklist-form" onSubmit={handleAddItem}>
                <InputDefault
                    label="Número"
                    placeholder="Digite aqui"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    disabled={telefone ? true : false}
                />
                <InputDefault
                    label="Motivo"
                    placeholder="Digite aqui"
                    value={motivo}
                    onChange={(e) => setMotivo(e.target.value)}
                />
                <div className="foot-dk">
                    <ButtonDefault loading={loading} theme="primary">
                        Confirmar
                    </ButtonDefault>
                </div>
            </form>
        </Modal>
    );
}
