import React from 'react';
import { IconCloseLine } from '../../Icon/Icons';

const EditionWidget = (props) => {
    const linkPlatform =
        window.location.protocol +
        '//' +
        (window.location.host === 'localhost:3000'
            ? 'localhost:3000'
            : window.location.host) +
        '/t/' +
        props.id;
    const qrCodeImg =
        'https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=' +
        linkPlatform;

    return (
        <div className="edition-widget">
            {props.editionWidgetEdit.component ? (
                <>
                    <div className="head">
                        <button
                            onClick={() =>
                                props.setEditionWidgetEdit({ id: null })
                            }
                        >
                            <IconCloseLine />
                        </button>
                        <span>Editar {props.editionWidgetEdit.item.name}</span>
                    </div>

                    <div className="content">
                        <props.editionWidgetEdit.component
                            onSave={props.onSave}
                            item={props.editionWidgetEdit.item}
                            id={props.editionWidgetEdit.id}
                        />
                    </div>
                </>
            ) : (
                <div className="no-edition">
                    <p>
                        Para pré-visualizar a Landing page no seu celular,{' '}
                        <span>salve</span> e aponte a camera para o QRCode
                        abaixo.
                    </p>

                    <img alt="QRCODE" src={qrCodeImg} />

                    <p>Caso prefira, digite no seu navegador o endereço: </p>

                    <a
                        href={linkPlatform}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {linkPlatform}
                    </a>
                </div>
            )}
        </div>
    );
};

export default EditionWidget;
