import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import Dashboard from './Pages/Dashboard/Dashboard';
import Login from './Pages/Login/Login';
import Campanhas from './Pages/Campanhas/Campanhas';

import LayoutPrivate from './Components/Layout/LayoutPrivate';
import Configuracoes from './Pages/Usuario/Configuracoes';
import Notificacoes from './Pages/Notificacoes/Notificaoes';
import Contas from './Pages/Administracao/Contas';
import ContasForm from './Pages/Administracao/ContasForm';
import UsuariosForm from './Pages/Administracao/UsuariosForm';
import Brokers from './Pages/Administracao/Brokers';
import BrokersForm from './Pages/Administracao/BrokersForm';
import CronManual from './Pages/Administracao/CronManual';
import GetFila from './Pages/Administracao/GetFila';
import RelatoriosTemplate from './Pages/Analytics/RelatoriosTemplate';
import RelatorioEmail from './Pages/Analytics/RelatorioEmail';
import GestaoContatos from './Pages/GestaoContatos/GestaoContatos';
import Contatos from './Pages/GestaoContatos/Contatos';

import Blacklist from './Pages/Blacklist/Blacklist';
import BlacklistPublic from './Pages/Blacklist/BlacklistPublic';
import LandingPages from './Pages/LandingPages/LandingPages';
import VIewLandingPage from './Pages/LandingPages/View/VIewLandingPage';
import StudioLandingPage from './Pages/LandingPages/Studio/StudioLandingPage';
import AnalyticsEnvios from './Pages/Analytics/Envios';
import DetalheCampanha from './Pages/Campanhas/Detalhe';
import NovaCampanhaSMS from './Pages/NovaCampanha/SMS';
import NovaCampanhaEmail from './Pages/NovaCampanha/Email';
import NovaCampanhaRCS from './Pages/NovaCampanha/RCS';
import 'moment/locale/pt-br'

//export function default
export default function Routes() {
    function PrivateRoute({ children, ...rest }) {
        return (
            <Route
                {...rest}
                render={({ location }) =>
                    window.localStorage.getItem('token') ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: location },
                            }}
                        />
                    )
                }
            />
        );
    }

    return (
        <Router>
            <ReactNotification />

            <Switch>
                <Route exact path="/login" component={Login} />

                <Route exact path="/t/:id" component={VIewLandingPage} />
                <Route exact path="/c/:shorturl" component={VIewLandingPage} />
                <Route exact path="/cron-manual" component={CronManual} />

                <Route exact path="/getfila" component={GetFila} />

                <Route
                    exact
                    path="/sair/:slug/:phone"
                    component={BlacklistPublic}
                />
                <Route
                    exact
                    path="/b/:slug/:phone"
                    component={BlacklistPublic}
                />
                <Route
                    exact
                    path="/s/:slug/:phone"
                    component={BlacklistPublic}
                />

                <LayoutPrivate>
                    <PrivateRoute exact path="/">
                        <Route component={Dashboard} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/blocklist">
                        <Route component={Blacklist} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/nova-campanha/sms">
                        <Route component={NovaCampanhaSMS} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/nova-campanha/rcs">
                        <Route component={NovaCampanhaRCS} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/nova-campanha/email">
                        <Route component={NovaCampanhaEmail} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/campanhas">
                        <Route component={Campanhas} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/gestao-contatos">
                        <Route component={GestaoContatos} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/gestao-contatos/:id">
                        <Route component={Contatos} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/landing-pages/">
                        <Route component={LandingPages} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/landing-pages/studio/:id">
                        <Route component={StudioLandingPage} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/campanhas/:id">
                        <Route component={DetalheCampanha} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/analytics/envios">
                        <Route component={AnalyticsEnvios} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/analytics/landing-page">
                        <Route component={RelatoriosTemplate} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/analytics/landing-page/:id">
                        <Route component={RelatoriosTemplate} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/analytics/email">
                        <Route component={RelatorioEmail} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/analytics/email/:id">
                        <Route component={RelatorioEmail} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/configuracoes">
                        <Route component={Configuracoes} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/notificacoes">
                        <Route component={Notificacoes} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/adm-contas">
                        <Route component={Contas} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/adm-contas/:id">
                        <Route component={ContasForm} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/adm-contas/usuarios/:id">
                        <Route component={UsuariosForm} />
                    </PrivateRoute>

                    <PrivateRoute
                        exact
                        path="/adm-contas/usuarios/new/:accountId"
                    >
                        <Route component={UsuariosForm} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/adm-brokers">
                        <Route component={Brokers} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/adm-brokers/:id">
                        <Route component={BrokersForm} />
                    </PrivateRoute>
                </LayoutPrivate>
            </Switch>
        </Router>
    );
}
