import React, { useState } from 'react';
import { api } from '../../Api/app';
import ModalBlackList from '../../Pages/Blacklist/ModalBlackList';
import messageStore from '../../Store/MessageStore';
import './ButtonDarkList.scss';

export default function ButtonDarkList({
    darklist,
    telefone = '',
    getData = () => {},
}) {
    const [modalOpen, setModalOpen] = useState(false);

    async function handleDeleteBlacklist(id) {
        await api.delete('blacklists/' + id);
        messageStore.addSuccess('Número removido na blacklist.');
        getData(null);
    }

    return (
        <>
            {modalOpen && (
                <ModalBlackList
                    onClose={() => setModalOpen(false)}
                    getData={getData}
                    telefone={telefone}
                />
            )}
            <div className="button-dark-list">
                {darklist ? (
                    <button
                        className="button danger"
                        onClick={() =>
                            messageStore.addConfirm(
                                'Deseja remover o número? "' +
                                    darklist.blacklist +
                                    '"',
                                () => handleDeleteBlacklist(darklist._id)
                            )
                        }
                    >
                        Remover
                    </button>
                ) : (
                    <button
                        className="button success"
                        onClick={() => setModalOpen(true)}
                    >
                        Adicionar
                    </button>
                )}
            </div>
        </>
    );
}
