import React from 'react';

import EditButton from '../../../Images/icos/edit-button-template.png';
import RemoveButton from '../../../Images/icos/remove-button-template.png';

const ButtonWidget = (props) => {
    function remove() {
        props.setLoading(true);
        props.removeWidget(props.id);
    }

    return (
        <div className="button-widget-edit">
            <button
                type="button"
                onClick={() =>
                    props.editWidget(props.item, props.id, props.componentEdit)
                }
            >
                {/* <span>Editar</span> */}
                <img alt="Botão de edição" src={EditButton} />
            </button>

            <button type="button" onClick={remove}>
                {/* <span>Remover</span> */}
                <img alt="Botão de remoção" src={RemoveButton} />
            </button>
        </div>
    );
};

export default ButtonWidget;
