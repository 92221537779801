import React from 'react'

import "./FormDefault.scss"

const FormDefault = ({children}) => (

    <div className="form-default">
        {children}
    </div>

)

export default FormDefault