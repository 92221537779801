import React, { useCallback, useEffect, useState } from 'react';
import { api } from '../../Api/app';
import moment from 'moment';

import './Campanhas.scss';
import messageStore from '../../Store/MessageStore';
import {
    IconAnalytics,
    IconCanal,
    IconCloseLine,
    IconDownload,
    IconEnviado,
    IconHours,
    IconLandingPage,
    IconListContacts,
    IconLoading,
    IconPause,
    IconPlay,
    IconQueue,
    IconRecebido,
} from '../../Components/Icon/Icons';
import H1Page from '../../Components/Layout/H1Page';
import TableDefault from '../../Components/TableDefault/TableDefault';
import { HeadersEnvios } from '../../Api/AnalyticsService';
import { ButtonDefault } from '../../Components/Form/ButtonDefault/ButtonDefault';
import { SelectDefault } from '../../Components/Form/SelectDefault/SelectDefault';
import Skeleton from 'react-loading-skeleton';
import { LoadingBox } from '../../Components/Utils/LoadingBox';
import PreviewTemplate from '../../Components/TemplateCreation/PreviewTemplate/PreviewTemplate';
import PieChartWithPadding from '../../Components/Dashboard/PieChartWithPadding';

export default function DetalheCampanha(props) {
    const [load, setLoad] = useState(true);
    const [loadDownload, setLoadDownload] = useState(false);
    const [loadQueue, setLoadQueue] = useState(false);
    const [loadStatus, setLoadStatus] = useState(false);

    const [data, setData] = useState({
        docs: [],
    });
    const [dataChart, setDataChart] = useState([
        {
            name: 'Agendados',
            value: 0,
            color: 'rgb(255, 187, 40)',
        },
        {
            name: 'Enviados',
            value: 0,
            color: '#198cf0',
        },
        {
            name: 'Recebidos',
            value: 0,
            color: '#40c33e',
        },
        {
            name: 'Erros',
            value: 0,
            color: '#f56464',
        },
    ]);

    const [page, setPage] = useState(1);

    const id = props.match.params.id ? props.match.params.id : '';
    const [campanha, setCampanha] = useState({
        totalPorStatus: {
            total: 0,
            fila: 0,
            envios: 0,
            recebidos: 0,
            agendados: 0,
            erros: 0,
            cancelados: 0,
        },
        contactListId: [],
        listaMensagem: [],
        relatorioAtualizado: false,
        status: 'Pendente',
        deleted: false,
        _id: null,
        chatbotId: null,
        templateId: null,
        templateOficialId: null,
        tipoDeEnvio: null,
        nome: null,
        mensagem: null,
        bina: null,
        tentativas: null,
        imagem: null,
        dataEnvio: null,
        frontend: null,
        templateOficialReplacesTypes: null,
        accountId: null,
        chart: [],
        createdAt: null,
        updatedAt: null,
        campaignSeq: 0,
        listas: [],
        template: null,
    });
    const [statusQueue, setStatusQueue] = useState(true);
    const [status, setStatus] = useState('');
    const [previewId, setPreviewId] = useState(false);

    const getQueue = useCallback(async () => {
        setLoadQueue(true);
        const url =
            'queues?campaignId=' + id + '&status=' + status + '&page=' + page;
        const response = await api.get(url);
        setData({ ...response.data });
        setLoadQueue(false);
    }, [id, page, status]);

    const getData = useCallback(async () => {
        try {
            const campgainInfo = await api.get(
                'campaigns/' + id + '?dataInicial='
            );
            setCampanha(campgainInfo.data);

            if (campgainInfo.data.status === 'Finalizado') {
                setStatusQueue(true);
            } else {
                setStatusQueue(false);
            }

            setLoad(false);
        } catch (e) {
            setLoad(false);
        }
    }, [id]);

    useEffect(() => {
        getData();
        getQueue();
    }, [getData, getQueue]);

    function newFile(blob, name = 'file.txt') {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );
        document.body.removeChild(link);
    }

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    async function handleDownload() {
        try {
            setLoadDownload(true);
            const url =
                'queues/csv?campaignId=' +
                id +
                '&status=' +
                status +
                '&page=' +
                page;
            const response = await api.get(url);
            newFile(
                new Blob([s2ab(response.data)], { type: 'text/csv' }),
                'Relatório analitico.csv'
            );
            setLoadDownload(false);
        } catch (err) {
            setLoadDownload(false);
            console.log(err);
            messageStore.addError('Não foi possivel exportar o arquivo.');
        }
    }

    async function handleStatusQueue(status) {
        setLoadStatus(true);
        setStatusQueue(status);
        await api.get(
            `campaigns-playpause?campaignId=${id}&status=${
                status === true ? 'Finalizado' : 'Pausada'
            }`
        );
        await getData();
        setLoadStatus(false);
    }

    useEffect(() => {
        const intervalGetData = setInterval(() => {
            getData();
        }, 5000);

        if (
            campanha.status === 'Finalizado' &&
            campanha.totalPorStatus.fila > 0
        ) {
        } else {
            clearInterval(intervalGetData);
        }
        return () => clearInterval(intervalGetData);
    }, [campanha, getData]);

    const reloadChart = useCallback(() => {
        setDataChart([
            {
                name: 'Agendados',
                value: campanha.totalPorStatus.agendados,
                color: 'rgb(255, 187, 40)',
                percent:
                    (campanha.totalPorStatus.agendados /
                        campanha.totalPorStatus.total) *
                    100,
            },
            {
                name: 'Enviados',
                value: campanha.totalPorStatus.envios,
                color: '#198cf0',
                percent:
                    (campanha.totalPorStatus.envios /
                        campanha.totalPorStatus.total) *
                    100,
            },
            {
                name: 'Recebidos',
                value: campanha.totalPorStatus.recebidos,
                color: '#40c33e',
                percent:
                    (campanha.totalPorStatus.recebidos /
                        campanha.totalPorStatus.total) *
                    100,
            },
            {
                name: 'Confirmados',
                value: campanha.totalPorStatus.confirmados,
                color: '#820ad1',
                percent:
                    (campanha.totalPorStatus.confirmados /
                        campanha.totalPorStatus.total) *
                    100,
            },
            {
                name: 'Erros',
                value: campanha.totalPorStatus.erros,
                color: '#f56464',
                percent:
                    (campanha.totalPorStatus.erros /
                        campanha.totalPorStatus.total) *
                    100,
            },
            {
                name: 'Fila',
                value: campanha.totalPorStatus.fila,
                color: '#f9cb46',
                percent:
                    (campanha.totalPorStatus.fila /
                        campanha.totalPorStatus.total) *
                    100,
            },
        ]);
    }, [campanha]);

    useEffect(() => {
        reloadChart();
    }, [reloadChart]);

    return (
        <div id="detalhe-campanha">
            <H1Page goback={true} nome={campanha.nome ?? <Skeleton />} />
            {previewId && (
                <PreviewTemplate
                    onClose={() => setPreviewId(false)}
                    id={previewId}
                />
            )}
            <div className="row-line">
                <div className="info-campgain">
                    <div className="campgain">
                        <ul className="list-icons">
                            <li>
                                <div className="icon">
                                    <IconHours />
                                </div>
                                <div className="text">
                                    <span>Data de envios</span>
                                    <b>
                                        {!load ? (
                                            moment(campanha.dataEnvio).format(
                                                'DD/MM/YYYY hh:mm'
                                            )
                                        ) : (
                                            <Skeleton />
                                        )}
                                    </b>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <IconCanal />
                                </div>
                                <div className="text">
                                    <span>Canal</span>
                                    <b>
                                        {!load ? (
                                            campanha.tipoDeEnvio
                                        ) : (
                                            <Skeleton />
                                        )}
                                    </b>
                                </div>
                            </li>
                            {campanha.tipoDeEnvio === 'Email' && (
                                <li>
                                    <div className="icon">
                                        <IconAnalytics />
                                    </div>
                                    <div className="text">
                                        <span>Cliques do email</span>
                                        <a
                                            onClick={() =>
                                                setPreviewId(
                                                    campanha.templateId
                                                )
                                            }
                                            href="#lp"
                                        >
                                            <div className="links">
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={`/analytics/email/${id}`}
                                                >
                                                    Visualizar relatórios
                                                </a>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            )}

                            {campanha.templateId && (
                                <li>
                                    <div className="icon">
                                        <IconLandingPage />
                                    </div>
                                    <div className="text">
                                        <span>Landing page</span>
                                        {!load ? (
                                            <div className="links">
                                                <a
                                                    onClick={() =>
                                                        setPreviewId(
                                                            campanha.templateId
                                                        )
                                                    }
                                                    href="#lp"
                                                >
                                                    Visualizar
                                                </a>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={`/analytics/landing-page/${id}`}
                                                >
                                                    Visualizar relatórios
                                                </a>
                                            </div>
                                        ) : (
                                            <b>
                                                <Skeleton />
                                            </b>
                                        )}
                                    </div>
                                </li>
                            )}
                            <li>
                                <div className="icon">
                                    <IconListContacts />
                                </div>
                                <div className="text">
                                    <span>Mailing</span>
                                    <b>
                                        {!load ? (
                                            campanha.totalPorStatus.total
                                        ) : (
                                            <Skeleton />
                                        )}
                                    </b>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <IconQueue />
                                </div>
                                <div className="button">
                                    {campanha.status === 'Finalizado' &&
                                        campanha.totalPorStatus.fila > 0 && (
                                            <button
                                                className="warning"
                                                onClick={() =>
                                                    handleStatusQueue(
                                                        !statusQueue
                                                    )
                                                }
                                            >
                                                {loadStatus === true ? (
                                                    <IconLoading />
                                                ) : (
                                                    <IconPause />
                                                )}
                                            </button>
                                        )}
                                    {campanha.status === 'Pausada' && (
                                        <button
                                            className="success"
                                            onClick={() =>
                                                handleStatusQueue(!statusQueue)
                                            }
                                        >
                                            {loadStatus === true ? (
                                                <IconLoading />
                                            ) : (
                                                <IconPlay />
                                            )}
                                        </button>
                                    )}
                                </div>
                                <div className="text">
                                    <span>Fila</span>
                                    <b>
                                        {!load ? (
                                            campanha.totalPorStatus.fila
                                        ) : (
                                            <Skeleton />
                                        )}
                                    </b>
                                </div>
                            </li>
                            {/**
                             * 
                            <li>
                                <div className="icon">
                                    <IconCloseLine />
                                </div>
                                <div className="text">
                                    <span>Cancelados</span>
                                    <b>
                                        {!load ? (
                                            campanha.totalPorStatus.cancelados
                                        ) : (
                                            <Skeleton />
                                        )}
                                    </b>
                                </div>
                            </li>
                             */}
                        </ul>
                    </div>
                    <div
                        className="img"
                        style={{
                            backgroundImage: `url('/campanha/detail.png')`,
                        }}
                    />
                </div>
                <div className="info-queue">
                    {load && <LoadingBox />}
                    {!load && (
                        <div className="render-chart">
                            <PieChartWithPadding
                                stroke="#c89afe"
                                strokeWidth="0px"
                                data={dataChart}
                            />
                        </div>
                    )}
                    <div
                        className="img"
                        style={{
                            backgroundImage: `url('/campanha/queue.png')`,
                        }}
                    />
                </div>
            </div>
            <div className="row-line">
                {campanha.tipoDeEnvio !== 'RCS' && (
                    <div className="box-white">
                        {load && <LoadingBox />}
                        <div className="head-box-white">
                            <b>Agendados</b>
                            <div className="icon">
                                <IconHours />
                            </div>
                        </div>
                        <div className="total">
                            <b>{campanha.totalPorStatus.agendados}</b>
                            <span>/{campanha.totalPorStatus.total}</span>
                        </div>
                    </div>
                )}

                <div className="box-white">
                    {load && <LoadingBox />}
                    <div className="head-box-white">
                        <b>Enviados</b>
                        <div className="icon">
                            <IconEnviado />
                        </div>
                    </div>
                    <div className="total">
                        <b>{campanha.totalPorStatus.envios}</b>
                        <span>/{campanha.totalPorStatus.total}</span>
                    </div>
                </div>
                <div className="box-white">
                    {load && <LoadingBox />}
                    <div className="head-box-white">
                        <b>Recebidos</b>

                        <div className="icon">
                            <IconRecebido />
                        </div>
                    </div>
                    <div className="total">
                        <b>{campanha.totalPorStatus.recebidos}</b>
                        <span>/{campanha.totalPorStatus.total}</span>
                    </div>
                </div>

                {campanha.tipoDeEnvio === 'RCS' && (
                    <div className="box-white">
                        {load && <LoadingBox />}
                        <div className="head-box-white">
                            <b>Lidos</b>
                            <div className="icon">
                                <IconRecebido color="#820ad1" />
                            </div>
                        </div>
                        <div className="total">
                            <b>{campanha.totalPorStatus.lidos ?? 0}</b>
                            <span>/{campanha.totalPorStatus.total}</span>
                        </div>
                    </div>
                )}

                <div className="box-white">
                    {load && <LoadingBox />}
                    <div className="head-box-white">
                        <b>Erros</b>
                        <div className="icon">
                            <IconCloseLine />
                        </div>
                    </div>
                    <div className="total">
                        <b>{campanha.totalPorStatus.erros}</b>
                        <span>/{campanha.totalPorStatus.total}</span>
                    </div>
                </div>
            </div>
            <div className="row-line">
                <TableDefault
                    filter={
                        <SelectDefault
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <option value="Em Fila">Fila</option>
                            <option value="Enviado">Enviados</option>
                            <option value="Recebido">Recebidos</option>
                            <option value="Confirmado Leitura">
                                Confirmados
                            </option>
                            <option value="Erro">Erros</option>
                            <option value="Cancelado">Cancelados</option>
                        </SelectDefault>
                    }
                    headers={HeadersEnvios}
                    download={
                        <ButtonDefault
                            loading={loadDownload}
                            type="button"
                            onClick={handleDownload}
                        >
                            <IconDownload /> Download
                        </ButtonDefault>
                    }
                    data={data.docs}
                    page={page}
                    onPaginate={setPage}
                    totalPage={data.pages}
                    total={data.total}
                    label={'Todos envios'}
                    loading={loadQueue}
                />
            </div>
        </div>
    );
}
