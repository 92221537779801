import React, { useEffect, useState } from 'react';
import { api } from '../../Api/app';

//scss
import './Login.scss';
//images
import messageStore from '../../Store/MessageStore';
import { InputDefault } from '../../Components/Form/InputDefault/InputDefault';
import { ButtonDefault } from '../../Components/Form/ButtonDefault/ButtonDefault';
import { IconLogo } from '../../Components/Icon/Icons';

export default function Login(props) {
    const [loading, setLoading] = useState(false);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        window.localStorage.removeItem('token');
    }, []);

    async function handleLogin(e) {
        e.preventDefault();
        try {
            setLoading(true);
            var response = await api.post('session/login', { login, password });

            if (response.data.error === true) {
                throw response.data.message;
            }

            window.localStorage.setItem('token', response.data.token);
            window.location.href = '/';
        } catch (e) {
            setLoading(false);
            messageStore.addError('Erro ao realizar login.');
        }
    }

    return (
        <div id="login">
            <div className="box-login">
                <div className="left">
                    <div className="box">
                        <h1>Bem vindo de volta!</h1>
                        <p>
                            Comece agora a se conectar com todos seus clientes
                        </p>
                        <form onSubmit={handleLogin}>
                            <InputDefault
                                value={login}
                                onChange={(e) => setLogin(e.target.value)}
                                placeholder="seunome@exemplo.com"
                            />
                            <InputDefault
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <ButtonDefault
                                loading={loading}
                                type="submit"
                                theme="primary"
                            >
                                Acessar
                            </ButtonDefault>
                        </form>
                    </div>
                </div>
                <div className="right">
                    <IconLogo width={130} height={60} />
                    <p>
                        Com nossa plataforma de comunicação, você pode alcançar
                        seus clientes em tempo real e manter-se conectado com
                        eles onde quer que estejam.
                    </p>
                    <div className="img" />
                </div>
            </div>
        </div>
    );
}
