import React from 'react';

import './PreviewTemplate.scss';

export default function PreviewTemplate({ id, onClose }) {
    return (
        <div id="box-phone-preview">
            <button className="overlay" onClick={onClose} />
            <div className="phone">
                <iframe title="Pré visualização" src={'/t/' + id} />
            </div>
        </div>
    );
}
