import React, { useState } from 'react';
import { api, baseUrl } from '../../Api/app';
import messageStore from '../../Store/MessageStore';
import { ButtonDefault } from '../Form/ButtonDefault/ButtonDefault';
import { CheckboxDefault } from '../Form/CheckboxDefault/CheckboxDefault';
import DragDropFile from '../Form/DragDropFile/DragDropFile';
import { IconUploadMac } from '../Icon/Icons';

import './ContactList.scss';

export default function CleanCSV(props) {
    const [step, setSetp] = useState('upload');
    const [load, setLoad] = useState(false);
    const [filename, setFilename] = useState('');

    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [contactListId, setContactListId] = useState(null);

    const [nameArquivo, setNameArquivo] = useState('');

    async function handleUpload(file) {
        try {
            setLoad(true);

            setNameArquivo(file.name);
            var formData = new FormData();
            formData.append('file', file, file.name);
            let response = await api.post('contact-imports/upload', formData);
            setFilename(response.data.filename);
            handleSplit(response.data.filename);
            setLoad(false);
            setSetp('question');
        } catch (e) {
            console.log(e);
            messageStore.addError('Houve um erro ao ler o arquivo.');
            setLoad(false);
            setNameArquivo('');
        }
    }

    async function handleSplit(filename) {
        try {
            let response = await api.post('contact-imports/split', {
                filename: filename,
            });

            setTotal(response.data.total);
        } catch (e) {
            return 'Houve um erro ao configurar o arquivo.';
        }
    }

    async function handleProcess(pageRefresh = 1) {
        try {
            setSetp('progress');
            const data = {
                filename: filename,
                nomeArquivo: nameArquivo,
                contactListId: contactListId,
                page: pageRefresh,
                total: total,
            };

            let response = await api.post('contact-imports/higienizar', data);

            if (
                response.data.page > 1 &&
                response.data.total >= response.data.page
            ) {
                handleProcess(response.data.page);
                setPage(response.data.page);
            } else {
                if (contactListId) {
                    await api.post('contact-imports/higienizar-vincular', {
                        filename: filename,
                        contactListId: contactListId,
                    });
                    messageStore.addSuccess('Importação completa.');
                }
                setPage(0);
                setSetp('download');
            }
        } catch (e) {
            messageStore.addError(e.message);
        }
    }

    return (
        <div className="import-contact">
            <div className="box-import">
                {step === 'upload' && (
                    <div className="step">
                        <div className="head-step">
                            <b className="number">1</b>
                            <p className="description-import">
                                Selecione a planilha, deve ter o formato de .csv
                                ou .txt
                            </p>
                        </div>
                        <div className="content-step">
                            <DragDropFile
                                onChange={handleUpload}
                                loading={load}
                            />
                        </div>
                    </div>
                )}

                {step === 'question' && (
                    <div className="step">
                        <div className="head-step">
                            <b className="number">2</b>
                            <p className="description-import">
                                Quando o CSV for higienizado, qual será a ação?
                            </p>
                        </div>
                        <div className="content-step">
                            <div className="label-clean">
                                <CheckboxDefault
                                    selected={contactListId ? true : false}
                                    onChange={() => setContactListId(props.id)}
                                    label="Quero importar todos os contatos válidos na minha lista."
                                />
                                <CheckboxDefault
                                    selected={!contactListId ? true : false}
                                    onChange={() => setContactListId(null)}
                                    label="Não quero importar, quero fazer o download do CSV higienizado."
                                />
                            </div>
                            <ButtonDefault
                                onClick={() => handleProcess(1)}
                                theme="primary"
                            >
                                Confirmar
                            </ButtonDefault>
                        </div>
                    </div>
                )}

                {step === 'progress' && (
                    <div className="step">
                        <div className="head-step">
                            <b className="number">3</b>
                            <p className="description-import">
                                Aguarde enquanto a importação finalize
                            </p>
                        </div>
                        <div className="content-step">
                            <div className="container-progress">
                                <div className="icon">
                                    <IconUploadMac />
                                </div>
                                <div className="process-load">
                                    <div
                                        style={{
                                            width: (page / total) * 100 + '%',
                                        }}
                                    >
                                        <span>
                                            {`${((page / total) * 100).toFixed(
                                                0
                                            )}`}
                                            %
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {step === 'download' && (
                    <div className="step">
                        <div className="head-step">
                            <b className="number">4</b>
                            <p className="description-import">
                                Faça o download dos arquivos higienizados
                            </p>
                        </div>
                        <div className="content-step">
                            <div className="buttons-donwload">
                                <a
                                    className="success"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={
                                        baseUrl +
                                        'contact-imports/higienizar-download/' +
                                        filename +
                                        '?tipo=validos'
                                    }
                                >
                                    Download válidos
                                </a>
                                <a
                                    className="danger"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={
                                        baseUrl +
                                        'contact-imports/higienizar-download/' +
                                        filename +
                                        '?tipo=invalidos'
                                    }
                                >
                                    Download mal formatados
                                </a>
                                <a
                                    className="warning"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={
                                        baseUrl +
                                        'contact-imports/higienizar-download/' +
                                        filename +
                                        '?tipo=duplicados'
                                    }
                                >
                                    Download duplicados
                                </a>
                            </div>
                            <ButtonDefault
                                theme="primary"
                                onClick={() => setSetp('upload')}
                            >
                                Higienizar outro arquivo
                            </ButtonDefault>
                        </div>
                    </div>
                )}
            </div>
            <div className="tutorial-box">
                <p className="title-tutorial">Central de dicas</p>
                <div className="item">
                    <span>
                        A funcionalidade Higienizar CSV é uma ferramenta que
                        permite aos usuários realizar uma limpeza nos seus
                        arquivos CSV antes de importá-los para as suas listas de
                        contatos. Com essa ferramenta, é possível eliminar
                        informações duplicadas, corrigir problemas de formatação
                        e identificar contatos inválidos, de forma rápida e
                        eficiente.
                    </span>
                    <span>
                        Ao fazer o upload de um arquivo CSV na ferramenta
                        Higienizar CSV, o sistema oferece duas opções para o
                        usuário: fazer o download do arquivo higienizado ou
                        importar diretamente os contatos válidos para a sua
                        lista. Na primeira opção, o usuário pode fazer o
                        download do arquivo CSV limpo, sem as informações
                        duplicadas ou inválidas, e utilizá-lo para realizar a
                        importação posteriormente. Na segunda opção, o usuário
                        pode importar diretamente os contatos válidos para a sua
                        lista, eliminando a necessidade de fazer a limpeza
                        manualmente.
                    </span>
                    <span>
                        Um ponto importante a ser destacado é que o primeiro
                        campo do CSV que será importado precisa ser
                        obrigatoriamente um número de celular ou um endereço de
                        e-mail válido. Isso é necessário para garantir que os
                        contatos importados sejam válidos e utilizáveis. Caso o
                        primeiro campo não seja um número de celular ou um
                        endereço de e-mail válido, o sistema irá identificar o
                        erro e apresentar uma mensagem de erro ao usuário.
                    </span>
                </div>
            </div>
        </div>
    );
}
