import React, { useEffect, useState } from 'react';

//scss
import './Notificacoes.scss';
import H1Page from '../../Components/Layout/H1Page';
import { api } from '../../Api/app';
import datesFormater from '../../Helper/DatesFormater';
import Paginator from '../../Components/Paginator/Paginator';

export default function Notificacoes(props) {
    const [page, setPage] = useState(1);

    const [data, setData] = useState({
        docs: [],
    });

    useEffect(() => {
        api.get('logs?page=' + page).then((response) => {
            setData({ ...response.data });
        });
    }, [page]);

    function handleRead(id) {
        data.docs = data.docs.filter((obj) => obj._id !== id);

        data.total = data.total - 1;

        document.querySelector('#total-notification').innerHTML = data.total;

        setData({ ...data });
    }

    return (
        <div id="notificacoes" className="page">
            <H1Page nome="Notificações" />

            <div className="list-notificacations">
                {data.docs.map((row, key) => (
                    <div className="notification" key={key}>
                        <div className="funcoes">
                            <button
                                onClick={() => handleRead(row._id)}
                                type="button"
                                className="button-zig danger"
                            >
                                <span>Arquivar</span>
                            </button>
                        </div>

                        <div className="not-text">
                            <div
                                className="img"
                                style={{
                                    backgroundImage:
                                        'url(' + row.usuario.foto + ')',
                                }}
                            ></div>
                            <div className="text">
                                <div className="not">
                                    <b>{row.usuario.nome}</b> {row.mensagem}
                                </div>
                                <div className="date">
                                    {datesFormater.dateBR(row.createdAt)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="tfoot" style={{ margin: '20px -10px' }}>
                <Paginator
                    range={10}
                    totalPage={data.pages}
                    setPage={setPage}
                    page={page}
                />
            </div>
        </div>
    );
}
