import React, { useEffect, useState } from 'react';
import { api } from '../../Api/app';
import FormDefault from '../../Components/Form/FormDefault';
import InputRow from '../../Components/Form/InputRow';
import H1Page from '../../Components/Layout/H1Page';
import messageStore from '../../Store/MessageStore';
//scss
import './Usuario.scss';
import DragDropFile from '../../Components/Form/DragDropFile/DragDropFile';
import { ButtonDefault } from '../../Components/Form/ButtonDefault/ButtonDefault';
import { InputDefault } from '../../Components/Form/InputDefault/InputDefault';
import { updateProfile } from '../../Api/UserService';
import { useGlobal } from '../../contexts/GlobalContext';

export default function Configuracoes() {
    const { user, setUser } = useGlobal();

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [data, setData] = useState(user);

    useEffect(() => {
        setData(user);
    }, [user]);

    function handleOnChange(name, value) {
        data[name] = value;
        setData({ ...data });
    }

    async function handleOnSave(e) {
        e.preventDefault();
        try {
            const response = await updateProfile(data);

            if (response.data.error === true)
                throw new Error(response.data.message);

            setUser(data);
            messageStore.addSuccess('Dados alterados com sucesso.');
        } catch (err) {
            messageStore.addError(err.message);
        }
    }

    async function handleUpload(file) {
        try {
            setLoadingUpload(true);
            var formData = new FormData();
            formData.append('file', file, file.name);

            let response = await api.put('profile/upload', formData);

            if (response.data.error) throw new Error(response.data.message);

            user.foto = response.data.link;
            setUser({ ...user });

            setLoadingUpload(false);
        } catch (err) {
            messageStore.addError(err.message);
            setLoadingUpload(false);
        }
    }

    return (
        <div id="usuario">
            <H1Page nome="Configurações" />

            <div className="form-user">
                <FormDefault>
                    <div className="head-form">
                        <h1>{user.account.nome}</h1>
                        <h3>{user.nome}</h3>
                    </div>

                    <InputRow>
                        <form onSubmit={handleOnSave} className="flex-user">
                            <InputRow className="line">
                                <InputDefault
                                    label="E-mail"
                                    name="email"
                                    value={data.email}
                                    onChange={(e) =>
                                        handleOnChange('email', e.target.value)
                                    }
                                />
                                <InputDefault
                                    label="Nome"
                                    name="nome"
                                    value={data.nome}
                                    onChange={(e) =>
                                        handleOnChange('nome', e.target.value)
                                    }
                                />
                            </InputRow>

                            <InputRow>
                                <InputDefault
                                    label="Data de nascimento"
                                    name="nascimento"
                                    value={data.nascimento}
                                    onChange={(e) =>
                                        handleOnChange(
                                            'nascimento',
                                            e.target.value
                                        )
                                    }
                                />
                                <InputDefault
                                    label="CPF"
                                    name="cpf"
                                    value={data.cpf}
                                    onChange={(e) =>
                                        handleOnChange('cpf', e.target.value)
                                    }
                                />
                            </InputRow>

                            <InputRow className="line">
                                <InputDefault
                                    label="Senha"
                                    name="password"
                                    value={data.password}
                                    onChange={(e) =>
                                        handleOnChange(
                                            'password',
                                            e.target.value
                                        )
                                    }
                                    type="password"
                                />
                                <InputDefault
                                    label="Confirme a senha"
                                    name="passwordConfirm"
                                    value={data.passwordConfirm}
                                    onChange={(e) =>
                                        handleOnChange(
                                            'passwordConfirm',
                                            e.target.value
                                        )
                                    }
                                    type="password"
                                />
                            </InputRow>

                            <ButtonDefault theme="primary" type="submit">
                                Salvar
                            </ButtonDefault>
                        </form>
                        <div
                            className="change-photo"
                            style={{
                                backgroundImage: `url(${user.foto})`,
                            }}
                        >
                            <div className="change-upload">
                                <DragDropFile
                                    textDrop=""
                                    textButton="Escolher foto"
                                    onChange={(file) => handleUpload(file)}
                                    loading={loadingUpload}
                                />
                            </div>
                        </div>
                    </InputRow>
                </FormDefault>
            </div>
        </div>
    );
}
