import React, { useCallback, useEffect, useState } from 'react';
import { useGlobal } from '../../contexts/GlobalContext';
import moment from 'moment';
import {
    getEnviosPorPeriodo,
    MockupBroker,
    MockupEnviosPorHora,
} from '../../Api/AnalyticsService';
import TinyLineChart from '../../Components/Dashboard/TinyLineChart';
import H1Page from '../../Components/Layout/H1Page';
import PieChartWithPadding from '../../Components/Dashboard/PieChartWithPadding';
import { LoadingBox } from '../../Components/Utils/LoadingBox';

import './Dashboard.scss';
import { EmptyBox } from '../../Components/Utils/EmptyBox';
import { Activities } from '../../Components/Dashboard/Activities';
import Axios from 'axios';
import messageStore from '../../Store/MessageStore';
import { newLandingPage } from '../../Api/LandingPagesSerive';
import { useHistory } from 'react-router-dom';

export default function Dashboard() {
    const { notifications, loadingNotification } = useGlobal();
    const history = useHistory();
    const [loadingLP, setLoadingLP] = useState(false);
    const [brokers, setBrokers] = useState(MockupBroker());
    const [dataSendHour, setDataSendHour] = useState(MockupEnviosPorHora);
    const [loadingSendHour, setLoadingSendHour] = useState(true);
    const [emptySendHour, setEmptySendHour] = useState(false);
    const dataInicial = moment().subtract('days', 7).format('YYYY-MM-DD');
    const dataFinal = moment().format('YYYY-MM-DD');

    const getData = useCallback(
        async (source) => {
            try {
                setLoadingSendHour(true);
                const sendDash = await getEnviosPorPeriodo(
                    {
                        dataInicial,
                        dataFinal,
                    },
                    {
                        source,
                    }
                );
                let total = 0;
                sendDash.brokers.forEach((row) => {
                    total += row.value > 0 ?? 0;
                });
                console.log(total);
                if (total === 0) throw new Error('Registros vázios');

                setDataSendHour([...sendDash.result]);
                setBrokers([...sendDash.brokers]);
                setLoadingSendHour(false);
            } catch (err) {
                setLoadingSendHour(false);
                setEmptySendHour(true);
                console.log('err', err);
            }
        },
        [dataInicial, dataFinal]
    );

    useEffect(() => {
        const source = Axios.CancelToken.source();
        getData(source);
        return () => {
            source.cancel('Cancelado pelo usuário');
        };
    }, [getData]);

    async function handleNewLP() {
        setLoadingLP(true);
        var response = await newLandingPage();
        if (response.data.error === true) {
            messageStore.addError(response.data.message);
        } else {
            history.push('/landing-pages/studio/' + response.data.result.uid);
        }

        setLoadingLP(false);
    }

    return (
        <div id="dashboard">
            <H1Page hiddenBack={true} nome={'Dashboard'} />

            <div className="container-row">
                <ul className="legend">
                    {brokers.map((row) => (
                        <button key={row.name}>
                            <i
                                style={{
                                    backgroundColor: row.color,
                                }}
                            />
                            <span>{row.name}</span>
                        </button>
                    ))}
                </ul>
            </div>

            <div className="container-row">
                <div className="item white pie">
                    <p className="title">
                        Canais <span>de hoje</span>
                    </p>
                    <div className="content-item">
                        {loadingSendHour && (
                            <LoadingBox
                                style={{
                                    borderRadius: 40,
                                }}
                            />
                        )}
                        {emptySendHour && (
                            <EmptyBox
                                style={{
                                    borderRadius: 40,
                                }}
                            />
                        )}
                        <PieChartWithPadding data={brokers} />
                    </div>
                </div>

                <div
                    onClick={() =>
                        !loadingLP &&
                        messageStore.addConfirm(
                            'Você deseja criar uma nova landing page?',
                            handleNewLP
                        )
                    }
                    className="item white banner"
                >
                    <div className="banner-item">
                        {loadingLP && (
                            <LoadingBox style={{ borderRadius: 40 }} />
                        )}
                        <p className="title-banner">Landing Pages</p>
                        <p className="description-banner">
                            Construa e personalize sua landiong page
                        </p>
                        <img
                            className="img-overlay"
                            src="/ipad-lp.png"
                            alt="Ad"
                        />
                    </div>
                </div>

                <div className="item white activies">
                    <p className="title">Atividades</p>
                    <div className="content-item">
                        {loadingNotification && (
                            <LoadingBox
                                style={{
                                    borderRadius: 40,
                                }}
                            />
                        )}
                        {loadingNotification === false &&
                            notifications.length === 0 && (
                                <EmptyBox
                                    style={{
                                        borderRadius: 40,
                                    }}
                                />
                            )}
                        <Activities data={notifications} />
                    </div>
                </div>
            </div>

            <div className="container-row">
                <div className="item line">
                    <p className="title">
                        Envios <span>de hoje</span>
                    </p>
                    <div className="content-item">
                        {loadingSendHour && (
                            <LoadingBox
                                style={{
                                    borderRadius: 40,
                                }}
                            />
                        )}
                        {emptySendHour && (
                            <EmptyBox
                                style={{
                                    borderRadius: 40,
                                }}
                            />
                        )}
                        <TinyLineChart brokers={brokers} data={dataSendHour} />
                    </div>
                </div>
            </div>
        </div>
    );
}
