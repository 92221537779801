import React, { useCallback, useEffect, useState } from 'react';

//scss
import './NovaCampanha.scss';
import H1Page from '../../Components/Layout/H1Page';
import {
    ContainerStepName,
    ContainerStepListContacts,
    ContainerStepSend,
    ContainerStepProgress,
    ContainerMessageEmail,
} from '../../Components/NovaCampanha/NovaCampanhaUtils';
import messageStore from '../../Store/MessageStore';
import { useGlobal } from '../../contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import { newCampanha, sendQueueCampanha } from '../../Api/CampanhasService';

export default function NovaCampanhaEmail() {
    const history = useHistory();

    const canal = 'email';
    const { refScroll } = useGlobal();

    const [step, setStep] = useState(1);
    const [shortCodes, setShortCodes] = useState([]);

    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const [DTO, setDTO] = useState({
        chatbotId: null,
        templateId: null,
        templateOficialId: null,
        contactListId: [],
        tipoDeEnvio: 'Email',
        nome: '',
        mensagem: null,
        listaMensagem: [],
        bina: null,
        tentativas: null,
        imagem: null,
        dataEnvio: null,
        frontend: 'https://octo.frontendtropa.com.br/c/',
        templateOficialReplacesTypes: null,
    });

    useEffect(() => {
        setDTO({
            chatbotId: null,
            templateId: null,
            templateOficialId: null,
            contactListId: [],
            tipoDeEnvio: 'Email',
            nome: '',
            mensagem: null,
            listaMensagem: [],
            bina: null,
            tentativas: null,
            imagem: null,
            dataEnvio: null,
            frontend: 'https://octo.frontendtropa.com.br/c/',
            templateOficialReplacesTypes: null,
        });
        setStep(1);

        //dev
    }, [canal]);

    function handleOnChange(name, value) {
        DTO[name] = value;
        setDTO({ ...DTO });
    }

    const sendQueue = useCallback(
        async (id) => {
            try {
                setLoading(true);

                const response = await sendQueueCampanha(id);

                if (!response.data.status)
                    throw new Error('Erro ao processar campanha.');

                if (response.data.status !== 'Finalizado') {
                    sendQueue(id);

                    const percent =
                        (response.data.filaTotal /
                            response.data.contatosTotal) *
                        100;
                    setProgress(percent.toFixed(0));
                } else {
                    history.push(`/campanhas/${id}`);
                }
            } catch (e) {
                setLoading(false);
                messageStore.addError(
                    'Houve um erro ao inserir envios na fila.'
                );
            }
        },
        [history]
    );

    const handleOnSave = useCallback(async () => {
        try {
            if (loading === true)
                throw new Error('A campanha já esta sendo processada.');
            setLoading(true);
            const response = await newCampanha(DTO);
            console.log(response);
            if (response.data.errors)
                throw new Error(
                    'Não foi possivel salvar a campanha no momento.'
                );
            setStep(5);
            sendQueue(response.data.result.id);
        } catch (e) {
            setLoading(false);
            console.log(e);
            messageStore.addError(e.message);
        }
    }, [DTO, loading, sendQueue]);

    const handleSubmit = useCallback(() => {
        try {
            if (step === 1) {
                if (DTO.nome.length < 3)
                    throw new Error(
                        'O nome da cmapanha é obrigatório ter pelo menos 3 caracateres.'
                    );
                setStep(2);
            } else if (step === 2) {
                if (DTO.contactListId.length < 1)
                    throw new Error(
                        'É obrigatório selecionar pelo menos 1 lista de contato.'
                    );
                setStep(3);
            } else if (step === 3) {
                if (!DTO.mensagem)
                    throw new Error('É obrigatório a mensagem da campanha.');
                setStep(4);
            } else if (step === 4) {
                if (!DTO.dataEnvio)
                    throw new Error(
                        'É obrigatório selecionar uma data e um horario.'
                    );
                handleOnSave();
            }

            setTimeout(() => {
                refScroll.scrollToTop(0);
            }, 10);
        } catch (err) {
            console.log(err);
            messageStore.addError(err.message);
        }
    }, [step, DTO, refScroll, handleOnSave]);

    const steps = [
        {
            Component: (
                <ContainerStepName
                    position="1"
                    step={step}
                    handleSubmit={handleSubmit}
                    handleOnChange={handleOnChange}
                    DTO={DTO}
                />
            ),
        },
        {
            Component: (
                <ContainerStepListContacts
                    position="2"
                    step={step}
                    handleSubmit={handleSubmit}
                    handleOnChange={handleOnChange}
                    DTO={DTO}
                    onPrev={() => setStep(1)}
                    canal={canal}
                    setShortCodes={(e) => setShortCodes(e)}
                />
            ),
        },
        {
            Component: (
                <ContainerMessageEmail
                    position="3"
                    canal={canal}
                    step={step}
                    handleSubmit={handleSubmit}
                    handleOnChange={handleOnChange}
                    DTO={DTO}
                    onPrev={() => setStep(2)}
                    shortCodes={shortCodes}
                />
            ),
        },
        {
            Component: (
                <ContainerStepSend
                    position="4"
                    step={step}
                    handleSubmit={handleSubmit}
                    handleOnChange={handleOnChange}
                    DTO={DTO}
                    onPrev={() => setStep(3)}
                    loading={loading}
                    progress={progress}
                />
            ),
        },
        {
            Component: (
                <ContainerStepProgress
                    position="5"
                    step={step}
                    loading={loading}
                    progress={progress}
                />
            ),
        },
    ];

    return (
        <div id="nova-campanha">
            <H1Page nome={`Nova campanha`} />
            <div className="list-steps">
                <div className="flex-step">
                    {steps.map((step) => step.Component)}
                </div>
            </div>
        </div>
    );
}
